<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>C端数据管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 数据管理 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">数据管理</span>
        </el-col>
      </el-row>
      <el-row :gutter="30" style="margin-top: 34px">
        <el-col :span="5" v-for="(item, i) of dataToolLost" v-bind:key="i">
          <div
            class="racing-tool-back"
            @click="racingToolClicked(item.path)"
          >
            <div
              class="racing-tool-leftimg"
              :class="'coloricon' + item.menuTag"
            >
              <i class="icon iconfont" :class="item.icon"></i>
            </div>
            <div class="racing-tool-right">
              <div>{{ item.rightTitle }}</div>
              <div class="racing-tool-desc">{{ item.rightDesc }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">数据统计</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :span="4"
          v-for="(item, i) of dataStatisticsList"
          v-bind:key="i"
        >
          <div class="processed-list-con">
            <div class="processed-image processed-image-big" :class="'coloricon' + (i + 1)">
              <i class="icon iconfont" :class="item.icon"></i>
            </div>
            <div class="processed-count">
              <div class="processed-desc">{{ item.rightDesc }}</div>
              <div class="processed-title">{{ item.rightTitle }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- <el-row style="margin-top: 15px">
        <el-col :span="8">
          <div class="rank-class">
            缴费排行榜 <span class="rank-update">每天更新</span>
          </div>
          <div class="rankTypeback">
            <div class="rank-type">
              <div
                :class="
                  payRankType === 'marketing'
                    ? 'rankTypeActive rankTypeActive2'
                    : 'rankTypeItem'
                "
                @click="ranktypeClicked('consideration', 'marketing')"
              >
                直营
              </div>
              <div
                :class="
                  payRankType === 'authorization'
                    ? 'rankTypeActive rankTypeActive2'
                    : 'rankTypeItem'
                "
                @click="ranktypeClicked('consideration', 'authorization')"
              >
                授权
              </div>
            </div>
            <el-table :data="payRankRankList" height="520" style="width: 100%">
              <el-table-column label="排名" type="index" width="50">
              </el-table-column>
              <el-table-column prop="districtName" label="赛区" width="100">
              </el-table-column>
              <el-table-column label="金额" prop="consider" width="80">
              </el-table-column>
              <el-table-column>
                <template scope="scope">
                  <el-progress
                    type="line"
                    :percentage="(scope.row.consider / 10000) * 100"
                    color="#FFA94C"
                    :show-text="false"
                  >
                  </el-progress>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row> -->
    </el-card>
    <!-- 实时报名情况 -->
    <!-- <div style="width: 100%;">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-card style="margin-top: 15px">
            <el-row style="margin: 15px auto; font-size: 16px">
              <el-col>
                <span class="title_class">实时报名情况</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <div class="item-sign-backview">
                  <div class="item-sign-data">
                    <div
                      class="item-sign-data-child"
                      v-for="(item, index) in signInListData"
                      v-bind:key="index"
                    >
                      <div class="child-data-title">{{ item.titleStr }}</div>
                      <div class="child-data-number">{{ item.descStr }}</div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </div> -->
  </div>
</template>

<script>
// import chinaMap from '@/components/bData/chinaMap/chinaMap.vue'
export default {
  data () {
    return {
      payRankType: 'marketing',
      dataToolLost: [
        {
          icon: 'icon-tianjiayonghu',
          rightTitle: '选手管理',
          rightDesc: '可查看选手注册信息',
          path: '/c_data_Management/c_player_registInfo',
          menuTag: '1'
        },
        {
          icon: 'icon-jiaoshi',
          rightTitle: '指导教师管理',
          rightDesc: '可修改教师权限',
          path: '',
          menuTag: '2'
        }
      ],
      dataStatisticsList: [
        {
          // leftImg: require('@/assets/img/testImg/icon-shijuanguanli.png'),
          icon: 'icon-tianjiayonghu',
          rightTitle: '已注册学生数量',
          rightDesc: '0',
          menuTag: '1'
        },
        {
          // leftImg: require('@/assets/img/testImg/icon-youhuiquan.png'),
          icon: 'icon-jiaoshi',
          rightTitle: '已注册老师数量',
          rightDesc: '0',
          menuTag: '2'
        },
        {
          // leftImg: require('@/assets/img/testImg/icon-rili.png'),
          icon: 'icon-jiaoshi',
          rightTitle: '授课老师数量',
          rightDesc: '0',
          menuTag: '3'
        },
        {
          // leftImg: require('@/assets/img/testImg/icon-historicalbill.png'),
          icon: 'icon-baoming',
          rightTitle: '已报名学生数量',
          rightDesc: '0',
          menuTag: '4'
        },
        {
          // leftImg: require('@/assets/img/testImg/icon-huoyueuser.png'),
          icon: 'icon-baoming',
          rightTitle: '历史报名学生数量',
          rightDesc: '0',
          menuTag: '5'
        }
      ]
      // payRankRankList: [
      //   {
      //     rankStr: 1,
      //     districtName: '天津赛区',
      //     consider: '10000'
      //   },
      //   {
      //     rankStr: 2,
      //     districtName: '天津赛区',
      //     consider: '9000'
      //   },
      //   {
      //     rankStr: 3,
      //     districtName: '天津赛区',
      //     consider: '8000'
      //   },
      //   {
      //     rankStr: 4,
      //     districtName: '天津赛区',
      //     consider: '7000'
      //   },
      //   {
      //     rankStr: 5,
      //     districtName: '天津赛区',
      //     consider: '6000'
      //   },
      //   {
      //     rankStr: 6,
      //     districtName: '天津赛区',
      //     consider: '5500'
      //   },
      //   {
      //     rankStr: 7,
      //     districtName: '天津赛区',
      //     consider: '4000'
      //   },
      //   {
      //     rankStr: 8,
      //     districtName: '天津赛区',
      //     consider: '2000'
      //   },
      //   {
      //     rankStr: 8,
      //     districtName: '天津赛区',
      //     consider: '2000'
      //   },
      //   {
      //     rankStr: 8,
      //     districtName: '天津赛区',
      //     consider: '2000'
      //   },
      //   {
      //     rankStr: 8,
      //     districtName: '天津赛区',
      //     consider: '2000'
      //   },
      //   {
      //     rankStr: 8,
      //     districtName: '天津赛区',
      //     consider: '2000'
      //   },
      //   {
      //     rankStr: 8,
      //     districtName: '天津赛区',
      //     consider: '2000'
      //   },
      //   {
      //     rankStr: 8,
      //     districtName: '天津赛区',
      //     consider: '2000'
      //   },
      //   {
      //     rankStr: 8,
      //     districtName: '天津赛区',
      //     consider: '2000'
      //   },
      //   {
      //     rankStr: 8,
      //     districtName: '天津赛区',
      //     consider: '2000'
      //   }
      // ],
      // signInListData: [
      //   {
      //     titleStr: '今日交易总额',
      //     descStr: '43244'
      //   },
      //   {
      //     titleStr: '销售目标完成率',
      //     descStr: '92%'
      //   },
      //   {
      //     titleStr: '报名剩余时间',
      //     descStr: '10:34:32'
      //   },
      //   {
      //     titleStr: '每天交易总额',
      //     descStr: '10000'
      //   }
      // ]
    }
  },
  // components: {
  //   chinaMap
  // },
  methods: {
    // 考量排行榜切换
    ranktypeClicked (rankType, item) {
      this.payRankType = item
    },
    format (row, column) {
      console.log('column', row)
      return () => {
        return '￥' + row.consider
      }
    },
    racingToolClicked (path) {
      if (path) {
        this.$router.push({ path })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.racing-tool-back {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

// .racing-tool-leftimg {
//   width: 50px;
//   height: 60px;
//   margin-right: 5px;
// }

// .racing-tool-right {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   font-size: 16px;
//   color: rgb(107, 107, 107);
// }

// .racing-tool-desc {
//   font-size: 12px;
// }
.rank-class {
  font-size: 14px;
  color: rgb(39, 39, 39);
  margin-top: 24px;
}
.rank-update {
  font-size: 12px;
  color: rgb(123, 122, 122);
}
.rankTypeback {
  margin: 10px 0px;
  padding: 20px;
  // border: 1px solid rgb(219, 219, 219);
}

.rankTypeItem {
  width: 42px;
  height: 24px;
  // border-radius: 6px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  border: 1px solid #aaa7a7;
  color: #fff;
  background-color: #aaa7a7;
  // margin-right: 10px;
  cursor: pointer;
}
.rank-type {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rankTypeActive {
  width: 42px;
  height: 24px;
  font-size: 12px;
  // border-radius: 6px;
  line-height: 24px;
  text-align: center;
  // margin-right: 10px;
  cursor: pointer;
  color: white;
}
.rankTypeActive1 {
  background-color: #2db8f8;
  border: 1px solid #2db8f8;
}
.rankTypeActive2 {
  background-color: #f8ae19;
  border: 1px solid #f8ae19;
}
.rankTypeActive3 {
  background-color: #4bced0;
  border: 1px solid #4bced0;
}
.item-sign-backview {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.item-sign-data {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.item-sign-data-child {
  width: 33%;
  display: flex;
  flex-direction: column;
}
</style>
