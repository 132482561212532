import request from '../http/request'
// 获取字典数据
export function getDictList (params) {
  return request({
    url: '/dict/getDictList', // 请求地址
    method: 'GET', // 请求方式
    params // 参数
  })
}
// 发送短信验证码
export function onSendSms (data) {
  return request({
    url: '/common/smsVerificationCode', // 请求地址
    method: 'POST', // 请求方式
    data // 参数
  })
}
// 登录
export function chnEngLoginRequest (data) {
  return request({
    url: '/mobileLoginErp',
    method: 'POST',
    data
  })
}
// 退出登录
export function loginOutRequest (params) {
  return request({
    url: '/loginOut',
    method: 'GET',
    params
  })
}
// 获取用户信息列表
export function getSysUserList (params) {
  return request({
    url: '/erpUser/getSysUserList',
    method: 'GET',
    params
  })
}
// 获取用户详情信息
export function getSysUserInfo (params) {
  return request({
    url: '/erpUser/getSysUserInfo',
    method: 'GET',
    params
  })
}
// 获取赛区信息列表
export function queryDistrictPage (params) {
  return request({
    url: '/erp/district/queryDistrictPage',
    method: 'GET',
    params
  })
}
// 查询学校列表
export function getSchoolList (params) {
  return request({
    url: '/school/getSysSchoolList',
    method: 'GET',
    params
  })
}
// 获取地址列表
export function getSysUserAddressListByUserId (params) {
  return request({
    url: '/address/getSysUserAddressListByUserId',
    method: 'GET',
    params
  })
}

// 根据省市区查询学校
export function getSchoolListWithCity (params) {
  return request({
    url: '/school/getSchoolList',
    method: 'GET',
    params
  })
}
// 保存用户信息
export function saveSysUserAll (data) {
  return request({
    url: '/erpUser/saveSysUserAll',
    method: 'POST',
    data
  })
}
// erp 查询学校列表
export function getSysSchoolList (params) {
  return request({
    url: '/school/getSysSchoolList',
    method: 'GET',
    params
  })
}
// 保存学校信息
export function addSysSchool (data) {
  return request({
    url: '/school/addSysSchool',
    method: 'POST',
    data
  })
}
// 查询学校审核列表
export function getApproveSchoolList (params) {
  return request({
    url: '/school/getApproveSchoolList',
    method: 'GET',
    params
  })
}
// 批量生成学校参赛码
export function createSchoolUniqueCode (params) {
  return request({
    url: '/school/createSchoolUniqueCode',
    method: 'GET',
    params
  })
}
// 学校审核拒绝操作
export function approveRefuseSchoolTeacher (data) {
  return request({
    url: '/school/approveRefuseSchoolTeacher',
    method: 'POST',
    data
  })
}
// 学校审核同意并开通试看课程

export function approveSchoolTeacher (data) {
  return request({
    url: '/school/approveOpenSchoolTeacher',
    method: 'POST',
    data
  })
}
// 查询字库相关列表
export function queryOpenProductList (params) {
  return request({
    url: '/openProduct/queryOpenProductList',
    method: 'GET',
    params
  })
}
// 保存用户开通字库
export function addOpenProductList (data) {
  return request({
    url: '/openProduct/addOpenProductList',
    method: 'POST',
    data
  })
}
// 查询学校试卷申请列表
export function getPaperApplyList (params) {
  return request({
    url: '/paper/getPaperApplyList',
    method: 'GET',
    params
  })
}
// 审核学校试卷申请
export function processPaperApply (data) {
  return request({
    url: '/paper/processPaperApply',
    method: 'POST',
    data
  })
}
// 获取学校试卷申请详情
export function queryPersonalPaperApplyInfo (params) {
  return request({
    url: '/paper/queryPersonalPaperApplyInfo',
    method: 'GET',
    params
  })
}
// 审核学校试卷申请
export function paperApplyExpressDelivery (data) {
  return request({
    url: '/paper/paperApplyExpressDelivery',
    method: 'POST',
    data
  })
}
// 变更试卷状态
export function updatePaperStatusDTO (data) {
  return request({
    url: '/paper/updatePaperStatusDTO',
    method: 'POST',
    data
  })
}
// 获取code列表
export function queryConversionCodeList (params) {
  return request({
    url: '/code/queryConversionCodeList',
    method: 'GET',
    params
  })
}
// 新增产品解锁码
export function addConversionCode (data) {
  return request({
    url: '/code/addConversionCode',
    method: 'POST',
    data
  })
}
// 获取订单列表
export function getTbOrderList (params) {
  return request({
    url: '/order/getTbOrderList',
    method: 'GET',
    params
  })
}
// 获取订单详情
export function getTbOrderInfo (params) {
  return request({
    url: '/order/getTbOrderInfo',
    method: 'GET',
    params
  })
}
// 提交快递信息
export function fillInTheTrackingNumber (data) {
  return request({
    url: '/order/fillInTheTrackingNumber',
    method: 'POST',
    data
  })
}
// 确认收货
export function executionOrderCompletion (data) {
  return request({
    url: '/order/executionOrderCompletion',
    method: 'POST',
    data
  })
}
// 获取报名资格
export function queryTbEnrollmentList (params) {
  return request({
    url: '/enrollment/queryTbEnrollmentList',
    method: 'GET',
    params
  })
}
// 根据赛季和赛程Flag获取赛程信息
export function querySeasonRaceScheduleFlag (params) {
  return request({
    url: '/raceSchedule/querySeasonRaceScheduleFlag',
    method: 'GET',
    params
  })
}
// 根据产品Id查询产品开通记录
export function queryOpenProductById (data) {
  return request({
    url: '/openProduct/queryOpenProductById',
    method: 'POST',
    data
  })
}
// 获取线上考试成绩
export function queryAnswerResultsPage (params) {
  return request({
    url: '/Initial/Reply/queryAnswerResultsPage',
    method: 'GET',
    params
  })
}
// 获取线下考试成绩
export function queryPaperResultScore (params) {
  return request({
    url: '/Initial/Reply/queryPaperResultScore',
    method: 'GET',
    params
  })
}
// 获取成绩详情
export function getAnswerRaceScheduleByUserId (params) {
  return request({
    url: '/Initial/Reply/getAnswerRaceScheduleByUserId',
    method: 'GET',
    params
  })
}

// 比赛设置晋级
export function replyQueryModelPaperResultScore (data) {
  return request({
    url: '/Initial/Reply/queryModelPaperResultScore',
    method: 'POST',
    data
  })
}
// 线上成绩设置晋级
export function setTbEnrollmentPromotion (data) {
  return request({
    url: '/enrollment/setTbEnrollmentPromotion',
    method: 'POST',
    data
  })
}
// 线下成绩设置晋级
export function updateInitialReplyScore (data) {
  return request({
    url: '/Initial/Reply/updateInitialReplyScore',
    method: 'POST',
    data
  })
}
// 获取线上晋级预期
export function queryModelPaperExpectResultScore (params) {
  return request({
    url: '/Initial/Reply/queryModelPaperExpectResultScore',
    method: 'GET',
    params
  })
}
// 获取线下晋级预期
export function queryModelExpectInitialReplyScore (params) {
  return request({
    url: '/Initial/Reply/queryModelExpectInitialReplyScore',
    method: 'GET',
    params
  })
}
// 线上批量设置晋级
export function queryModelPaperResultScore (data) {
  return request({
    url: '/Initial/Reply/queryModelPaperResultScore',
    method: 'POST',
    data
  })
}
// 线下批量设置晋级
export function queryModelInitialReplyScore (data) {
  return request({
    url: '/Initial/Reply/queryModelInitialReplyScore',
    method: 'POST',
    data
  })
}

// 一键生成成绩查询码
export function oneKeyGenerateScoreCode (data) {
  return request({
    url: '/Initial/Reply/oneKeyGenerateScoreCode',
    method: 'POST',
    data
  })
}
// 单个生成成绩查询码
export function oneGenerateScoreCode (data) {
  return request({
    url: '/Initial/Reply/oneGenerateScoreCode',
    method: 'POST',
    data
  })
}
// 成绩模板下载
export function importPreliminaryCompetitionTemplate (params) {
  return request({
    url: '/Initial/Reply/importPreliminaryCompetitionTemplate',
    method: 'GET',
    params
  })
}
// 获取赛程时间列表
export function querySeasonRaceSchedule (params) {
  return request({
    url: '/raceSchedule/querySeasonRaceSchedule',
    method: 'GET',
    params
  })
}
// 设置当前赛程相关信息
export function setSeasonRaceSchedule (data) {
  return request({
    url: '/raceSchedule/setSeasonRaceSchedule',
    method: 'POST',
    data
  })
}
// 设置当前正在进行的赛程
export function setSeasonRaceScheduleIng (data) {
  return request({
    url: '/raceSchedule/setSeasonRaceScheduleIng',
    method: 'POST',
    data
  })
}

// ==============字库管理=============
// 获取字库列表
export function getCWordList (params) {
  return request({
    url: '/lexicon/getCWordList',
    method: 'GET',
    params
  })
}
// 删除字
export function deleteCWord (params) {
  return request({
    url: '/lexicon/deleteCWord',
    method: 'DELETE',
    params
  })
}
// 增加or修改字库内容
export function addCWord (data) {
  return request({
    url: '/lexicon/addCWord',
    method: 'POST',
    data
  })
}
// 查询字详情
export function getCWordInfoGroupList (params) {
  return request({
    url: '/lexicon/getCWordInfoById',
    method: 'GET',
    params
  })
}
// 新增赛区
export function saveOrUpdateDistrict (data) {
  return request({
    url: '/erp/district/saveOrUpdateDistrict',
    method: 'POST',
    data
  })
}
// 查询erp列表用户
export function queryErpUserList (params) {
  return request({
    url: '/erp/queryErpUserList',
    method: 'GET',
    params
  })
}
// 获取赛区事件列表
export function queryDistrictTimePage (params) {
  return request({
    url: '/erp/districtTime/v1/queryDistrictTimePage',
    method: 'GET',
    params
  })
}
// 保存或修改赛区时间
export function saveOrUpdateDistrictTime (data) {
  return request({
    url: '/erp/districtTime/v1/saveOrUpdateDistrictTime',
    method: 'POST',
    data
  })
}
// 删除赛区时间
export function deleteDistrictTime (params) {
  return request({
    url: '/erp/districtTime/v1/deleteDistrictTime',
    method: 'GET',
    params
  })
}
// 获取课程产品列表
export function getTbProductListInfoPage (params) {
  return request({
    url: '/lexicon/getTbProductListInfoPage',
    method: 'GET',
    params
  })
}
// 删除课程
export function deleteProductInfo (data) {
  return request({
    url: '/lexicon/deleteProductInfo',
    method: 'POST',
    data
  })
}
// 下架处理
export function updateTakeOffTheShelf (data) {
  return request({
    url: '/lexicon/updateTakeOffTheShelf',
    method: 'POST',
    data
  })
}

// 获取课程二级联动类型
export function secondaryLinkageCourseQuery (params) {
  return request({
    url: '/lexicon/secondaryLinkageCourseQuery',
    method: 'get',
    params
  })
}

// 新增课程产品
export function addTbProductListInfo (data) {
  return request({
    url: '/lexicon/addTbProductListInfo',
    method: 'POST',
    data
  })
}
// 获取课程详情
export function queryTbProductListInfo (params) {
  return request({
    url: '/lexicon/queryTbProductListInfo',
    method: 'GET',
    params
  })
}
// 根据学校获取学校下的老师
export function getSchoolTeacherList (params) {
  return request({
    url: '/school/getSchoolTeacherList',
    method: 'GET',
    params
  })
}

// 学校开通课程
export function addTbProductSchoolList (data) {
  return request({
    url: '/openProduct/addTbProductSchoolList',
    method: 'POST',
    data
  })
}
// 查询学校开通了哪些课程
export function queryProductSchoolList (params) {
  return request({
    url: '/openProduct/queryProductSchoolList',
    method: 'GET',
    params
  })
}
// 添加社团课老师信息
export function addSchoolTeacherOrganization (data) {
  return request({
    url: '/school/addSchoolTeacherOrganization',
    method: 'POST',
    data
  })
}
// 下载喜报模板
export function downLoad (params) {
  return request({
    url: '/goodNews/downLoad',
    method: 'GET',
    params
  })
}
// 导入喜报模板
export function importExcel (data) {
  return request({
    url: '/goodNews/importExcel',
    method: 'POST',
    data
  })
}
// 社团管理，编辑授课老师信息
export function updateSchoolTeacher (data) {
  return request({
    url: '/school/updateSchoolTeacher',
    method: 'POST',
    data
  })
}

// 删除社团老师课程
export function deleteSchoolTeacher (data) {
  return request({
    url: '/school/deleteSchoolTeacher',
    method: 'POST',
    data
  })
}

// 解封和禁用教师课程
export function disableSchoolTeacher (data) {
  return request({
    url: '/school/disableSchoolTeacher',
    method: 'POST',
    data
  })
}

// 线上线下成绩修改
export function updateTbEnrollmentIsOnline (data) {
  return request({
    url: '/enrollment/updateTbEnrollmentIsOnline',
    method: 'POST',
    data
  })
}

// 获取网站参赛队报名信息
export function itsoRegisterList (params) {
  return request({
    url: '/itsoRegister/page',
    method: 'GET',
    params
  })
}

// 获取俱乐部列表
export function itsoClubInfoList (params) {
  return request({
    url: '/itsoClubInfo/page',
    method: 'GET',
    params
  })
}

// 修改会员学校状态
export function setSchoolMember (data) {
  return request({
    url: '/itsoClubInfo/setSchoolMember',
    method: 'POST',
    data
  })
}

// 取消会员学校
export function deleteSchoolMember (data) {
  return request({
    url: '/itsoClubInfo/deleteSchoolMember',
    method: 'DELETE',
    data
  })
}

// 设置俱乐部指导教师
export function setClubTeacher (data) {
  return request({
    url: '/itsoClubInfo/setClubTeacher',
    method: 'POST',
    data
  })
}

// 获取学校省市区
export function getAllAreaList (params) {
  return request({
    url: '/common/getAllAreaList',
    method: 'GET',
    params
  })
}

// 获取战队列表信息
export function getitsoTeamList (params) {
  return request({
    url: '/itsoteam/page',
    method: 'GET',
    params
  })
}

// 修改战队签到状态
export function changeTeamSignInState (data) {
  return request({
    url: '/itsoteam/signIn',
    method: 'POST',
    data
  })
}

// 确认缴费
export function itsoRegisterPayment (data) {
  return request({
    url: '/itsoRegister/payment',
    method: 'POST',
    data
  })
}

// 查询字典类型
export function getDictTypeList (params) {
  return request({
    url: '/dict/getSysDictTypeList',
    method: 'GET',
    params
  })
}
// 添加/修改字典类型
export function addSysDictType (data) {
  return request({
    url: '/dict/addSysDictType',
    method: 'POST',
    data
  })
}
// 根据type类型从缓存查询字典表
export function getSysDictDataList (params) {
  return request({
    url: '/dict/getSysDictDataList',
    method: 'GET',
    params
  })
}
// 添加字典数据
export function addSysDictTypeValue (data) {
  return request({
    url: '/dict/addSysDictTypeValue',
    method: 'POST',
    data
  })
}
