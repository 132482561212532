<template>
  <div>
    <el-row class="title_row">
      <el-col>
        <span>课程信息</span>
      </el-col>
    </el-row>
    <el-form
      label-width="110px"
      :model="courseQueryForm"
      :rules="courseQueryFormRulers"
      ref="editCourseFormRef"
      :inline="true"
    >
      <el-row :gutter="10">
        <el-col>
          <el-form-item label="主题:" prop="typeIdArr">
            <el-cascader
              style="width: 300px"
              :options="dict_category"
              v-model="courseQueryForm.typeIdArr"
              clearable
              :props="{
                checkStrictly: true,
                label: 'typeName',
                value: 'id',
                children: 'childrenCourseType',
              }"
              @change="courseTypeArrayChange(1)"
            ></el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col>
          <el-form-item label="区域:" prop="courseTypeIdArray">
            <el-cascader
              style="width: 300px"
              :options="areaCategory"
              v-model="courseQueryForm.courseTypeIdArray"
              clearable
              :props="{
                label: 'typeName',
                value: 'id',
                children: 'childrenCourseType',
                multiple: true
              }"
              @change="courseTypeArrayChange(2)"
            ></el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="课程缩略图:" prop="courseData.courseThumbnail">
            <el-upload
              accept="image/jpeg,image/gif,image/png,image/gpg"
              class="upload-demo"
              ref="uploadCoverImgRef"
              action="void"
              :http-request="customUpload"
              :on-change="onChange"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :show-file-list="false"
              multiple
              :auto-upload="false"
            >
              <el-image
                v-if="courseQueryForm.courseData.courseThumbnail"
                style="width: 100px; height: 100px"
                :src="courseQueryForm.courseData.courseThumbnail"
                fit="contain"
              >
              </el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="课程名称:" prop="courseData.courseName">
            <el-input
              v-model="courseQueryForm.courseData.courseName"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="课程简介:" prop="courseData.courseDescription">
            <el-input style="width: 500px;"
              :rows="6"
              v-model="courseQueryForm.courseData.courseDescription"
              placeholder="请输入"
              type="textarea"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="课程类型" prop="courseData.courseType">
            <template>
              <el-radio
                v-model="courseQueryForm.courseData.courseType"
                :label="1"
                >图文课程</el-radio
              >
              <el-radio
                v-model="courseQueryForm.courseData.courseType"
                :label="2"
                >视频课程</el-radio
              >
            </template>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="内容管理:">
            <el-upload
              class="upload-demo"
              :accept="fileDataType"
              ref="fileDataRef"
              action="void"
              :limit="1"
              :http-request="fileDataUpload"
              :on-remove="handleRemove"
              :on-change="fileOnChange"
              :on-success="uploadFileSuccess"
              :on-error="uploadFileError"
              :file-list="fileList"
              :auto-upload="false"
            >
              <el-button slot="trigger" size="small" type="primary"
                >选取文件</el-button
              >
              <div slot="tip" class="el-upload-tipclass" v-if="!editInit">{{ promptCopy }}</div>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="showPwd || courseQueryForm.courseData.password">
        <el-col :span="24">
          <el-form-item label="PPT文件密码:">
            <el-input
              v-model="courseQueryForm.courseData.password"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <div class="tip">*提示：密码请设置为英文大小写+特殊字符+数字，以降低被破解的风险。</div>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="showPwd || courseQueryForm.courseData.password">
        <el-col :span="24">
          <el-form-item label="PPT文件权限:">
            <el-radio v-model="courseQueryForm.courseData.editPermissions" :label="0"
              >只读</el-radio
            >
            <el-radio v-model="courseQueryForm.courseData.editPermissions" :label="1"
              >可编辑转发</el-radio
            >
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="收费类型" prop="isFree">
            <template>
              <el-radio v-model="courseQueryForm.isFree" :label="0"
                >试看课程</el-radio
              >
              <el-radio v-model="courseQueryForm.isFree" :label="1"
                >付费课程</el-radio
              >
              <el-radio v-model="courseQueryForm.isFree" :label="2"
                >特惠课程</el-radio
              >
            </template>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="courseQueryForm.isFree !== 0">
        <el-form-item
          label="产品价格"
          prop="price"
          :required="courseQueryForm.isFree !== 0"
        >
          <el-input
            onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^00/, '0.')
                .replace(/^\./g, '0.').replace(/\.{2,}/g, '.').replace('.', '$#$')
                .replace(/\./g, '').replace('$#$', '.');"
            @blur="courseQueryForm.price = $event.target.value"
            v-model="courseQueryForm.price"
            placeholder="请输入"
            clearable
          >
          </el-input>
        </el-form-item>
      </el-row>
      <!-- keynote -->
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="主讲人:" prop="keynote">
            <el-input
              v-model="courseQueryForm.courseData.keynote"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="title_row">
        <el-col :span="24">
          <span>售卖设置</span>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="上架设置" prop="productStatus">
            <template>
              <el-radio v-model="courseQueryForm.productStatus" :label="1"
                >上架售卖</el-radio
              >
              <el-radio v-model="courseQueryForm.productStatus" :label="2"
                >暂不上架</el-radio
              >
            </template>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-form-item label="课程有效期" prop="handType">
          <el-radio v-model="courseQueryForm.handType" :label="6"
            >长期有效</el-radio
          >
          <el-radio v-model="courseQueryForm.handType" :label="1"
            >有效天数
          </el-radio>
        </el-form-item>
      </el-row>
      <el-row :gutter="10" v-if="courseQueryForm.handType === 1">
        <el-form-item label="有效天数" prop="days">
          <el-input
            v-model="courseQueryForm.days"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row :gutter="10" v-if="courseQueryForm.handType === 5">
        <el-form-item label="到期时间" prop="expiryDate">
          <el-date-picker
            format="yyyy-MM-dd HH:hh:mm"
            value-format="yyyy-MM-dd HH:hh:mm"
            v-model="courseQueryForm.expiryDate"
            type="datetime"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-row>
    </el-form>
    <el-row type="flex" justify="center" style="margin-top: 20px">
      <el-col :span="4">
        <el-button type="primary" style="width: 100%" @click="saveClicked"
          >保存</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
import env from '@/http/env'
import { secondaryLinkageCourseQuery, addTbProductListInfo, queryTbProductListInfo } from '@/http/api'
export default {
  created () {
    this.getSecondaryLinkageCourseQuery(1)
    this.getSecondaryLinkageCourseQuery(2)
    if (this.$route.query.courseId) {
      this.getCourseDetail()
      this.editInit = true
    }
  },
  data () {
    return {
      promptCopy: '',
      fileDataType: '',
      fileDataChange: false, // 是否修改了文件列表数据
      coverImageChange: false, // 是否修改了图封面图内容
      dayRequired: false,
      expiryDateRequired: false,
      priceRequired: false,
      fileDataList: [], // 存储最终提交给后台的资源列表数据
      uploadCoverResults: {
        upLoadType: 'cover',
        uploadUrl: env.baseIP + 'upload/uploadFileAliOss',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      courseQueryFormRulers: {
        'courseData.courseType': [{ required: true, message: '请选择', trigger: 'change' }],
        'courseData.courseThumbnail': [{ required: true, message: '请选择封面图', trigger: 'blur' }],
        'courseData.courseName': [{ required: true, message: '请输入', trigger: 'blur' }],
        'courseData.courseDescription': [{ required: true, message: '请输入', trigger: 'blur' }],
        'courseData.productStatus': [{ required: true, message: '请选择', trigger: 'change' }],
        typeIdArr: [{ required: true, message: '请选择', trigger: 'change' }],
        courseTypeIdArray: [{ required: true, message: '请选择', trigger: 'change' }],
        // keynote: [{ required: true, message: '请输入', trigger: 'blur' }],
        // subTypeCategory: [{ required: true, message: '请选择', trigger: 'change' }],
        isFree: [{ required: true, message: '请选择', trigger: 'change' }],
        handType: [{ required: true, message: '请选择', trigger: 'change' }],
        price: [{ required: this.priceRequired, type: 'number', message: '请输入', trigger: 'blur', transform: (value) => Number(value) }],
        expiryDate: [{ required: this.expiryDateRequired, message: '请选择', trigger: 'blur' }],
        days: [{ required: this.dayRequired, message: '请输入', trigger: 'blur' }],
        productStatus: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      uploadFileRequest: {
        upLoadType: 'file',
        uploadUrl: env.baseIP + 'upload/uploadFileAliOss',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      dict_category: [],
      dict_subCategory: [],
      dict_subTypeCategory: [],
      courseQueryForm: {
        activityType: this.$chnEngStatusCode.activityCourseType,
        courseList: [],
        courseData: {
          keynote: '',
          courseName: '',
          courseThumbnail: '',
          courseDescription: '',
          editPermissions: 0
        },
        expiryDate: '',
        days: '',
        price: null,
        typeIdArr: [],
        courseTypeIdArray: []
      },
      fileList: [],
      areaCategory: [], // 国内海外
      editInit: false,
      showPwd: false
    }
  },
  watch: {
    'courseQueryForm.isFree' () {
      this.$nextTick(() => {
        this.changePayRuler()
      })
    },
    'courseQueryForm.handType' () {
      this.$nextTick(() => {
        this.changeHandTypeRuler()
      })
    },
    'courseQueryForm.courseData.courseType' () {
      this.$nextTick(() => {
        this.changeCourseType()
      })
    }
  },
  methods: {
    changePayRuler () {
      this.priceRequired = this.courseQueryForm.isFree !== 0
    },
    changeHandTypeRuler () {
      this.expiryDateRequired = this.courseQueryForm.handType === 5
      this.dayRequired = this.courseQueryForm.handType === 1
    },
    changeCourseType () {
      this.fileDataType = this.courseQueryForm.courseData.courseType === 1 ? '.pdf, .pptx, .ppt, .PPT,' : '.mp4'
      this.promptCopy = this.courseQueryForm.courseData.courseType === 1 ? '*请上传文件' : '*请上传.mp4类型文件'
    },
    // 获取课程详情
    getCourseDetail () {
      queryTbProductListInfo({ productId: this.$route.query.courseId }).then((res) => {
        this.courseQueryForm = res.data
        if (this.courseQueryForm.days) {
          this.$set(this.courseQueryForm, 'days', this.courseQueryForm.days + '')
        }
        if (this.courseQueryForm.courseList.length !== 0) {
          this.fileList = this.courseQueryForm.courseList.map(item => {
            item.name = item.courseUrl
            if (item.courseUrl.includes('ppt')) {
              this.showPwd = true
              if (!item.editPermissions) {
                item.editPermissions = 0
              }
            }
            return item
          })
          this.$set(this.courseQueryForm, 'courseData', this.courseQueryForm.courseList[0])
          // console.log('courseData', this.courseQueryForm.courseData)
          if (this.courseQueryForm.courseData.typeIdArr) {
            this.$set(this.courseQueryForm, 'typeIdArr', JSON.parse(this.courseQueryForm.courseData.typeIdArr))
          }
          // console.log('courseTypeIdArray', this.courseQueryForm)
          if (this.courseQueryForm.courseData.courseTypeIdArray) {
            this.$set(this.courseQueryForm, 'courseTypeIdArray', JSON.parse(this.courseQueryForm.courseData.courseTypeIdArray))
          }

          // this.$set(this.courseQueryForm, 'typeIdArr', [this.courseQueryForm.category, this.courseQueryForm.subCategory, this.courseQueryForm.subTypeCategory])
        }
        // this.getSecondaryLinkageCourseQuery(1)
        // this.getSecondaryLinkageCourseQuery(2)
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取课程二级联动类型
    getSecondaryLinkageCourseQuery (type) {
      secondaryLinkageCourseQuery({ type }).then((res) => {
        if (type === 1) { // 八大主题
          console.log('res', res)
          this.dict_category = res.data
          if (this.$route.query.courseId) {
            // for (let index = 0; index < this.dict_category.length; index++) {
            //   const element = this.dict_category[index]
            //   if (element.id === this.courseQueryForm.category) {
            //     this.dict_subCategory = this.dict_category[index].childrenCourseType
            //     this.$set(this.dict_subCategory)
            //     for (let childIndex = 0; childIndex < this.dict_subCategory.length; childIndex++) {
            //       const childelement = this.dict_subCategory[childIndex]
            //       if (childelement.id === this.courseQueryForm.subCategory) {
            //         this.dict_subTypeCategory = this.dict_subCategory[childIndex].childrenCourseType
            //         this.$set(this.dict_subTypeCategory)
            //       }
            //     }
            //   }
            // }
          }
        } else if (type === 2) {
          this.areaCategory = res.data
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 课程分类选择
    courseTypeArrayChange (type) {
      console.log(this.courseQueryForm.typeIdArr)
      if (type === 1) {
        this.courseQueryForm.typeId = `${this.courseQueryForm.typeIdArr[0]}-${this.courseQueryForm.typeIdArr[1]}`
      } else if (type === 2) {
        // const arr = []
        // this.courseQueryForm.courseTypeIdArray.forEach(fir => {
        //   console.log(fir)
        //   fir.forEach(sec => {
        //     arr.push(sec)
        //   })
        // })
        // this.courseQueryForm.courseTypeId = arr.join('-')
        // console.log(this.courseQueryForm.courseTypeId)
      }
    },
    // 封面图选择
    onChange (file) {
      this.$set(this.courseQueryForm.courseData, 'courseThumbnail', URL.createObjectURL(file.raw))
      this.coverImageChange = true
    },
    // 封面图上传成功
    uploadSuccess () {
      this.coverImageChange = false
    },
    // 封面图上传失败
    uploadError () {
      this.$refs.uploadCoverImgRef.clearFiles()
      this.coverImageChange = true
    },
    // 文件选择
    fileOnChange (filed, filelist) {
      if (filelist.length > 1) {
        filelist.splice(0, 1)
        return
      }
      const file = filed.raw
      if (file.name.includes('ppt') || file.name.includes('pptx')) {
        this.showPwd = true
      } else {
        this.showPwd = false
      }
      // 获取视频时长
      var url = URL.createObjectURL(file)
      var audioElement = new Audio(url)
      var duration
      this.durationNumber = audioElement.addEventListener(
        'loadedmetadata',
        function (_event) {
          duration = audioElement.duration // 时长为秒，小数
          return duration
        }
      )
      let fileSize = 0
      setTimeout(() => {
        fileSize = file.size / 1024 / 1024
      }, 50)
      if (fileSize > 200) {
        var filearrays = filelist.map((item, index) => {
          if (item.name === filed.name) {
            filelist.splice(index, 1)
          }
        })
        this.fileList = filearrays
        this.$message({
          type: 'error',
          message: '文件最大上传200M'
        })
        return false
      }
      this.fileDataChange = true
    },
    // 文件列表移除时的回调
    handleRemove (file, fileList) {
      console.log('fileList', fileList)
      this.showPwd = false
      this.$refs.fileDataRef.abort() // 取消上传
      this.fileDataChange = true
    },

    // 封面图上传
    customUpload (params) {
      this.$upLoadFileRequest.uploadFileResults(
        params, this.uploadCoverResults
      ).then(value => {
        this.courseQueryForm.courseData.courseThumbnail = value.data.data
        //  列表文件有修改，则从列表文件开始上传
        //  两个都没有修改则直接上传课程内容
        if (this.fileDataChange === true) {
          for (let index = 0; index < this.$refs.fileDataRef.uploadFiles.length; index++) {
            const element = this.$refs.fileDataRef.uploadFiles[index]
            if (element.courseUrl) {
              this.fileDataList.push(element)
            }
          }
          if (this.fileDataList.length === this.$refs.fileDataRef.uploadFiles.length) {
            this.upLoadCourseData()
          } else {
            this.$refs.fileDataRef.submit()
          }
        } else {
          this.upLoadCourseData()
        }
      })
    },
    // 文件列表上传
    fileDataUpload (params) {
      console.log('params', params)
      this.$upLoadFileRequest.uploadFileResults(
        params, this.uploadFileRequest
      ).then(value => {
        this.fileDataList.push({ fileUrl: value.data.data })
        if (this.fileDataList.length === this.$refs.fileDataRef.uploadFiles.length) {
          this.upLoadCourseData()
        }
      })
    },
    // 文件上传成功
    uploadFileSuccess () {
      this.fileDataChange = false
    },
    // 文件上传失败
    uploadFileError () {
      this.$refs.fileDataRef.clearFiles()
      this.fileDataChange = true
    },
    // 保存课程内容
    saveClicked () {
      console.log('this.courseQueryForm', this.courseQueryForm)
      if (this.$refs.fileDataRef.uploadFiles.length === 0) {
        return this.$message.warning('请先选择需要上传的文件！')
      }
      this.$refs.editCourseFormRef.validate(valid => {
        if (!valid) return
        this.$showLoading.show()
        //  如果封面图有修改则从封面图开始上传
        //  列表文件有修改，则从列表文件开始上传
        //  两个都没有修改则直接上传课程内容
        if (this.coverImageChange === true) {
          this.$refs.uploadCoverImgRef.submit()
        } else if (this.coverImageChange === false && this.fileDataChange === true) {
          for (let index = 0; index < this.$refs.fileDataRef.uploadFiles.length; index++) {
            const element = this.$refs.fileDataRef.uploadFiles[index]
            if (element.courseUrl) {
              this.fileDataList.push(element)
            }
          }
          if (this.fileDataList.length === this.$refs.fileDataRef.uploadFiles.length) {
            this.upLoadCourseData()
          } else {
            this.$refs.fileDataRef.submit()
          }
        } else {
          this.upLoadCourseData()
        }
      })
    },
    upLoadCourseData () {
      this.$showLoading.hide()
      const courseList = []
      if (this.fileDataChange === true) {
        for (let index = 0; index < this.fileDataList.length; index++) {
          const element = this.fileDataList[index]
          if (element.id) {
            courseList.push(element)
          } else {
            const courseData = JSON.parse(JSON.stringify(this.courseQueryForm.courseData))
            courseData.courseUrl = element.fileUrl
            courseData.typeId = this.courseQueryForm.subTypeCategory
            // const courseDict = {
            //   courseType: this.courseQueryForm.subTypeCategory,
            //   courseUrl: element.fileUrl,
            //   courseName: element.fileUrl,
            //   courseContent: this.courseQueryForm.courseData.courseDescription
            // }
            courseList.push(courseData)
          }
        }
        this.courseQueryForm.courseList = courseList
      }
      // this.courseQueryForm.price = ((this.courseQueryForm.isFree === 0 || Number(this.courseQueryForm.price) === 0) ? '0.00' : this.courseQueryForm.price)
      console.log('courseQueryForm', this.courseQueryForm)
      // this.courseQueryForm.typeIdArr = JSON.stringify(this.courseQueryForm.typeIdArr)
      // this.courseQueryForm.courseTypeIdArray = JSON.stringify(this.courseQueryForm.courseTypeIdArray)
      addTbProductListInfo(this.courseQueryForm).then((res) => {
        console.log('addTbProductListInfo', res)
        this.$message.success('保存成功！')
        this.$emit('gobackClicked')
      }).catch((err) => {
        console.log('返回的err', err)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.el-upload-tipclass {
  color: red;
  font-size: 13px;
}
.courseImage {
  widows: 100px;
  height: 100px;
}
.avatar {
  width: 100px;
  height: 100px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px solid #8c939d;
}
.tip{
  color: red;
  font-size: 13px;
  margin-bottom: 20px;
  margin-left: 110px;
}
</style>
