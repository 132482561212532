<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>个人赛考务管理</el-breadcrumb-item>
      <el-breadcrumb-item>初选成绩查询</el-breadcrumb-item>
      <el-breadcrumb-item>答题详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
      <div v-for="(item, index) in answerResultData" :key="index">
        <el-card style="margin-top: 15px">
        <el-descriptions border>
        <el-descriptions-item label="当前赛季">{{
          item.season
        }}</el-descriptions-item>
        <el-descriptions-item label="答题状态">
          <template>
            <el-tag type="danger" v-if="item.answerStatus === 2" size="mini"
              >进行中</el-tag
            >
            <el-tag
              type="success"
              v-else-if="item.answerStatus === 1"
              size="mini"
              >已完成</el-tag
            >
            <span v-else>-</span>
          </template></el-descriptions-item
        >
        <el-descriptions-item label="本轮成绩"><span style="color: orange">{{
          item.score
        }}</span></el-descriptions-item>
      </el-descriptions>
          <el-table
            header-cell-class-name="tableHeaderStyle"
            :data="item.resultList"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            border
            stripe
          >
            <el-table-column label="#" type="index"></el-table-column>
            <el-table-column label="用户答案" prop="userAnswer">
              <template slot-scope="scope">
                <img v-if="scope.row.userAnswer.indexOf('https') > -1" :src="scope.row.userAnswer" />
                <template v-else-if="scope.row.userAnswer.indexOf('[') > -1 && scope.row.userAnswer.indexOf(']') > -1">{{formatFn(scope.row.userAnswer)}}</template>
                <template v-else-if="scope.row.userAnswer === 'true'"><i class="el-icon-check" style="font-size: 32px; color: #67C23A;"></i></template>
                <template v-else-if="scope.row.userAnswer === 'false'"><i class="el-icon-close" style="font-size: 32px; color: #F56C6C;" ></i></template>
                <template v-else>{{ scope.row.userAnswer }}</template>
              </template>
            </el-table-column>
            <el-table-column label="系统答案" prop="questionAnswer">
              <template slot-scope="scope">
                <img v-if="scope.row.questionAnswer.indexOf('https') > -1" :src="scope.row.questionAnswer" />
                <template v-else-if="scope.row.questionAnswer === '1'"><i class="el-icon-check" style="font-size: 32px; color: #67C23A;"></i></template>
                <template v-else-if="scope.row.questionAnswer === '0'"><i class="el-icon-close" style="font-size: 32px; color: #F56C6C;" ></i></template>
                <template v-else>{{ scope.row.questionAnswer }}</template>
              </template>
            </el-table-column>
            <el-table-column label="是否正确" type="isRight">
              <template slot-scope="scope">
                <el-tag
                  type="success"
                  v-if="scope.row.isRight === '1'"
                  size="mini"
                  >正确</el-tag
                >
                <el-tag
                  type="danger"
                  v-else-if="scope.row.isRight === '0'"
                  size="mini"
                  >错误</el-tag
                >
                <span v-else>-</span>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
  </div>
</template>

<script>

import { getAnswerRaceScheduleByUserId } from '@/http/api'

export default {
  data () {
    return {
      dataList: [],
      answerResultData: [],
      formData: JSON.parse(this.$route.query.formDataStr)
    }
  },
  created () {
    this.seasonHandleChange()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    seasonHandleChange () {
      getAnswerRaceScheduleByUserId(this.formData).then((res) => {
        console.log('getSchoolList', res)
        this.answerResultData = res.data.map(item => {
          item.resultList = JSON.parse(item.results)
          return item
        })
        console.log('answerResultData', this.answerResultData)
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    formatFn (str) {
      return JSON.parse(str).join(',')
    }
  }
}
</script>

<style>

</style>
