<template>
  <div class="welcome-container">
    <div>
      <div class="h1"> WELCOME {{ username }} TO</div>
      <img class="logo" src="../../assets/img/newerp_button3.png" />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      username: window.sessionStorage.getItem('username')
    }
  }
}
</script>
<style scoped lang="less">
.welcome-container{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.welcome-container>div{
  width: 60%;
}
.logo{
  width: 100%;
}
.h1 {
  font-weight: 600;
  font-size: 40px;
  color: #231816;
  margin-bottom: 20px;
  padding-left: 170px;
}
</style>
