<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 订单信息 -->
    <el-card style="margin-top: 15px">
      <el-descriptions title="订单信息">
        <el-descriptions-item
          label="订单编号"
          :contentStyle="{ color: 'orange' }"
          >{{ orderData.orderInfo.orderNo }}</el-descriptions-item
        >
        <el-descriptions-item label="产品名称">{{
          orderData.orderInfo.orderName
        }}</el-descriptions-item>
        <el-descriptions-item label="支付类型">
          <template>
            <el-tag
              type="danger"
              v-if="orderData.orderInfo.orderSource === 1"
              size="small"
              >小程序</el-tag
            >
            <el-tag
              type="danger"
              v-else-if="orderData.orderInfo.orderSource === 2"
              size="small"
              >网站</el-tag
            >
            <el-tag
              type="danger"
              v-else-if="orderData.orderInfo.orderSource === 3"
              size="small"
              >线下</el-tag
            >
            <el-tag
              type="danger"
              v-else-if="orderData.orderInfo.orderSource === 4"
              size="small"
              >表单大师</el-tag
            >
            <span v-else>-</span>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="订单金额"
          >￥{{ orderData.orderInfo.orderAmount }}</el-descriptions-item
        >
        <el-descriptions-item label="优惠金额"
          >￥{{ orderData.orderInfo.discountAmount }}</el-descriptions-item
        >
        <el-descriptions-item
          label="实际支付金额"
          :contentStyle="{ color: 'red' }"
          >￥{{ orderData.orderInfo.paymentAmount }}</el-descriptions-item
        >
        <el-descriptions-item label="下单时间">{{
          orderData.orderInfo.orderTime
        }}</el-descriptions-item>
        <el-descriptions-item label="是否寄送">
          <template>
            <el-tag
              type="danger"
              v-if="orderData.orderInfo.isSend === 0"
              size="mini"
              >无需寄送</el-tag
            >
            <el-tag
              type="success"
              v-else-if="orderData.orderInfo.isSend === 1"
              size="mini"
              >需要寄送</el-tag
            >
            <span v-else>-</span>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="订单状态">
          <template>
            <el-tag
              type="warning"
              v-if="orderData.orderInfo.orderStatus === 1"
              size="small"
              >待支付</el-tag
            >
            <el-tag
              type="warning"
              v-else-if="orderData.orderInfo.orderStatus === 2"
              size="small"
              >待发货/已支付</el-tag
            >
            <el-tag
              type="success"
              v-else-if="orderData.orderInfo.orderStatus === 3"
              size="small"
              >已发货</el-tag
            >
            <el-tag
              type="success"
              v-else-if="orderData.orderInfo.orderStatus === 4"
              size="small"
              >订单完成</el-tag
            >
            <el-tag
              type="danger"
              v-else-if="orderData.orderInfo.orderStatus === 5"
              size="small"
              >申请退款</el-tag
            >
            <el-tag
              type="warning"
              v-else-if="orderData.orderInfo.orderStatus === 6"
              size="small"
              >退款审核中</el-tag
            >
            <el-tag
              type="success"
              v-else-if="orderData.orderInfo.orderStatus === 7"
              size="small"
              >退款成功</el-tag
            >
            <el-tag
              type="danger"
              v-else-if="orderData.orderInfo.orderStatus === 8"
              size="small"
              >退款失败</el-tag
            >
            <span v-else>-</span>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="备注">{{
          orderData.orderInfo.remark
        }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 支付信息 -->
    <el-card style="margin-top: 15px">
      <el-descriptions title="支付信息">
        <el-descriptions-item
          label="订单编号"
          :contentStyle="{ color: 'orange' }"
          >{{ orderData.tbOrderPay.orderNo }}</el-descriptions-item
        >
        <el-descriptions-item label="支付平台">
          <template>
            <el-tag type="success" size="mini">{{
              orderData.tbOrderPay.payPlatform
            }}</el-tag>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="支付类型">{{
          orderData.tbOrderPay.payType
        }}</el-descriptions-item>
        <el-descriptions-item label="支付金额"
          >￥{{ orderData.tbOrderPay.payPrice }}</el-descriptions-item
        >
        <el-descriptions-item label="IP地址">{{
          orderData.tbOrderPay.ip
        }}</el-descriptions-item>
        <el-descriptions-item label="支付状态">
          <template>
            <el-tag
              type="danger"
              v-if="orderData.tbOrderPay.payStatus === 1"
              size="small"
              >失败</el-tag
            >
            <el-tag
              type="success"
              v-else-if="orderData.tbOrderPay.payStatus === 2"
              size="small"
              >成功</el-tag
            >
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="支付时间">{{
          orderData.tbOrderPay.payTime
        }}</el-descriptions-item>
        <el-descriptions-item label="微信订单">{{
          orderData.tbOrderPay.transactionId
        }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 收件人信息 -->
    <el-card v-if="orderData.orderAddress" style="margin-top: 15px">
      <el-descriptions title="收件人信息">
        <el-descriptions-item label="收件人">{{
          orderData.orderAddress.receiptName
        }}</el-descriptions-item>
        <el-descriptions-item label="联系方式">{{
          orderData.orderAddress.receiptPhone
        }}</el-descriptions-item>
        <el-descriptions-item label="收货地址"
          >{{ orderData.orderAddress.province }}/{{
            orderData.orderAddress.city
          }}/{{ orderData.orderAddress.area }}</el-descriptions-item
        >
        <el-descriptions-item label="详细地址">{{
          orderData.orderAddress.receiptAddress
        }}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 产品信息 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="product_title">产品信息</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="tbOrderProductList"
        border
        stripe
      >
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="赛季" prop="season"></el-table-column>
        <el-table-column label="商品名称" prop="productName"> </el-table-column>
        <el-table-column label="商品数量" prop="productNum"> </el-table-column>
        <el-table-column label="商品单价" prop="unitPrice">
          <template slot-scope="scope">
            <span>￥{{ scope.row.unitPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商品总价" prop="totalAmount">
          <template slot-scope="scope">
            <span>￥{{ scope.row.totalAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="处理方式"
          prop="handType"
          :formatter="handTypeFormatter"
        ></el-table-column>
      </el-table>
    </el-card>
    <!-- 物流信息 -->
    <el-card v-if="orderData.tbOrderExpress" style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="product_title">物流信息</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="[orderData.tbOrderExpress]"
        border
        stripe
      >
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="物流单号" prop="expressNumber"> </el-table-column>
        <el-table-column
          label="物流公司"
          prop="expressType"
          :formatter="expressFormatter"
        ></el-table-column>
        <el-table-column label="订单号" prop="orderNo"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { getTbOrderInfo } from '@/http/api'

export default {
  data () {
    return {
      dict_express: this.$userInfo.getDataList(this, 'express_type').then(value => { this.dict_express = value }),
      dict_handType: this.$userInfo.getDataList(this, 'p_hand_type').then(value => { this.dict_handType = value }),
      orderData: {
        orderInfo: {},
        orderAddress: {},
        tbOrderPay: {}
      },
      tbOrderProductList: [],
      order_no: this.$route.query.order_no
    }
  },
  created () {
    sessionStorage.setItem('order_no', JSON.stringify(this.order_no))
    this.getTbOrderInfo()
  },
  methods: {
    // 获取订单详情
    getTbOrderInfo () {
      getTbOrderInfo({ orderNo: JSON.parse(sessionStorage.getItem('order_no')) }).then((res) => {
        console.log('getTbOrderInfo', res)
        this.$nextTick(() => {
          this.orderData = res.data
          this.tbOrderProductList = res.data.tbOrderProduct
        })
      }).catch((err) => {
        console.log('err', err)
      })
    },
    handTypeFormatter (data) {
      var temp = '未知'
      for (const key in this.dict_handType) {
        if (Object.hasOwnProperty.call(this.dict_handType, key)) {
          const element = this.dict_handType[key]
          if (element.dictValue === data.handType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    expressFormatter (data) {
      var temp = '未知'
      for (const key in this.dict_express) {
        if (Object.hasOwnProperty.call(this.dict_express, key)) {
          const element = this.dict_express[key]
          if (element.dictValue === data.expressType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
.product_title {
  display: -moz-inline-box;
  display: inline-block;
  width: 120px;
  color: #303133;
  font-size: 16px;
  font-weight: 700;
}
</style>
