import axios from 'axios'
export default {
  // 获取赛程信息
  uploadFileResults: async function (params, uploadFileResults) {
    const host = window.location.host
    const isTest = host.includes('test') || host.includes('localhost')
    const file = params.file
    const fileType = file.type
    const FormDatas = new FormData()
    let result = {}
    FormDatas.append('file', file)
    if (uploadFileResults.upLoadType === 'cover') {
      const isImage = fileType.indexOf('image') !== -1
      const isLt2M = file.size / 1024 / 1024 < 2
      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error('只能上传图片格式png、jpg、gif!')
        return
      }
      if (!isLt2M) {
        this.$message.error('只能上传图片大小小于2M')
        return
      }
      FormDatas.append('filePath', `chineseWord/erp/product/images/${isTest ? 'test/' : ''}`)
    } else if (uploadFileResults.upLoadType === 'file') {
      FormDatas.append('filePath', `chineseWord/webSite/course/file/${isTest ? 'test/' : ''}`)
    } else if (uploadFileResults.upLoadType === 'video') {
      FormDatas.append('filePath', `chineseWord/webSite/course/video/${isTest ? 'test/' : ''}`)
    }
    await axios({
      url: uploadFileResults.uploadUrl,
      method: 'post',
      headers: uploadFileResults.headerObj,
      data: FormDatas,
      // 上传进度
      onUploadProgress: (progressEvent) => {
        const num = progressEvent.loaded / progressEvent.total * 100 | 0
        params.onProgress({ percent: num })
      }
    }).then(function (response) {
      result = response
    }).catch(() => {
      return this.$message.error('文件上传失败！')
    })
    return result
  }
}
