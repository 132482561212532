export default {
  /**
   * 获取字典数据
   * @param {*} dictType 字典类型（查看字典管理）
   * @returns Array 字典数据
   */
  getDataList: async function (that, dictType) {
    const { data: res } = await that.$http.get('/dict/getDictList', {
      params: {
        dictType: dictType
      }
    })
    console.log('res', res)
    return res
  },
  // ====================  ====================
  /**
   * 年级数据
   */
  getGradeData: function () {
    const gradeList = [
      {
        dictCode: 1,
        dictLabel: '一年级',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '二年级',
        dictValue: '2'
      },
      {
        dictCode: 3,
        dictLabel: '三年级',
        dictValue: '3'
      },
      {
        dictCode: 4,
        dictLabel: '四年级',
        dictValue: '4'
      },
      {
        dictCode: 5,
        dictLabel: '五年级',
        dictValue: '5'
      },
      {
        dictCode: 6,
        dictLabel: '六年级',
        dictValue: '6'
      },
      {
        dictCode: 7,
        dictLabel: '七年级',
        dictValue: '7'
      },
      {
        dictCode: 8,
        dictLabel: '八年级',
        dictValue: '8'
      },
      {
        dictCode: 9,
        dictLabel: '九年级',
        dictValue: '9'
      },
      {
        dictCode: 10,
        dictLabel: '高一',
        dictValue: '10'
      },
      {
        dictCode: 11,
        dictLabel: '高二',
        dictValue: '11'
      },
      {
        dictCode: 12,
        dictLabel: '高三',
        dictValue: '12'
      }
    ]
    return gradeList
  },
  /**
   * 身份类型
   */
  dict_idCardType () {
    const idCardType = [
      {
        dictCode: 1,
        dictLabel: '身份证',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '护照',
        dictValue: '2'
      }
    ]
    return idCardType
  },
  dict_sex () {
    const dictSex = [
      {
        dictCode: 1,
        dictLabel: '男',
        dictValue: '1'
      },
      {
        dictCode: 0,
        dictLabel: '女',
        dictValue: '0'
      }
    ]
    return dictSex
  },
  p_open_isFree () {
    const isFree = [
      {
        dictCode: 0,
        dictLabel: '试看',
        dictValue: 0
      },
      {
        dictCode: 1,
        dictLabel: '付费',
        dictValue: 1
      },
      {
        dictCode: 2,
        dictLabel: '特惠',
        dictValue: 2
      }
    ]
    return isFree
  },
  dict_c_type () {
    const dictType = [
      {
        dictCode: 0,
        dictLabel: '独体字',
        dictValue: 0
      },
      {
        dictCode: 1,
        dictLabel: '合体字',
        dictValue: 1
      }
    ]
    return dictType
  },
  dict_schoolType () {
    const schoolType = [
      {
        dictCode: 1,
        dictLabel: '公立学校',
        dictValue: '0'
      },
      {
        dictCode: 2,
        dictLabel: '私立学校',
        dictValue: '1'
      }, {
        dictCode: 3,
        dictLabel: '外籍子女学校',
        dictValue: '2'
      }
    ]
    return schoolType
  },
  dict_countries () {
    const countriesList = [
      { value: 'Angola', label: '安哥拉' },
      { value: 'Afghanistan', label: '阿富汗' },
      { value: 'Albania', label: '阿尔巴尼亚' },
      { value: 'Algeria', label: '阿尔及利亚' },
      { value: 'Andorra', label: '安道尔共和国' },
      { value: 'Anguilla', label: '安圭拉岛' },
      { value: 'Antigua and Barbuda', label: '安提瓜和巴布达' },
      { value: 'Argentina', label: '阿根廷' },
      { value: 'Armenia', label: '亚美尼亚' },
      { value: 'Ascension', label: '阿森松' },
      { value: 'Australia', label: '澳大利亚' },
      { value: 'Austria', label: '奥地利' },
      { value: 'Azerbaijan', label: '阿塞拜疆' },
      { value: 'Bahamas', label: '巴哈马' },
      { value: 'Bahrain', label: '巴林' },
      { value: 'Bangladesh', label: '孟加拉国' },
      { value: 'Barbados', label: '巴巴多斯' },
      { value: 'Belarus', label: '白俄罗斯' },
      { value: 'Belgium', label: '比利时' },
      { value: 'Belize', label: '伯利兹' },
      { value: 'Benin', label: '贝宁' },
      { value: 'Bermuda Is', label: '百慕大群岛' },
      { value: 'Bolivia', label: '玻利维亚' },
      { value: 'Botswana', label: '博茨瓦纳' },
      { value: 'Brazil', label: '巴西' },
      { value: 'Brunei', label: '文莱' },
      { value: 'Bulgaria', label: '保加利亚' },
      { value: 'Burkina Faso', label: '布基纳法索' },
      { value: 'Burma', label: '缅甸' },
      { value: 'Burundi', label: '布隆迪' },
      { value: 'Cameroon', label: '喀麦隆' },
      { value: 'Canada', label: '加拿大' },
      { value: 'Cayman Is', label: '开曼群岛' },
      { value: 'Central African Republic', label: '中非共和国' },
      { value: 'Chad', label: '乍得' },
      { value: 'Chile', label: '智利' },
      { value: 'China', label: '中国', disabled: true },
      { value: 'Colombia', label: '哥伦比亚' },
      { value: 'Congo', label: '刚果' },
      { value: 'Cook Is', label: '库克群岛' },
      { value: 'Costa Rica', label: '哥斯达黎加' },
      { value: 'Cuba', label: '古巴' },
      { value: 'Cyprus', label: '塞浦路斯' },
      { value: 'Czech Republic', label: '捷克' },
      { value: 'Denmark', label: '丹麦' },
      { value: 'Djibouti', label: '吉布提' },
      { value: 'Dominica Rep', label: '多米尼加共和国' },
      { value: 'Ecuador', label: '厄瓜多尔' },
      { value: 'Egypt', label: '埃及' },
      { value: 'EI Salvador', label: '萨尔瓦多' },
      { value: 'Estonia', label: '爱沙尼亚' },
      { value: 'Ethiopia', label: '埃塞俄比亚' },
      { value: 'Fiji', label: '斐济' },
      { value: 'Finland', label: '芬兰' },
      { value: 'France', label: '法国' },
      { value: 'French Guiana', label: '法属圭亚那' },
      { value: 'French Polynesia', label: '法属玻利尼西亚' },
      { value: 'Gabon', label: '加蓬' },
      { value: 'Gambia', label: '冈比亚' },
      { value: 'Georgia', label: '格鲁吉亚' },
      { value: 'Ghana', label: '加纳' },
      { value: 'Gibraltar', label: '直布罗陀' },
      { value: 'Greece', label: '希腊' },
      { value: 'Grenada', label: '格林纳达' },
      { value: 'Guam', label: '关岛' },
      { value: 'Guatemala', label: '危地马拉' },
      { value: 'Guinea', label: '几内亚' },
      { value: 'Guyana', label: '圭亚那' },
      { value: 'Haiti', label: '海地' },
      { value: 'Honduras', label: '洪都拉斯' },
      { value: 'Hungary', label: '匈牙利' },
      { value: 'Iceland', label: '冰岛' },
      { value: 'India', label: '印度' },
      { value: 'Indonesia', label: '印度尼西亚' },
      { value: 'Iran', label: '伊朗' },
      { value: 'Iraq', label: '伊拉克' },
      { value: 'Ireland', label: '爱尔兰' },
      { value: 'Israel', label: '以色列' },
      { value: 'Italy', label: '意大利' },
      { value: 'Ivory Coast', label: '科特迪瓦' },
      { value: 'Jamaica', label: '牙买加' },
      { value: 'Japan', label: '日本' },
      { value: 'Jordan', label: '约旦' },
      { value: 'Kampuchea (Cambodia )', label: '柬埔寨' },
      { value: 'Kazakstan', label: '哈萨克斯坦' },
      { value: 'Kenya', label: '肯尼亚' },
      { value: 'Korea', label: '韩国' },
      { value: 'Kuwait', label: '科威特' },
      { value: 'Kyrgyzstan', label: '吉尔吉斯坦' },
      { value: 'Laos', label: '老挝' },
      { value: 'Latvia', label: '拉脱维亚' },
      { value: 'Lebanon', label: '黎巴嫩' },
      { value: 'Lesotho', label: '莱索托' },
      { value: 'Liberia', label: '利比里亚' },
      { value: 'Libya', label: '利比亚' },
      { value: 'Liechtenstein', label: '列支敦士登' },
      { value: 'Lithuania', label: '立陶宛' },
      { value: 'Luxembourg', label: '卢森堡' },
      { value: 'Madagascar', label: '马达加斯加' },
      { value: 'Malawi', label: '马拉维' },
      { value: 'Malaysia', label: '马来西亚' },
      { value: 'Maldives', label: '马尔代夫' },
      { value: 'Mali', label: '马里' },
      { value: 'Malta', label: '马耳他' },
      { value: 'Mariana Is', label: '马里亚那群岛' },
      { value: 'Martinique', label: '马提尼克' },
      { value: 'Mauritius', label: '毛里求斯' },
      { value: 'Mexico', label: '墨西哥' },
      { value: 'Moldova', label: '摩尔多瓦' },
      { value: 'Monaco', label: '摩纳哥' },
      { value: 'Mongolia', label: '蒙古' },
      { value: 'Montserrat Is', label: '蒙特塞拉特岛' },
      { value: 'Morocco', label: '摩洛哥' },
      { value: 'Mozambique', label: '莫桑比克' },
      { value: 'Namibia', label: '纳米比亚' },
      { value: 'Nauru', label: '瑙鲁' },
      { value: 'Nepal', label: '尼泊尔' },
      { value: 'Netheriands Antilles', label: '荷属安的列斯' },
      { value: 'Netherlands', label: '荷兰' },
      { value: 'New Zealand', label: '新西兰' },
      { value: 'Nicaragua', label: '尼加拉瓜' },
      { value: 'Niger', label: '尼日尔' },
      { value: 'Nigeria', label: '尼日利亚' },
      { value: 'North Korea', label: '朝鲜' },
      { value: 'Norway', label: '挪威' },
      { value: 'Oman', label: '阿曼' },
      { value: 'Pakistan', label: '巴基斯坦' },
      { value: 'Panama', label: '巴拿马' },
      { value: 'Papua New Cuinea', label: '巴布亚新几内亚' },
      { value: 'Paraguay', label: '巴拉圭' },
      { value: 'Peru', label: '秘鲁' },
      { value: 'Philippines', label: '菲律宾' },
      { value: 'Poland', label: '波兰' },
      { value: 'Portugal', label: '葡萄牙' },
      { value: 'Puerto Rico', label: '波多黎各' },
      { value: 'Qatar', label: '卡塔尔' },
      { value: 'Reunion', label: '留尼旺' },
      { value: 'Romania', label: '罗马尼亚' },
      { value: 'Russia', label: '俄罗斯' },
      { value: 'Saint Lueia', label: '圣卢西亚' },
      { value: 'Saint Vincent', label: '圣文森特岛' },
      { value: 'Samoa Eastern', label: '东萨摩亚(美)' },
      { value: 'Samoa Western', label: '西萨摩亚' },
      { value: 'San Marino', label: '圣马力诺' },
      { value: 'Sao Tome and Principe', label: '圣多美和普林西比' },
      { value: 'Saudi Arabia', label: '沙特阿拉伯' },
      { value: 'Senegal', label: '塞内加尔' },
      { value: 'Seychelles', label: '塞舌尔' },
      { value: 'Sierra Leone', label: '塞拉利昂' },
      { value: 'Singapore', label: '新加坡' },
      { value: 'Slovakia', label: '斯洛伐克' },
      { value: 'Slovenia', label: '斯洛文尼亚' },
      { value: 'Solomon Is', label: '所罗门群岛' },
      { value: 'Somali', label: '索马里' },
      { value: 'South Africa', label: '南非' },
      { value: 'Spain', label: '西班牙' },
      { value: 'SriLanka', label: '斯里兰卡' },
      { value: 'St.Lucia', label: '圣卢西亚' },
      { value: 'St.Vincent', label: '圣文森特' },
      { value: 'Sudan', label: '苏丹' },
      { value: 'Suriname', label: '苏里南' },
      { value: 'Swaziland', label: '斯威士兰' },
      { value: 'Sweden', label: '瑞典' },
      { value: 'Switzerland', label: '瑞士' },
      { value: 'Syria', label: '叙利亚' },
      { value: 'Tajikstan', label: '塔吉克斯坦' },
      { value: 'Tanzania', label: '坦桑尼亚' },
      { value: 'Thailand', label: '泰国' },
      { value: 'Togo', label: '多哥' },
      { value: 'Tonga', label: '汤加' },
      { value: 'Trinidad and Tobago', label: '特立尼达和多巴哥' },
      { value: 'Tunisia', label: '突尼斯' },
      { value: 'Turkey', label: '土耳其' },
      { value: 'Turkmenistan', label: '土库曼斯坦' },
      { value: 'Uganda', label: '乌干达' },
      { value: 'Ukraine', label: '乌克兰' },
      { value: 'United Arab Emirates', label: '阿拉伯联合酋长国' },
      { value: 'United Kiongdom', label: '英国' },
      { value: 'United States of America', label: '美国' },
      { value: 'Uruguay', label: '乌拉圭' },
      { value: 'Uzbekistan', label: '乌兹别克斯坦' },
      { value: 'Venezuela', label: '委内瑞拉' },
      { value: 'Vietnam', label: '越南' },
      { value: 'Yemen', label: '也门' },
      { value: 'Yugoslavia', label: '南斯拉夫' },
      { value: 'Zimbabwe', label: '津巴布韦' },
      { value: 'Zaire', label: '扎伊尔' },
      { value: 'Zambia', label: '赞比亚' }
    ]
    return countriesList
  },
  dict_schoolGroup () {
    const schoolGroup = [
      {
        dictCode: 1,
        dictLabel: '小学',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '初中',
        dictValue: '2'
      },
      {
        dictCode: 3,
        dictLabel: '高中',
        dictValue: '3'
      }
    ]
    return schoolGroup
  },
  dict_joinMatch () {
    const dictJoinMatch = [
      {
        dictCode: 1,
        dictLabel: '参赛',
        dictValue: 1
      },
      {
        dictCode: 0,
        dictLabel: '不参赛',
        dictValue: 0
      }
    ]
    return dictJoinMatch
  },
  dict_cebsbSeason () {
    const cebsbSeason = [
      {
        dictCode: 1,
        dictLabel: this.$chnEngStatusCode.defaultSeason,
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '2023~2024',
        dictValue: '2'
      },
      {
        dictCode: 3,
        dictLabel: '2024~2025',
        dictValue: '3'
      }
    ]
    return cebsbSeason
  },
  dict_considerations () {
    const considerations = [
      {
        dictCode: 1,
        dictLabel: '100-200',
        dictValue: '100-200'
      },
      {
        dictCode: 2,
        dictLabel: '200-400',
        dictValue: '200-400'
      },
      {
        dictCode: 3,
        dictLabel: '400-600',
        dictValue: '400-600'
      },
      {
        dictCode: 4,
        dictLabel: '600-800',
        dictValue: '600-800'
      },
      {
        dictCode: 5,
        dictLabel: '800-1000',
        dictValue: '800-1000'
      },
      {
        dictCode: 6,
        dictLabel: '1000-1200',
        dictValue: '1000-1200'
      },
      {
        dictCode: 7,
        dictLabel: '1200-1500',
        dictValue: '1200-1500'
      },
      {
        dictCode: 8,
        dictLabel: '1500-2000',
        dictValue: '1500-2000'
      }
    ]
    return considerations
  },
  dict_group () {
    const group = [
      {
        dictCode: 1,
        dictLabel: '小初组（1-2年级）',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '小中组（3-4年级）',
        dictValue: '2'
      },
      {
        dictCode: 3,
        dictLabel: '小高组（5-6年级）',
        dictValue: '3'
      },
      {
        dictCode: 4,
        dictLabel: '初中组（7-9年级）',
        dictValue: '4'
      },
      {
        dictCode: 5,
        dictLabel: '高中组（10-12年级）',
        dictValue: '5'
      }
    ]
    return group
  },
  /**
   * 根据年级返回组别信息
   * @param {*} userGrade 年级数据
   * @returns 组别
   */
  getUserGroupWithGrade (userGrade) {
    let userGradeStr = ''
    let groupId = ''
    switch (userGrade + '') {
      case '1':
      case '2':
        userGradeStr = '小初组'
        groupId = '1'
        break
      case '3':
      case '4':
        userGradeStr = '小中组'
        groupId = '2'
        break
      case '5':
      case '6':
        userGradeStr = '小高组'
        groupId = '3'
        break
      case '7':
      case '8':
      case '9':
        userGradeStr = '初中组'
        groupId = '4'
        break
      case '10':
      case '11':
      case '12':
        userGradeStr = '高中组'
        groupId = '5'
        break
      default:
        userGradeStr = '未设置'
        groupId = '0'
    }
    const gradeData = {}
    gradeData.userGradeStr = userGradeStr
    gradeData.groupId = groupId
    return gradeData
  },
  /**
   * 试卷申请考量状态
   * 0 申请 1 通过/待寄出 2 未通过 3 已寄出 4 已寄回 5 成绩统计中/已收回 6成绩统计完毕/公布成绩
   * @returns
   */
  dict_considerationsState () {
    const considerationsState = [
      {
        dictCode: 0,
        dictLabel: '申请中',
        dictValue: '0'
      },
      {
        dictCode: 1,
        dictLabel: '待寄出',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '未通过',
        dictValue: '2'
      },
      {
        dictCode: 3,
        dictLabel: '已寄出',
        dictValue: '3'
      },
      {
        dictCode: 4,
        dictLabel: '已寄回',
        dictValue: '4'
      },
      {
        dictCode: 5,
        dictLabel: '成绩统计中',
        dictValue: '5'
      },
      {
        dictCode: 6,
        dictLabel: '成绩待公布',
        dictValue: '6'
      },
      {
        dictCode: 7,
        dictLabel: '成绩已公布',
        dictValue: '7'
      }
    ]
    return considerationsState
  },
  product_disposable () {
    const productDisposable = [
      {
        dictCode: 0,
        dictLabel: '多次使用',
        dictValue: '0'
      },
      {
        dictCode: 1,
        dictLabel: '一次性消耗',
        dictValue: '1'
      }
    ]
    return productDisposable
  },
  participation_way () {
    const participationWay = [
      {
        dictCode: 1,
        dictLabel: '社会考生线上',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '集体参赛线上',
        dictValue: '2'
      },
      {
        dictCode: 3,
        dictLabel: '集体参赛线下',
        dictValue: '3'
      }
    ]
    return participationWay
  },
  isPromotion () {
    const Promotion = [
      {
        dictCode: 0,
        dictLabel: '未设置',
        dictValue: '0'
      },
      {
        dictCode: 1,
        dictLabel: '已晋级',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '未晋级',
        dictValue: '2'
      }
    ]
    return Promotion
  },
  // 订单来源
  orderSource () {
    const orderSource = [
      {
        dictCode: 1,
        dictLabel: '小程序',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '网站',
        dictValue: '2'
      },
      {
        dictCode: 3,
        dictLabel: '线下',
        dictValue: '3'
      },
      {
        dictCode: 4,
        dictLabel: '表单大师',
        dictValue: '4'
      },
      {
        dictCode: 5,
        dictLabel: '获奖赠送',
        dictValue: '5'
      }
    ]
    return orderSource
  },
  // 订单状态
  orderStatus () {
    const orderStatus = [
      {
        dictCode: 1,
        dictLabel: '待支付',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '待发货/已支付',
        dictValue: '2'
      },
      {
        dictCode: 3,
        dictLabel: '已发货',
        dictValue: '3'
      },
      {
        dictCode: 4,
        dictLabel: '订单完成',
        dictValue: '4'
      },
      {
        dictCode: 5,
        dictLabel: '申请退款',
        dictValue: '5'
      },
      {
        dictCode: 6,
        dictLabel: '退款审核中',
        dictValue: '6'
      },
      {
        dictCode: 7,
        dictLabel: '退款成功',
        dictValue: '7'
      },
      {
        dictCode: 8,
        dictLabel: '退款失败',
        dictValue: '8'
      },
      {
        dictCode: 9,
        dictLabel: '订单关闭',
        dictValue: '9'
      }
    ]
    return orderStatus
  },
  // 赛区类型
  divisionType () {
    const divisiontype = [
      {
        dictCode: 1,
        dictLabel: '赛区',
        dictValue: 1
      },
      {
        dictCode: 0,
        dictLabel: '非赛区',
        dictValue: 0
      }
    ]
    return divisiontype
  },
  // 运营类型
  operationType () {
    const divisiontype = [
      {
        dictCode: 1,
        dictLabel: '授权',
        dictValue: 1
      },
      {
        dictCode: 0,
        dictLabel: '直营',
        dictValue: 0
      }
    ]
    return divisiontype
  },
  dict_courseType () {
    const dictCourseType = [
      {
        dictCode: 1,
        dictLabel: '图文课程',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '视频课程',
        dictValue: '2'
      }
    ]
    return dictCourseType
  },
  dict_selectType () {
    const dictSelectType = [
      {
        dictCode: 1,
        dictLabel: '学校',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '教师',
        dictValue: '2'
      }
    ]
    return dictSelectType
  },
  dict_coursePrice () {
    const dictCoursePrice = [
      {
        dictCode: 0,
        dictLabel: '试看课程',
        dictValue: 0
      },
      {
        dictCode: 1,
        dictLabel: '付费课程',
        dictValue: 1
      },
      {
        dictCode: 2,
        dictLabel: '特惠课程',
        dictValue: 2
      }
    ]
    return dictCoursePrice
  },
  dict_courseTheme () {
    const dictCurseTheme = [
      {
        dictCode: 0,
        dictLabel: '主题一',
        dictValue: 0
      },
      {
        dictCode: 1,
        dictLabel: '主题二',
        dictValue: 1
      },
      {
        dictCode: 2,
        dictLabel: '主题三',
        dictValue: 2
      }
    ]
    return dictCurseTheme
  },
  dict_courseClass () {
    const dictCourseClass = [
      {
        dictCode: 1,
        dictLabel: 'A1',
        dictValue: 1
      },
      {
        dictCode: 2,
        dictLabel: 'A2',
        dictValue: 2
      },
      {
        dictCode: 3,
        dictLabel: 'B1',
        dictValue: 3
      },
      {
        dictCode: 4,
        dictLabel: 'B2',
        dictValue: 4
      },
      {
        dictCode: 5,
        dictLabel: 'C2',
        dictValue: 5
      },
      {
        dictCode: 6,
        dictLabel: 'C2',
        dictValue: 6
      }
    ]
    return dictCourseClass
  },
  dict_isPackage () {
    const dictIsPackage = [
      {
        dictCode: 0,
        dictLabel: '产品不可选',
        dictValue: '0'
      },
      {
        dictCode: 1,
        dictLabel: '可选产品',
        dictValue: '1'
      },
      {
        dictCode: 2,
        dictLabel: '一对一产品',
        dictValue: '2'
      }
    ]
    return dictIsPackage
  },
  /**
   * 团体赛审核状态
   */
  dict_examine () {
    const dictExamine = [
      {
        dictCode: 1,
        dictLabel: '待审核',
        dictValue: 0
      }, {
        dictCode: 2,
        dictLabel: '审核通过/待缴费',
        dictValue: 1
      }, {
        dictCode: 3,
        dictLabel: '审核未通过',
        dictValue: 2
      }, {
        dictCode: 4,
        dictLabel: '已缴费',
        dictValue: 3
      }, {
        dictCode: 5,
        dictLabel: '已退费',
        dictValue: 4
      }
    ]
    return dictExamine
  }
}
