const env = process.env.NODE_ENV
// 开发环境运行命令：npm run serve
// 生产环境打包命令：npm run pro
// 测试环境打包命令：npm run build
const IP = {
  development: { // 本地开发环境
    // baseIP: 'http://192.168.2.53:9865/erp_hq_dev/'
    // baseIP: 'https://hq.spbcn.org/erp_hq/'
    baseIP: 'https://hq.spbcn.org/erp_hq_test/'
  },
  production: { // 生产环境
    baseIP: 'https://hq.spbcn.org/erp_hq/'
    // baseIP: 'https://hy.spbcn.org/erp/'
  },
  test: { // 测试环境
    baseIP: 'https://hq.spbcn.org/erp_hq_test/'
  }
}
export default IP[env]
