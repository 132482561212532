<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>B端数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>赛区管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>赛区信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="100px"
        ref="queryFormRef"
      >
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="赛区名称" prop="districtName">
              <el-input
                v-model="queryForm.districtName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赛区编号" prop="districtNo">
              <el-input
                v-model="queryForm.districtNo"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getDataList('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
  <!-- 赛区列表 -->
  <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span
            style="
              display: -moz-inline-box;
              display: inline-block;
              width: 120px;
            "
            >赛区信息列表</span
          >
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="赛区名称" prop="districtName"></el-table-column>
        <el-table-column label="赛区编号" prop="districtNo"></el-table-column>
        <el-table-column label="初赛晋级分数线" prop="earlyMatch"></el-table-column>
        <el-table-column label="复赛晋级分数线" prop="repeatedMatch"></el-table-column>
        <el-table-column label="赛区电话" prop="districtTel"></el-table-column>
        <el-table-column label="赛区负责人" prop="userName"></el-table-column>
        <el-table-column label="负责人电话" prop="contactPhone"></el-table-column>
        <!-- <el-table-column label="邮箱" prop="contactEmail"></el-table-column> -->
        <el-table-column label="赛区类型" prop="divisionType">
          <template slot-scope="scope">
            <span v-if="scope.row.divisionType + '' === '0'">非赛区</span>
            <span v-else-if="scope.row.divisionType + '' === '1'">赛区</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="运营类型" prop="operationType">
          <template slot-scope="scope">
            <span v-if="scope.row.operationType + '' === '0'">直营赛区</span>
            <span v-else-if="scope.row.operationType + '' === '1'">授权赛区</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="赛区地址" prop="address"></el-table-column> -->
        <!-- <el-table-column label="提货地址" prop="pickAddress"></el-table-column> -->
        <!-- <el-table-column label="提货时间" prop="pickTime"></el-table-column> -->
        <!-- <el-table-column label="赛区介绍" prop="description"></el-table-column> -->
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="80px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="searchDetailsClicked(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { queryDistrictPage } from '@/http/api'
export default {
  data () {
    return {
      dataList: [],
      total: 0,
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        districtName: '',
        districtNo: ''
      }
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取赛区列表信息
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      queryDistrictPage(this.queryForm).then((res) => {
        console.log('queryDistrictPage', res)
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    searchDetailsClicked (rowData) {
      this.$router.push({
        path: '/system_data_Management/system_competition_details',
        query: {
          districtData: JSON.stringify(rowData)
        }
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    }
  }
}
</script>

<style>
</style>
