<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>个人赛赛务管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 个人赛程 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">个人赛赛程</span>
        </el-col>
      </el-row>
      <el-row :gutter="30">
      <div class="schedule-list">
        <el-col :span="6" v-for="(item, index) in scheduleCardList" v-bind:key="index">
          <div class="schedule-item">
              <el-card shadow="hover" style="border: none"
                :class="'card-background' + (index + 1)">
                <div class="schedule_card" @click="scheduleCardTap(item)">
                  <div class="schedule_gameName">{{ item.scheduleName }}</div>
                  <!-- <div class="schedule_gameNumber">
                    已报名{{ item.singUpNum }}
                  </div> -->
                </div>
              </el-card>
          </div>
        </el-col>
      </div>
      </el-row>
    </el-card>
    <!-- 个人赛赛务工具 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">个人赛赛务工具</span>
        </el-col>
      </el-row>
      <el-row  :gutter="30" style="margin-top: 34px">
        <el-col :span="4" v-for="(item, i) of racingToolList" v-bind:key="i">
          <div
            class="racing-tool-back"
            @click="racingToolClicked(item.menuTag)"
          >
            <div
              class="racing-tool-leftimg"
              :class="'coloricon' + item.menuTag"
            >
              <i class="icon iconfont" :class="item.icon"></i>
            </div>
            <div class="racing-tool-right">
              <div>{{ item.rightTitle }}</div>
              <div class="racing-tool-desc">{{ item.rightDesc }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <!-- 待处理事务 -->
    <el-card style="margin-top: 15px" v-if="false">
      <el-row class="title_row">
        <el-col>
          <span>待处理事务</span>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :span="3" v-for="(item, i) of processedList" v-bind:key="i">
          <div class="processed-list-back">
            <el-image
              class="processed-image"
              :src="item.iconImg"
              fit="contain"
            ></el-image>
            <div class="processed-title">{{ item.title }}</div>
            <div class="processed-desc">{{ item.number }}</div>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <!-- 数据统计 -->
    <el-tabs type="border-card" style="margin-top: 15px" v-if="false">
      <el-row class="title_row">
        <el-col>
          <span>数据统计</span>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col
          :span="3"
          v-for="(item, i) of dataStatisticsList"
          v-bind:key="i"
        >
          <div class="processed-list-back">
            <div class="dataStatistics-desc">{{ item.number }}</div>
            <div class="dataStatistics-title">{{ item.title }}</div>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="8">
          <div class="rank-class">
            考量排行榜 <span class="rank-update">每天更新</span>
          </div>
          <!-- <el-divider></el-divider> -->
          <div class="rankTypeback">
            <div class="rank-type">
              <div
                :class="
                  rankType === 'marketing' ? 'rankTypeActive' : 'rankTypeItem'
                "
                @click="ranktypeClicked('marketing')"
              >
                直营
              </div>
              <div
                :class="
                  rankType === 'authorization'
                    ? 'rankTypeActive'
                    : 'rankTypeItem'
                "
                @click="ranktypeClicked('authorization')"
              >
                授权
              </div>
            </div>
            <el-table :data="rankList" style="width: 100%">
              <el-table-column prop="date" label="排名" width="80">
              </el-table-column>
              <el-table-column prop="name" label="赛区" width="180">
              </el-table-column>
              <el-table-column prop="address" label="考量"> </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="rank-class">
            缴费排行榜 <span class="rank-update">每天更新</span>
          </div>
          <!-- <el-divider></el-divider> -->
          <div class="rankTypeback">
            <div class="rank-type">
              <div
                :class="
                  rankType === 'marketing' ? 'rankTypeActive' : 'rankTypeItem'
                "
                @click="ranktypeClicked('marketing')"
              >
                直营
              </div>
              <div
                :class="
                  rankType === 'authorization'
                    ? 'rankTypeActive'
                    : 'rankTypeItem'
                "
                @click="ranktypeClicked('authorization')"
              >
                授权
              </div>
            </div>
            <el-table :data="rankList" style="width: 100%">
              <el-table-column prop="date" label="排名" width="80">
              </el-table-column>
              <el-table-column prop="name" label="赛区" width="180">
              </el-table-column>
              <el-table-column prop="address" label="考量"> </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="rank-class">
            转化率排行榜 <span class="rank-update">每天更新</span>
          </div>
          <!-- <el-divider></el-divider> -->
          <div class="rankTypeback">
            <div class="rank-type">
              <div
                :class="
                  rankType === 'marketing' ? 'rankTypeActive' : 'rankTypeItem'
                "
                @click="ranktypeClicked('marketing')"
              >
                直营
              </div>
              <div
                :class="
                  rankType === 'authorization'
                    ? 'rankTypeActive'
                    : 'rankTypeItem'
                "
                @click="ranktypeClicked('authorization')"
              >
                授权
              </div>
            </div>
            <el-table :data="rankList" style="width: 100%">
              <el-table-column prop="date" label="排名" width="80">
              </el-table-column>
              <el-table-column prop="name" label="赛区" width="180">
              </el-table-column>
              <el-table-column prop="address" label="考量"> </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <!-- <el-tab-pane label="初赛">用户管理</el-tab-pane>
      <el-tab-pane label="复赛">配置管理</el-tab-pane>
      <el-tab-pane label="城市赛">角色管理</el-tab-pane>
      <el-tab-pane label="国赛有组别">定时任务补偿</el-tab-pane>
      <el-tab-pane label="国赛无组别">定时任务补偿</el-tab-pane> -->
    </el-tabs>
    <!-- 喜报制作dialog -->
    <el-dialog
      title="喜报制作"
      :visible.sync="formPoster"
      width="30%"
      @close="formPosterClose"
    >
      <el-form
        :model="uploadPosterForm"
        label-width="80px"
        :rules="uploadPosterFormRules"
        ref="uploadPosterFormRef"
      >
        <el-form-item label="赛区:" prop="districtName">
          <el-select
            placeholder="请选择"
            style="width: 80%"
            v-model="uploadPosterForm.districtName"
            clearable
          >
            <el-option
              v-for="item in dict_district"
              :key="item.id"
              :label="item.districtName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校名:" prop="schoolName">
          <el-input
            v-model="uploadPosterForm.schoolName"
            autocomplete="off"
            placeholder="请输入"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="是否分年级制作："
          prop="gradesGroup"
          label-width="140px"
        >
          <el-radio-group v-model="uploadPosterForm.gradesGroup">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="列数:" prop="columnNum">
          <el-select
            v-model="uploadPosterForm.columnNum"
            placeholder="请选择"
            style="width: 80%"
          >
            <el-option label="1" value="1"></el-option>
            <el-option label="2" value="2"></el-option>
            <el-option label="3" value="3"></el-option>
            <el-option label="4" value="4"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-upload
          class="upload-demo"
          ref="uploadPosterRef"
          :action="this.uploadPosterForm.uploadUrl"
          :data="{
            schoolName: this.uploadPosterForm.schoolName,
            columnNum: this.uploadPosterForm.columnNum,
            gradesGroup:
              this.uploadPosterForm.gradesGroup === '是' ? 'true' : 'false',
            districtName: this.uploadPosterForm.districtName,
          }"
          :before-upload="beforeUpload"
          :on-preview="uploadPosterProgress"
          :on-success="uploadPosterSuccess"
          :on-error="uploadPostereError"
          :on-exceed="uploadPosterExceed"
          :headers="uploadPosterForm.headerObj"
          :limit="1"
          :auto-upload="true"
        >
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="small"
            @click="downLoadUploadPoster"
            >下载模版</el-button
          >
          <el-button size="small" type="primary" slot="trigger"
            >上传文件</el-button
          >
          <el-button
            style="margin-left: 10px"
            type="success"
            size="small"
            @click="generateGoodnewsClicked"
            >生成喜报</el-button
          >
        </el-upload>
      </span>
    </el-dialog>
    <!-- 单张喜报生成 -->
    <div id="printId" class="html2canvasDivShow" ref="html2canvasRef">
      <div class="pic">
        <!-- <img src="@/assets/img/CEBSB_goodnews_bg_up.png" /> -->
        <div class="class_name">
          {{ lessongoodNewsSchoolName }}
        </div>
      </div>
      <div class="nameList">
        <div class="multicolumn">
          <div
            v-bind:style="player_nameclass"
            v-for="(itemList, index) in finalDataList"
            :key="index"
          >
            <div
              class="multicolumn-item"
              v-for="(item, itemIndex) in itemList"
              :key="itemIndex"
            >
              <div v-if="item.gradeName" class="gradeName_class">
                {{ item.gradeName }}
              </div>
              <div v-else class="userName_class">{{ item }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="pic2">
        <!-- <img src="@/assets/img/CEBSB_goodnews_bg_down1.png" /> -->
      </div>
      <div class="pic4">
        <!-- <img src="@/assets/img/CEBSB_goodnews_text.png" alt="" /> -->
      </div>
    </div>
    <!-- 多张海报生成 -->
    <div
      v-for="(item, index) in batchFinalDataList"
      :key="index"
      :id="`batchprintImage_${index}`"
      class="html2canvasDivShow"
      :ref="'html2canvasRef_' + index"
    >
      <div class="pic">
        <!-- <img src="@/assets/img/CEBSB_goodnews_bg_up.png" /> -->
        <div class="class_name">
          <div>{{ lessongoodNewsSchoolName }}</div>
        </div>
      </div>
      <div class="nameList">
        <!-- 多张 -->
        <div class="firstPage">{{ item.gradeName }}</div>
        <div class="multicolumn1">
          <div
            class="player-name"
            v-bind:style="player_nameclass"
            v-for="(userItem, childIndex) in item.userName"
            :key="childIndex"
          >
            {{ userItem }}
          </div>
        </div>
      </div>
      <div class="pic2">
        <!-- <img src="@/assets/img/CEBSB_goodnews_bg_down1.png" /> -->
      </div>
      <div class="pic4">
        <!-- <img src="@/assets/img/CEBSB_goodnews_text.png" alt="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { queryDistrictPage, downLoad } from '@/http/api'
import env from '@/http/env'
export default {
  data () {
    return {
      rankType: 'marketing',
      goodNewsData: null,
      // 单组年级
      lessongoodNewsSchoolName: '', // 学校
      lessongoodNewsDistrictName: '', // 赛区
      finalDataList: [],
      batchFinalDataList: [],
      lessonArr: {
        oneGradeNames: '一年级',
        twoGradeNames: '二年级',
        threeGradeNames: '三年级',
        fourGradeNames: '四年级',
        fiveGradeNames: '五年级',
        sixGradeNames: '六年级',
        sevenGradeNames: '七年级',
        eightGradeNames: '八年级',
        nineGradeNames: '九年级'
      },
      player_nameclass: {
        width: '100%',
        color: '#e8c77a'
      },
      dict_district: [],
      formPoster: false,
      uploadPosterForm: {
        uploadUrl: env.baseIP + 'goodNews/importExcel',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        },
        schoolName: '',
        columnNum: '',
        gradesGroup: '',
        districtName: ''
      },
      uploadPosterFormRules: {
        districtName: [{ required: true, message: '请选择赛区', trigger: 'change' }],
        schoolName: [{ required: true, message: '请输入学校名', trigger: 'blur' }],
        columnNum: [{ required: true, message: '请选择列数', trigger: 'change' }],
        gradesGroup: [{ required: true, message: '请选择是否分年级', trigger: 'change' }]
      },
      scheduleCardList: [
        {
          scheduleName: '初选',
          scheduleId: '1',
          promotionNum: '1000',
          unPromotion: '200',
          singUpNum: '1000',
          backGround: '#57a3f7',
          flag: this.$chnEngStatusCode.INDIVIDUAL_PRELIMINARY
        },
        {
          scheduleName: '2024展演',
          scheduleId: '2',
          promotionNum: '1000',
          unPromotion: '200',
          singUpNum: '1000'
        }
        // {
        //   scheduleName: '复选',
        //   scheduleId: '2',
        //   promotionNum: '1000',
        //   singUpNum: '1000',
        //   unPromotion: '200',
        //   backGround: '#febf3d',
        //   flag: this.$chnEngStatusCode.INDIVIDUAL_FINAL
        // }, {
        //   scheduleName: '城市总决选',
        //   scheduleId: '3',
        //   promotionNum: '1000',
        //   singUpNum: '1000',
        //   unPromotion: '200',
        //   backGround: '#52c1f5',
        //   flag: this.$chnEngStatusCode.CITY_REGIONAL_COMPETITION
        // }, {
        //   scheduleName: '国赛有组别',
        //   scheduleId: '4',
        //   promotionNum: '1000',
        //   singUpNum: '1000',
        //   unPromotion: '200',
        //   backGround: '#fb6260',
        //   flag: this.$chnEngStatusCode.NATIONAL_FINALS
        // }, {
        //   scheduleName: '国赛无组别',
        //   scheduleId: '4',
        //   promotionNum: '1000',
        //   singUpNum: '1000',
        //   unPromotion: '200',
        //   backGround: '#8167f5',
        //   flag: this.$chnEngStatusCode.NATIONAL_FINALS
        // }
      ],
      racingToolList: [
        // {
        //   leftImg: require('@/assets/img/icon-shijuanguanli.png'),
        //   rightTitle: '试卷管理',
        //   rightDesc: '可查看并审核试卷申请',
        //   menuTag: '1'
        // },
        // {
        //   leftImg: require('@/assets/img/icon-youhuiquan.png'),
        //   rightTitle: '参赛码管理',
        //   rightDesc: '生成参赛码/管理有效期',
        //   menuTag: '2'
        // },
        {
          rightTitle: '比赛时间管理',
          rightDesc: '管理赛区/学校比赛时间',
          icon: 'icon-rili',
          menuTag: '3'
        }
        // {
        //   leftImg: require('@/assets/img/icon-school.png'),
        //   rightTitle: '线下考场管理（复）',
        //   rightDesc: '新增/修改线下考场场地',
        //   menuTag: '4'
        // },
        // {
        //   leftImg: require('@/assets/img/icon-diannao.png'),
        //   rightTitle: '线上考场管理（复）',
        //   rightDesc: '新增/修改线上考场会议号',
        //   menuTag: '5'
        // },
        // {
        //   leftImg: require('@/assets/img/icon-shijuanguanli.png'),
        //   rightTitle: '生成海报',
        //   rightDesc: '可生成晋级海报',
        //   menuTag: '6'
        // }
      ],
      dataStatisticsList: [
        {
          title: '初赛缴费报名',
          number: '110'
        }, {
          title: '初赛参赛人数',
          number: '103'
        }, {
          title: '初赛晋级人数',
          number: '102'
        }, {
          title: '发放证书人数',
          number: '102'
        }, {
          title: '复赛缴费报名',
          number: '103'
        }, {
          title: '复赛参赛人数',
          number: '103'
        }, {
          title: '复赛晋级人数',
          number: '102'
        }, {
          title: '初转复转化率',
          number: '50%'
        }
      ],
      processedList: [
        {
          iconImg: require('@/assets/img/icon-school.png'),
          title: '待审核学校',
          number: '10'
        },
        {
          iconImg: require('@/assets/img/icon-shijuanguanli.png'),
          title: '待审核试卷',
          number: '10'
        },
        {
          iconImg: require('@/assets/img/icon-waitsend.png'),
          title: '待寄出试卷',
          number: '10'
        },
        {
          iconImg: require('@/assets/img/icon-waitReceive.png'),
          title: '待收回试卷',
          number: '10'
        },
        {
          iconImg: require('@/assets/img/icon-waitSite.png'),
          title: '待审核场地',
          number: '10'
        },
        {
          iconImg: require('@/assets/img/icon-waitDeliver.png'),
          title: '待发货订单',
          number: '10'
        },
        {
          iconImg: require('@/assets/img/icon-sendDeliver.png'),
          title: '已发货订单',
          number: '10'
        },
        {
          iconImg: require('@/assets/img/icon-refund.png'),
          title: '待处理退款',
          number: '10'
        }
      ]
    }
  },
  watch: {
    'uploadPosterForm.gradesGroup' () {
      this.$nextTick(() => {
        this.dataProcessing()
      })
    },
    'uploadPosterForm.columnNum' () {
      this.$nextTick(() => {
        this.dataProcessing()
      })
    },
    'uploadPosterForm.districtName' () {
      this.$nextTick(() => {
        this.dataProcessing()
      })
    },
    'uploadPosterForm.schoolName' () {
      this.$nextTick(() => {
        this.dataProcessing()
      })
    }
  },
  methods: {
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
        this.getQueryDistrictTimePage()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 赛程点击事件
    scheduleCardTap (scheduleData) {
      console.log('e', scheduleData)
      let turnUrl = ''
      switch (scheduleData.scheduleId) {
        case '1': // 初选
          turnUrl = '/raceManagement/individual_management/raceManagement_childview/individual_racing_schedules/preliminary_raceManagement'
          break
        case '2':
          turnUrl = '/raceManagement/individual_management/performanceRegister'
          break
        default:
          turnUrl = ''
          break
      }
      this.$router.push(
        {
          path: turnUrl,
          query: {
            scheduleFlag: scheduleData.flag
          }
        }
      )
    },
    // 赛务工具点击回调
    racingToolClicked (tag) {
      switch (tag) {
        case '1':
          this.$router.push('/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/individual_school_considerations')
          break
        case '2':
          this.$router.push('/product_Management/productcode_management')
          break
        case '3':
          this.$router.push('/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/individual_gametime_manage')
          break
        case '4':
          break
        case '5':
          break
        case '6':
          this.getDistrictList()
          this.formPoster = true
          this.$refs.uploadPosterRef.clearFiles()
          break
        default:
          break
      }
    },
    // 考量排行榜切换
    ranktypeClicked (rankType) {
      this.rankType = rankType
    },
    // 关闭喜报事件
    formPosterClose () {
      this.$refs.uploadPosterFormRef.resetFields()
      // this.$refs.uploadPosterRef.resetFields()
    },
    // 下载模版
    async downLoadUploadPoster () {
      downLoad().then((res) => {
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        var url = res.data
        var link = document.createElement('a')
        try {
          link.href = url
        } catch (error) {
          link.href = window.URL.createObjectURL(url)
        }
        link.click()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 上传文件前的校验
    beforeUpload () {
      this.$refs.uploadPosterFormRef.validate(async valid => {
        if (!valid) {
          this.$refs.uploadPosterRef.clearFiles()
          return false
        } else {
          return true
        }
      })
    },
    // 已上传钩子
    uploadPosterProgress () {
      this.$common.openLoading()
    },
    // 上传成功钩子
    uploadPosterSuccess (res) {
      if (res.code === 200) {
        this.goodNewsData = res.data
        this.dataProcessing()
      } else {
        this.$refs.uploadPosterRef.clearFiles()
        return this.$message.warning('文件解析失败，请重新上传！')
      }
    },
    // 数据处理
    dataProcessing () {
      if (this.goodNewsData !== null) {
        this.lessongoodNewsSchoolName = this.uploadPosterForm.schoolName// 学校
        this.lessongoodNewsDistrictName = this.uploadPosterForm.districtName.replace('赛', '选')// 赛区
        this.$data.player_nameclass.width = (100 / Number(this.uploadPosterForm.columnNum)) + '%'
        if (this.uploadPosterForm.gradesGroup === '否') {
          var obj = this.goodNewsData.goodNewsDetails
          const finalList = []
          for (const v in obj) { // 将所有数据合并到一个数组中
            finalList.push({ gradeName: this.lessonArr[v] })
            finalList.push.apply(finalList, obj[v])
          }
          const twoDimensionalArray = []
          var rowsNumber = parseInt(finalList.length / Number(this.uploadPosterForm.columnNum))
          for (let index = 0; index < Number(this.uploadPosterForm.columnNum); index++) {
            if (finalList.length % Number(this.uploadPosterForm.columnNum) !== 0) {
              if (index < (finalList.length % Number(this.uploadPosterForm.columnNum))) {
                twoDimensionalArray.push(finalList.slice(index * (rowsNumber + 1), (rowsNumber + 1) * (index + 1)))
              } else {
                twoDimensionalArray.push(finalList.slice(index * (rowsNumber + 1), (index + 1) + ((index + 1) * rowsNumber)))
              }
            } else {
              twoDimensionalArray.push(finalList.slice(index * rowsNumber, (index + 1) * rowsNumber))
            }
          }
          this.finalDataList = twoDimensionalArray
        }
      }
    },
    // 生成喜报
    generateGoodnewsClicked () {
      console.log('this.uploadPosterForm', this.uploadPosterForm)
      this.$refs.uploadPosterFormRef.validate(async valid => {
        if (!valid) return
        if (this.$refs.uploadPosterRef.uploadFiles.length === 0) {
          return this.$message.warning('请选择要上传的文件')
        }
        var obj = this.goodNewsData.goodNewsDetails
        const finalList = [{ gradeName: '', userName: [] }]
        if (this.uploadPosterForm.gradesGroup === '是') {
          for (const v in obj) {
            if (obj[v].length >= Number(this.uploadPosterForm.columnNum)) {
              finalList.push({ gradeName: this.lessonArr[v], userName: obj[v] })
            } else {
              this.$curUtils.closeLoading(this)
              return this.$message({
                message: '列数不能大于单年级人数',
                type: 'warning'
              })
            }
          }
          this.batchFinalDataList = finalList
          // this.formPoster = false
          for (let index = 0; index < finalList.length; index++) {
            await this.batchprintImage('batchprintImage_' + index, index)
          }
        } else {
          this.printImage()
          // this.formPoster = false
        }
      })
    },
    printImage () {
      html2canvas(document.querySelector('#printId'), {
        height: this.$refs.html2canvasRef.offsetHeight - 150,
        scale: 4, // 处理模糊问题
        dpi: 900 // 处理模糊问题
      }).then(canvas => {
        const printUrl = canvas.toDataURL('image/png', 1)
        // 创建隐藏的可下载链接
        const eleLink = document.createElement('a')
        eleLink.href = printUrl
        eleLink.download = 'pictureName'
        document.body.appendChild(eleLink)
        eleLink.click()
        document.body.removeChild(eleLink)
        this.$curUtils.closeLoading(this)
      })
    },
    batchprintImage (batchprintId, refIndex) {
      console.log('batchprintId', batchprintId)
      if (batchprintId !== 'batchprintImage_0') {
        this.$nextTick(() => {
          const itemRef = this.$refs['html2canvasRef_' + refIndex]
          console.log('itemRef___', itemRef)
          const box = document.getElementById(batchprintId)
          if (box) {
            html2canvas(box, {
              height: itemRef[0].offsetHeight - 150,
              scale: 4, // 处理模糊问题
              dpi: 900 // 处理模糊问题
            }).then(canvas => {
              const printUrl = canvas.toDataURL('image/png', 1)
              const eleLink = document.createElement('a')
              eleLink.href = printUrl
              eleLink.download = 'pictureName'
              document.body.appendChild(eleLink)
              eleLink.click()
              document.body.removeChild(eleLink)
            })
          }
        })
      }
    },
    // 上传失败钩子
    uploadPostereError (error) {
      this.$common.closeLoading()
      console.log('uploadPostereError', error)
      this.$message.error(error)
    },
    // 超出后动作
    uploadPosterExceed (files, fileList) {
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
      // this.$refs.uploadRef.clearFiles()// 清除文件
      // this.$refs.uploadRef.handleStart(files[0])// 选择文件后的赋值方法
    }
  }
}
</script>

<style lang="less" scoped>
.rankTypeback {
  margin: 10px 0px;
  padding: 20px;
  border: 1px solid rgb(219, 219, 219);
}
.rankTypeItem {
  width: 60px;
  height: 24px;
  border-radius: 15px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  border: 1px solid gray;
  color: gray;
  background-color: white;
  margin-right: 10px;
}
.rankTypeActive {
  width: 60px;
  height: 24px;
  font-size: 12px;
  border-radius: 15px;
  line-height: 24px;
  text-align: center;
  margin-right: 10px;
  background-color: blue;
  border: 1px solid blue;
  color: white;
}
.rank-type {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rank-update {
  font-size: 12px;
  color: rgb(107, 107, 107);
}
.rank-class {
  font-size: 14px;
  color: rgb(39, 39, 39);
  font-weight: 800;
}
.dataStatistics-desc {
  font-size: 20px;
  color: #ff0000;
}
.dataStatistics-title {
  font-size: 14px;
  color: rgb(107, 107, 107);
}
.schedule_card {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.schedule_gameName {
  text-align: left;
  color: white;
  font-size: 20px;
}

.schedule_gameNumber {
  text-align: left;
  color: white;
  font-size: 12px;
}

.processed-list-back {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.processed-image {
  width: 40px;
  height: 40px;
}

.processed-title {
  font-size: 14px;
  color: rgb(107, 107, 107);
}

.processed-desc {
  font-size: 14px;
  color: #ff0000;
}

.html2canvasDivShow {
  position: relative;
  z-index: -1;
}

.class_promotion_img {
  width: 50%;
  margin-left: 25%;
}

.player-name {
  margin-bottom: 5px;
  font-size: 18px;
  font-family: "xique";
}

.gradeName_class {
  color: white;
  font-size: 20px;
  font-family: "xique";
}

.userName_class {
  font-size: 18px;
  font-family: "xique";
}

.multicolumn1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 60%;
  margin-left: 15%;
  /* align-self: start; */
  /* justify-content: space-evenly; */
  height: 93%;
}

.multicolumn-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 30px;
}

.multicolumn {
  display: flex;
  flex-direction: row;
  width: 60%;
  margin-left: 15%;
  /* flex-wrap: wrap; */
  /* align-self: start; */
  justify-content: center;
  /* justify-content: space-evenly; */
  height: 93%;
}

.firstPage {
  width: 60%;
  margin-left: 15%;
  color: #fff;
  font-size: 20px;
  font-family: "xique";
  margin-bottom: 20px;
  /* margin: 20px 0; */
}

.nameList {
  width: 100%;
  min-height: 279px;
  position: relative;
  margin-top: -120px;
  text-align: center;
  color: #e8c77a;
  // background: url(../../../../../assets/img/CEBSB_goodnews_bg_middle2.png);
  background-size: 100%;
  z-index: 100;
}

img {
  width: 100%;
}

.class_name {
  position: absolute;
  text-align: center;
  font-family: "xique";
  width: 60%;
  margin-left: 15%;
  color: #ffffff;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 30%;
}

.class_name img {
  height: 100%;
  width: 15%;
}

.class_name div {
  /* margin: 10px; */
  position: relative;
}

.class_school {
  color: #e8c77a;
  text-align: center;
  width: 100%;
  font-size: 26px;
  position: relative;
  margin-bottom: 10px;
}

.pic {
  position: relative;
  width: 100%;
  top: 0;
}

.pic3 {
  width: 100%;
}
.pic4 {
  position: absolute;
  right: 20px;
  top: 200px;
  width: 150px;
  height: 507px;
  z-index: 101;
}
.pic4 img {
  width: 100%;
  height: 100%;
}
.pic2 {
  width: 100%;
  position: relative;
  top: -150px;
  z-index: 99;
  background-color: #3f0000;
  /* background-color: #3f0000; */
}

.pic2 img {
  width: 100%;
}

#batchprintId {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}

#printId {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}

#batchprintImage_0 {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}

#batchprintImage_1 {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}

#batchprintImage_2 {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}

#batchprintImage_3 {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}

#batchprintImage_4 {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}

#batchprintImage_5 {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}

#batchprintImage_6 {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}

#batchprintImage_7 {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}

#batchprintImage_8 {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}

#batchprintImage_9 {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}

#batchprintImage_10 {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}

#batchprintImage_11 {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}

#batchprintImage_12 {
  position: relative;
  width: 560px;
  min-height: 760px;
  letter-spacing: 1px;
  background-color: #3f0000;
}
</style>
