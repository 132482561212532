<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>个人赛考务管理</el-breadcrumb-item>
      <el-breadcrumb-item>复选成绩查询</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 信息筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="100px"
        ref="queryFormRef"
      >
        <el-row :gutter="1">
          <el-col :span="6">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择赛季"
                style="width: 100%"
                @change="seasonHandleChange"
                v-model="queryForm.season"
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="所属赛区" prop="divisionId">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.divisionId"
                clearable
              >
                <el-option
                  v-for="item in dict_district"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="6">
            <el-form-item label="赛程" prop="raceSchedule">
              <el-select
                placeholder="请选择"
                @visible-change="changeRaceScheduleValue"
                style="width: 100%"
                v-model="queryForm.raceSchedule"
                clearable
              >
                <el-option
                  v-for="item in raceScheduleList"
                  :key="item.id"
                  :label="item.scheduleName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item label="学校省市区" prop="schoolArea">
              <el-cascader
                placeholder="请选择"
                clearable
                v-model="queryForm.schoolArea"
                ref="schoolAreaRef"
                :options="areasData"
                style="width: 100%"
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'id',
                  children: 'areas',
                }"
                @change="provincesHandleChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="学校" prop="schoolId">
              <el-select
                ref="schoolSelectRef"
                style="width: 100%"
                placeholder="请选择"
                v-model="queryForm.schoolId"
                clearable
                filterable
              >
                <el-option
                  v-for="item in provincesSchoolList"
                  :key="item.id"
                  :label="item.schoolName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="参赛方式" prop="participation">
              <el-select
                placeholder="请选择"
                v-model="queryForm.participation"
                style="width: 100%"
              >
                <el-option
                  v-for="item in participation_way"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row :gutter="0">
          <el-col :span="6">
            <el-form-item label="学生姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="组别" prop="enrollmentLevel">
              <el-select
                placeholder="请选择"
                v-model="queryForm.enrollmentLevel"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_Group"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否晋级" prop="promotion">
              <el-select
                placeholder="请选择"
                v-model="queryForm.promotion"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in isPromotion"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="scoreTypeActiveName === 'off-line'">
            <el-form-item label="成绩查询码" prop="scoreCode">
              <el-input
                v-model="queryForm.scoreCode"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0"> </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getDataList(scoreTypeActiveName, 'search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 信息列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="title_class">成绩信息列表</span>
        </el-col>
        <el-col :span="15">
          <div style="text-align: right">
            <el-button
              type="primary"
              @click="importScoreDialogVisible = true"
              v-if="scoreTypeActiveName === 'off-line'"
              >批量导入成绩</el-button
            >
            <el-button
              type="primary"
              v-if="scoreTypeActiveName === 'off-line'"
              @click="scoreQueryCodeDialogVisible = true"
              >批量生成成绩查询码</el-button
            >
            <el-button type="primary" @click="setPassDialogVisible = true"
              >批量设置晋级</el-button
            >
            <el-button
              type="primary"
              @click="downLoadScoreSearchCode"
              v-if="scoreTypeActiveName === 'off-line'"
              >导出成绩查询码</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-tabs
        type="border-card"
        v-model="scoreTypeActiveName"
        @tab-click="scoreTypeChengeClick"
      >
        <el-tab-pane label="线上成绩" name="on-line">
          <el-table
            header-cell-class-name="tableHeaderStyle"
            :data="dataList"
            border
            stripe
          >
            <el-table-column label="#" type="index"></el-table-column>
            <el-table-column label="姓名" prop="userName"></el-table-column>
            <el-table-column label="手机号" prop="phone"></el-table-column>
            <el-table-column label="学校" prop="schoolName"></el-table-column>
            <el-table-column
              label="组别"
              prop="enrollmentLevel"
              :formatter="groupFormatter"
            ></el-table-column>
            <el-table-column
              label="年级"
              prop="grade"
              :formatter="gradeFormatter"
            ></el-table-column>
            <el-table-column label="复赛成绩" prop="score"></el-table-column>
            <el-table-column label="答题次数" prop="answerNum"></el-table-column>
            <el-table-column label="晋级状态" prop="expectedTime">
              <template slot-scope="scope">
                <el-tag
                  type="danger"
                  v-if="scope.row.promotion === 2"
                  size="mini"
                  >未晋级</el-tag
                >
                <el-tag
                  type="success"
                  v-else-if="scope.row.promotion === 1"
                  size="mini"
                  >晋级</el-tag
                >
                <el-tag
                  type="warning"
                  v-else-if="!scope.row.promotion || scope.row.promotion === 0"
                  size="mini"
                  >未设置</el-tag
                >
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120px">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  icon="el-icon-edit"
                  size="small"
                  @click="showEditDialog(scope.row)"
                  >晋级修改</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="线下成绩" name="off-line">
          <el-table
            header-cell-class-name="tableHeaderStyle"
            :data="dataList"
            border
            stripe
          >
            <el-table-column label="#" type="index"></el-table-column>
            <el-table-column label="省市区" prop="provence">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.province }}-{{ scope.row.city }}-{{ scope.row.area }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              label="学校"
              prop="schoolName"
            ></el-table-column>
            <el-table-column label="姓名" prop="scoreName"></el-table-column>
            <el-table-column label="性别" prop="scoreSex" width="50px"></el-table-column>
            <el-table-column
              label="手机号"
              prop="scorePhoneOne"
            ></el-table-column>
            <el-table-column
              label="组别"
              :formatter="gradeGroupFormatter"
            ></el-table-column>
            <el-table-column
              label="年级"
              prop="scoreGrade"
              :formatter="gradeFormatter"
            ></el-table-column>
            <el-table-column label="班级" prop="scoreClass"></el-table-column>
            <el-table-column label="复赛成绩" prop="scores"></el-table-column>
            <el-table-column label="成绩查询码" prop="scoreCode"></el-table-column>
            <el-table-column label="晋级状态" prop="scorePromotion">
              <template slot-scope="scope">
                <el-tag
                  type="danger"
                  v-if="scope.row.scorePromotion === 2"
                  size="mini"
                  >未晋级</el-tag
                >
                <el-tag
                  type="success"
                  v-else-if="scope.row.scorePromotion === 1"
                  size="mini"
                  >晋级</el-tag
                >
                <el-tag
                  type="warning"
                  v-else-if="
                    !scope.row.scorePromotion || scope.row.scorePromotion === 0
                  "
                  size="mini"
                  >未设置</el-tag
                >
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180px">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  icon="el-icon-edit"
                  size="small"
                  @click="showEditOffLineDialog(scope.row)"
                  >晋级修改</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="generateScoreSearchCode(scope.row)"
                  >生成查询码</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 修改线上成绩的dialog -->
    <el-dialog
      title="修改成绩信息"
      :visible.sync="editScoreDialogVisible"
      @close="editScoreDialogClose"
      width="30%"
    >
      <el-form
        :model="editScoreForm"
        label-width="80px"
        ref="editScoreFormRef"
        :rules="editScoreFormRules"
      >
        <el-form-item label="姓名" prop="userName">
          <el-input
            v-model="editScoreForm.userName"
            placeholder="请输入"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="晋级资格" prop="promotion">
          <el-radio-group v-model="editScoreForm.promotion">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editScoreDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editScore">修 改</el-button>
      </span>
    </el-dialog>
    <!-- 修改线下成绩的dialog -->
    <el-dialog
      title="修改成绩信息"
      :visible.sync="editofflineScoreDialogVisible"
      @close="editoffLineScoreDialogClose"
      width="30%"
    >
      <el-form
        :model="editOffScoreForm"
        label-width="80px"
        ref="editOffScoreFormRef"
        :rules="editScoreFormRules"
      >
        <el-form-item label="姓名" prop="scoreName">
          <el-input
            v-model="editOffScoreForm.scoreName"
            placeholder="请输入"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="晋级资格" prop="scorePromotion">
          <el-radio-group v-model="editOffScoreForm.scorePromotion">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editofflineScoreDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="editOffScore">修 改</el-button>
      </span>
    </el-dialog>
    <!-- 批量导入线下成绩 -->
    <el-dialog
      title="批量导入线下成绩"
      :visible.sync="importScoreDialogVisible"
      @close="importScoreDialogClose"
      width="30%"
    >
      <el-form
        :model="queryForm"
        label-width="100px"
        ref="editInfoFormRef"
        :rules="importScoreInfoRules"
        style="width: 100%"
      >
        <el-form-item label="学校省市区" prop="schoolArea">
          <el-cascader
            placeholder="请选择"
            v-model="queryForm.schoolArea"
            :options="areasData"
            style="width: 100%"
            :props="{
              expandTrigger: 'hover',
              label: 'name',
              value: 'id',
              children: 'areas',
            }"
            @change="provincesHandleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="学校名称" prop="schoolId">
          <el-select
            ref="schoolSelectRef"
            placeholder="请选择"
            style="width: 100%"
            v-model="queryForm.schoolId"
            @change="selectSchoolChange"
          >
            <el-option
              v-for="item in provincesSchoolList"
              :key="item.id"
              :label="item.schoolName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
      <el-upload
        :action="scoreImportInfo.uploadUrl"
        ref="scoreImportInfoRef"
        :data="{
          season: this.queryForm.season,
          activityType: this.$chnEngStatusCode.activityType,
          raceSchedule: this.queryForm.raceSchedule,
          schoolId: this.queryForm.schoolId,
          schoolName: this.queryForm.schoolName
        }"
        :headers="scoreImportInfo.headerObj"
        :on-progress="scoreImportInfoProgress"
        :on-success="scoreImportInfoSuccess"
        :on-error="scoreImportInfoError"
        :on-exceed="scoreImportInfoExceed"
        :limit="1"
        :auto-upload="false"
        :on-change="onChangeLoad"
      >
        <el-button slot="trigger" size="small" type="primary"
          >选取文件</el-button
        >
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="downLoadscoreImportInfoStencil"
          >下载模版</el-button
        >
        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="submitscoreImportInfo"
          >新增报名信息</el-button
        >
        <el-button
          style="margin-left: 10px"
          size="small"
          type="danger"
          @click="coverscoreImportInfo"
          >覆盖报名信息</el-button
        >
      </el-upload>
      </span>
    </el-dialog>
    <!-- 批量设置晋级dialog -->
    <el-dialog
      title="批量设置晋级"
      :visible.sync="setPassDialogVisible"
      width="30%"
      @close="setPassDialogClose"
    >
      <el-form
        ref="setPassFormRef"
        :model="setPassForm"
        :rules="setPassFormRules"
        label-width="120px"
      >
        <el-form-item label="设置范围" prop="setRange">
          <el-select
            placeholder="请选择"
            style="width: 100%"
            v-model="setPassForm.setRange"
          >
            <el-option
              v-for="item in dict_setPassRange"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="赛区名称"
          prop="zoneId"
          v-if="setPassForm.setRange === 0"
        >
          <el-select
            placeholder="请选择"
            style="width: 100%"
            v-model="setPassForm.zoneId"
          >
            <el-option
              v-for="item in dict_district"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="学校地区"
          prop="schoolArea"
          v-if="setPassForm.setRange === 1"
        >
          <el-cascader
            placeholder="请选择"
            style="width: 100%"
            v-model="setPassForm.schoolArea"
            :options="areasData"
            :props="{
              expandTrigger: 'hover',
              label: 'name',
              value: 'id',
              children: 'areas',
            }"
            @change="provincesHandleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="学校名称"
          prop="schoolId"
          v-if="setPassForm.setRange === 1"
        >
          <el-select
            placeholder="请选择"
            style="width: 100%"
            v-model="setPassForm.schoolId"
          >
            <el-option
              v-for="item in provincesSchoolList"
              :key="item.id"
              :label="item.schoolName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="年级" prop="grade" v-if="setPassForm.setRange === 1">
          <el-select
            placeholder="请选择（默认全部）"
            style="width: 100%"
            v-model="queryForm.grade"
          >
            <el-option
              v-for="item in dict_grade"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="班级"
          prop="classroom"
          v-if="setPassForm.setRange === 1"
        >
          <el-select
            placeholder="请选择（默认全部）"
            style="width: 100%"
            v-model="queryForm.classroom"
          >
            <el-option
              v-for="item in dict_classroom"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="晋级方式" prop="wayOfPromotion">
          <el-select
            placeholder="请选择"
            style="width: 100%"
            v-model="setPassForm.wayOfPromotion"
          >
            <el-option
              v-for="item in dict_setPassWay"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设置值" prop="value">
          <el-input
            v-model="setPassForm.value"
            placeholder="请输入"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="参赛人数" prop="numberCompetition">
          <el-input
            v-model="setPassForm.numberCompetition"
            placeholder="请点击查看晋级预期"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="预期晋级人数" prop="expectNumber">
          <el-input
            v-model="setPassForm.expectNumber"
            placeholder="请点击查看晋级预期"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="预期晋级分数线" prop="expectLine" v-if="setPassForm.wayOfPromotion === 1">
          <el-input
            v-model="setPassForm.expectLine"
            placeholder="请点击查看晋级预期"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="预期晋级比例" prop="promotionRatio">
          <el-input
            v-model="setPassForm.promotionRatio"
            placeholder="请点击查看晋级预期"
            disabled
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setPassDialogVisible = false">取 消</el-button>
        <el-button type="warning" @click="queryPromotionExpectations"
          >查看晋级预期</el-button
        >
        <el-button type="primary" @click="setPass()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择生成code方式的dialog -->
    <el-dialog
      title="选择生成方式"
      :visible.sync="scoreQueryCodeDialogVisible"
      @close="scoreQueryCodeDialogClose"
      width="35%"
    >
      <el-form
        :model="scoreQueryForm"
        :rules="scoreQueryFormRules"
        class="filter_Form"
        label-width="100px"
        ref="scoreQueryFormRef"
      >
        <el-form-item label="赛季" prop="season">
          <el-select
            placeholder="请选择赛季"
            style="width: 100%"
            v-model="scoreQueryForm.season"
          >
            <el-option
              v-for="item in dict_season"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校省市区" prop="schoolArea">
          <el-cascader
            placeholder="请选择"
            v-model="scoreQueryForm.schoolArea"
            ref="schoolAreaRef"
            :options="areasData"
            style="width: 100%"
            :props="{
              expandTrigger: 'hover',
              label: 'name',
              value: 'id',
              children: 'areas',
            }"
            @change="provincesHandleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="学校" prop="schoolId">
          <el-select
            style="width: 100%"
            placeholder="请选择"
            v-model="scoreQueryForm.schoolId"
            clearable
            filterable
          >
            <el-option
              v-for="item in provincesSchoolList"
              :key="item.id"
              :label="item.schoolName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scoreQueryCodeDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="generateScoreQueryCode"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import env from '@/http/env'
import { oneGenerateScoreCode, queryAnswerResultsPage, queryModelPaperResultScore, queryModelInitialReplyScore, queryModelExpectInitialReplyScore, getSchoolListWithCity, setTbEnrollmentPromotion, updateInitialReplyScore, querySeasonRaceScheduleFlag, queryPaperResultScore, queryModelPaperExpectResultScore, oneKeyGenerateScoreCode } from '@/http/api'
import areasData from '@/assets/area.json'
export default {
  data () {
    return {
      scoreQueryCodeDialogVisible: false,
      singleScoreQueryCodeDialogVisible: false,
      batchScoreQueryCodeDialogVisible: false,
      setPassDialogVisible: false,
      scoreQueryForm: {
        raceSchedule: '2',
        schoolId: '',
        season: ''
      },
      setPassForm: {
        raceScheduleId: '2',
        season: this.$chnEngStatusCode.defaultSeason,
        setRange: null,
        value: '',
        wayOfPromotion: null,
        schoolId: '',
        zoneId: '',
        expectLine: '',
        promotionRatio: '',
        expectNumber: '',
        numberCompetition: ''
      },
      dict_setPassRange: [
        {
          dictCode: 0,
          dictLabel: '赛区',
          dictValue: 0
        },
        {
          dictCode: 1,
          dictLabel: '学校',
          dictValue: 1
        }
      ],
      dict_setPassWay: [
        {
          dictCode: 0,
          dictLabel: '人数',
          dictValue: 0
        },
        {
          dictCode: 2,
          dictLabel: '分数线',
          dictValue: 1
        }
      ],
      setPassFormRules: {
        wayOfPromotion: [{ required: true, message: '请选择', trigger: 'change' }],
        setRange: [{ required: true, message: '请选择', trigger: 'change' }],
        value: [{ required: true, message: '请输入', trigger: 'blur' }],
        zoneId: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolArea: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      scoreQueryFormRules: {
        season: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolArea: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      importScoreInfoRules: {
        schoolArea: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      editofflineScoreDialogVisible: false,
      isUploadFile: false, // 是否已选择上传文件
      importScoreDialogVisible: false,
      scoreImportInfo: {
        uploadUrl: env.baseIP + 'Initial/Reply/importPreliminaryCompetitionExcel',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      scoreTypeActiveName: 'on-line',
      dataList: [],
      provincesSchoolList: [],
      p_open_type: this.$userInfo.getDataList(this, 'p_open_type').then(value => { this.p_open_type = value }),
      dict_district: this.$userInfo.getDataList(this, 't_district').then(value => { this.dict_district = value }),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dict_Group: this.$userInfo.dict_group(),
      areasData: areasData.areas,
      participation_way: this.$userInfo.participation_way(),
      isPromotion: this.$userInfo.isPromotion(),
      dict_grade: this.$userInfo.getGradeData(),
      total: 0,
      editScoreDialogVisible: false,
      editScoreFormRules: {
        promotion: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      editScoreForm: {
        activityType: this.$chnEngStatusCode.activityType,
        raceSchedule: null,
        season: this.$chnEngStatusCode.defaultSeason,
        userId: '',
        promotion: null
      },
      editOffScoreForm: {
      },
      queryForm: {
        activityType: this.$chnEngStatusCode.activityType,
        raceSchedule: this.$route.query.scheduleFlag,
        promotion: '',
        phone: '',
        name: '',
        season: this.$chnEngStatusCode.defaultSeason,
        divisionId: '',
        schoolId: '',
        pageNum: 1,
        pageSize: 10
      },
      raceScheduleList: []
    }
  },
  created () {
    this.seasonHandleChange()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 重置搜索条件
    reset () {
      this.queryForm.pageNum = 1
      this.$refs.queryFormRef.resetFields()
      this.getDataList(this.scoreTypeActiveName, '')
    },
    // 赛季选择事件回调
    seasonHandleChange (value) {
      var seasonStr = ''
      if (!value) {
        seasonStr = this.queryForm.season
      } else {
        seasonStr = value
      }
      var formData = {
        activityType: this.$chnEngStatusCode.activityType,
        season: seasonStr,
        scheduleFlag: this.$route.query.scheduleFlag
      }
      this.searchScheduleWithFlag(formData)
    },
    // 根据赛季标识符查询赛程相关
    searchScheduleWithFlag (formData) {
      querySeasonRaceScheduleFlag(formData).then((res) => {
        this.queryForm.raceSchedule = res.data.id
        this.getDataList(this.scoreTypeActiveName, '')
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 赛程选择
    changeRaceScheduleValue (state) {
      console.log(state)
      if (state === true && this.queryForm.season === '') {
        this.$message.error('请先选择赛季')
      }
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      console.log('value', value)
      if (!value) return
      this.provincesSchoolList = []
      this.queryForm.schoolId = ''
      this.scoreQueryForm.schoolId = ''
      this.getSchoolListData(value)
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        console.log('getSchoolList', res)
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList(this.scoreTypeActiveName, '')
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList(this.scoreTypeActiveName, '')
    },
    // 线上、线下成绩切换
    scoreTypeChengeClick (tab, event) {
      console.log('scoreTypeActiveName', this.scoreTypeActiveName)
      this.getDataList(this.scoreTypeActiveName, 'search')
    },
    // 获取列表信息
    getDataList (scoreType, getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      if (scoreType === 'on-line') {
        queryAnswerResultsPage(this.queryForm).then((res) => {
          console.log('queryAnswerResultsPage', res)
          this.dataList = res.data.list
          this.total = res.data.total
        }).catch((err) => {
          console.log('err', err)
        })
      } else if (scoreType === 'off-line') {
        this.queryForm.scoreName = this.queryForm.userName
        queryPaperResultScore(this.queryForm).then((res) => {
          console.log('queryPaperResultScore', res)
          this.dataList = res.data.list
          this.total = res.data.total
        }).catch((err) => {
          console.log('err', err)
        })
      }
    },
    // 显示修改晋级弹窗
    showEditDialog (row) {
      console.log('row', row)
      this.editScoreForm.userName = row.userName ? row.userName : row.scoreName
      this.editScoreForm.raceSchedule = row.raceSchedule
      this.editScoreForm.userId = row.userId ? row.userId : row.id
      this.editScoreDialogVisible = true
    },
    // 单个修改线上成绩
    editScore () {
      this.savePromotionInfoData(this.editScoreForm)
    },
    // 修改成绩弹窗关闭
    editScoreDialogClose () {
      this.$refs.editScoreFormRef.resetFields()
    },
    // 显示修改线上成绩弹窗
    showEditOffLineDialog (data) {
      this.editOffScoreForm = JSON.parse(JSON.stringify(data))
      this.editofflineScoreDialogVisible = true
    },
    // 提交修改线下成绩
    editOffScore () {
      this.saveOfflinePromotionInfoData(this.editOffScoreForm)
    },
    // 修改线下成绩弹窗关闭
    editoffLineScoreDialogClose () {
      this.$refs.editOffScoreFormRef.resetFields()
    },
    // 线下导入成绩学校选择
    selectSchoolChange (e) {
      console.log('e__', e)
      this.$nextTick(function () {
        this.queryForm.schoolName = this.$refs.schoolSelectRef.selectedLabel
      })
    },
    // 提交线下成绩
    async saveOfflinePromotionInfoData (formData) {
      updateInitialReplyScore(formData).then((res) => {
        this.editofflineScoreDialogVisible = false
        this.$message.success('修改成功')
        this.getDataList(this.scoreTypeActiveName, 'search')
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 提交线上成绩
    async savePromotionInfoData (formData) {
      setTbEnrollmentPromotion(formData).then((res) => {
        this.editScoreDialogVisible = false
        this.$message.success('修改成功')
        this.getDataList(this.scoreTypeActiveName, 'search')
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 上传成绩加载
    scoreImportInfoProgress () {
      this.$curUtils.openLoading(this)
    },
    // 批量上传成功
    scoreImportInfoSuccess (res) {
      this.$curUtils.closeLoading(this)
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      this.isUploadFile = false
      if (res.code !== 200) {
        return this.$alert('请修改后再上传，失败原因:' + '<br/>' + res.msg, '上传失败', {
          confirmButtonText: '确定',
          type: 'warning',
          dangerouslyUseHTMLString: true
        })
      }
      this.$message.success('批量上传报名信息成功')
      this.getDataList(this.scoreTypeActiveName, '')
      this.importScoreDialogVisible = false
    },
    scoreImportInfoError (error, file, fileList) {
      console.log('batchImportInfoError', error, file, fileList)
      this.$curUtils.closeLoading()
      this.$message.error(error)
    },
    scoreImportInfoExceed (files, fileList) {
      console.log('fileList', files, fileList)
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      this.$refs.scoreImportInfoRef.handleStart(files[0])// 选择文件后的赋值方法
    },
    // 新增成绩
    submitscoreImportInfo () {
      console.log('selectedLabel___________', this.$refs.schoolSelectRef.selectedLabel)
      this.scoreImportInfo.uploadUrl = env.baseIP + 'Initial/Reply/importPreliminaryCompetitionExcel'
      this.$nextTick(function () {
        this.queryForm.schoolName = this.$refs.schoolSelectRef.selectedLabel
        this.$refs.editInfoFormRef.validate(async valid => {
          if (!valid) return
          console.log('this.queryForm', this.queryForm)
          if (this.isUploadFile === true) {
            this.$refs.scoreImportInfoRef.submit()
          } else {
            this.$message.error('请先选择要上传的文件')
          }
        })
      })
    },
    // 覆盖成绩
    coverscoreImportInfo () {
      this.scoreImportInfo.uploadUrl = env.baseIP + 'Initial/Reply/updatePreliminaryCompetitionExcel'
      this.queryForm.schoolName = this.$refs.schoolSelectRef.selectedLabel
      this.$refs.editInfoFormRef.validate(async valid => {
        if (!valid) return
        console.log('this.queryForm', this.queryForm)
        if (this.isUploadFile === true) {
          this.$confirm('是否继续覆盖该条件下的所有成绩?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            this.$refs.scoreImportInfoRef.submit()
          }).catch(() => {})
        } else {
          this.$message.error('请先选择要上传的文件')
        }
      })
    },
    // 监听上传文件弹窗关闭事件
    importScoreDialogClose () {
      // this.$refs.editInfoFormRef.clearFiles()
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      this.isUploadFile = false
    },
    // 下载模板
    async downLoadscoreImportInfoStencil () {
      var downLoadUrl = env.baseIP + 'Initial/Reply/importPreliminaryCompetitionTemplate'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, {})
    },
    // 选择文件修改状态
    onChangeLoad (files, fileList) {
      if (fileList.length > 0) {
        this.isUploadFile = true
      }
    },
    // 查看晋级预期
    queryPromotionExpectations () {
      console.log('aaaaaa')
      this.$refs.setPassFormRef.validate(async valid => {
        console.log('valid', valid)
        if (!valid) return
        var formData = {
          raceScheduleId: '1',
          season: this.setPassForm.season,
          setRange: this.setPassForm.setRange,
          value: this.setPassForm.value,
          wayOfPromotion: this.setPassForm.wayOfPromotion,
          schoolId: this.setPassForm.schoolId,
          zoneId: this.setPassForm.zoneId
        }
        if (this.scoreTypeActiveName === 'on-line') { // 查看线上晋级预期
          queryModelPaperExpectResultScore(formData).then((res) => {
            this.setPassForm.numberCompetition = res.data.numberCompetition
            this.setPassForm.expectNumber = res.data.expectNumber
            this.setPassForm.expectLine = res.data.expectLine
            this.setPassForm.promotionRatio = res.data.promotionRatio
          }).catch((err) => {
            console.log('返回的err', err)
          })
        } else { // 查看线下晋级预期
          queryModelExpectInitialReplyScore(formData).then((res) => {
            this.setPassForm.numberCompetition = res.data.numberCompetition
            this.setPassForm.expectNumber = res.data.expectNumber
            this.setPassForm.expectLine = res.data.expectLine
            this.setPassForm.promotionRatio = res.data.promotionRatio
          }).catch((err) => {
            console.log('返回的err', err)
          })
        }
      })
    },
    setPass () {
      this.$confirm('晋级结果将以晋级预期情况来设置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.$refs.setPassFormRef.validate(async valid => {
          if (!valid) return
          if (this.scoreTypeActiveName === 'on-line') { // 设置线上晋级
            queryModelPaperResultScore(this.setPassForm).then((res) => {
              console.log('queryModelPaperResultScore', res)
              this.setPassDialogVisible = false
              this.$message.success('设置成功')
              this.getDataList(this.scoreTypeActiveName, '')
            }).catch((err) => {
              console.log('err', err)
            })
          } else { // 设置线下晋级
            queryModelInitialReplyScore(this.setPassForm).then((res) => {
              console.log('queryModelPaperResultScore', res)
              this.setPassDialogVisible = false
              this.$message.success('设置成功')
              this.getDataList(this.scoreTypeActiveName, '')
            }).catch((err) => {
              console.log('err', err)
            })
          }
        })
      }).catch(() => {})
    },
    // 批量设置晋级弹窗关闭
    setPassDialogClose () {
      this.setPassSchoolList = []
      this.$refs.setPassFormRef.resetFields()
    },
    // 生成成绩查询码
    generateScoreQueryCode () {
      this.$refs.scoreQueryFormRef.validate(async valid => {
        if (!valid) return
        oneKeyGenerateScoreCode(this.scoreQueryForm).then((res) => {
          this.scoreQueryCodeDialogVisible = false
          this.$message.success('生成成功')
          this.getDataList(this.scoreTypeActiveName, '')
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // 成绩查询码弹窗关闭
    scoreQueryCodeDialogClose () {
      this.$refs.scoreQueryFormRef.resetFields()
    },
    // 单个生成成绩查询码
    generateScoreSearchCode (rowData) {
      this.$confirm('是否确认生成该考生成绩查询码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        oneGenerateScoreCode({ id: rowData.id }).then((res) => {
          console.log('oneGenerateScoreCode', res)
          this.$message.success('生成成功')
          this.getDataList(this.scoreTypeActiveName, '')
        }).catch((err) => {
          console.log('err', err)
        })
      }).catch(() => {})
    },
    // 导出成绩查询码
    downLoadScoreSearchCode () {
      var downLoadUrl = env.baseIP + 'Initial/Reply/excelPaperResultScoreCode'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, this.queryForm)
    },
    gradeFormatter (data) {
      var temp = '未知'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (data.scoreGrade) {
            if (element.dictValue === data.scoreGrade + '') {
              temp = element.dictLabel
            }
          }
          if (data.grade) {
            if (element.dictValue === data.grade + '') {
              temp = element.dictLabel
            }
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      var temp = '未知'
      for (const key in this.dict_Group) {
        if (Object.hasOwnProperty.call(this.dict_Group, key)) {
          const element = this.dict_Group[key]
          if (element.dictValue === data.enrollmentLevel + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    gradeGroupFormatter (data) {
      var gradeData = this.$userInfo.getUserGroupWithGrade(data.scoreGrade)
      return gradeData.userGradeStr
    },
    participationFormatter (data) {
      var temp = '未知'
      for (const key in this.participation_way) {
        if (Object.hasOwnProperty.call(this.participation_way, key)) {
          const element = this.participation_way[key]
          if (element.dictValue === data.grade + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style lang="less" scoped>
.title_class {
  display: -moz-inline-box;
  display: inline-block;
  width: 120px;
}
.score-querytype-class {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
