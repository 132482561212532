<template>
  <div>团体赛赛务首页</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
