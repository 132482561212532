<template>
  <div>
    <el-form
      :model="editInfoForm"
      label-width="100px"
      ref="editInfoFormRef"
      :rules="editInfoRules"
      style="width: 100%"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="学校省市区" prop="schoolArea">
            <el-cascader
              placeholder="请选择"
              v-model="editInfoForm.schoolArea"
              ref="schoolAreaRef"
              :options="areasData"
              style="width: 100%"
              :props="{
                expandTrigger: 'hover',
                label: 'name',
                value: 'id',
                children: 'areas',
              }"
              @change="provincesHandleChange()"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="学校名称" prop="schoolName">
            <el-input
              v-model="editInfoForm.schoolName"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="学校类型" prop="schoolType">
            <el-select
              placeholder="请选择"
              v-model="editInfoForm.schoolType"
              style="width: 100%"
            >
              <el-option
                v-for="item in dict_schoolType"
                :key="item.dictCode"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="学校地址" prop="schoolAddress">
            <el-input
              v-model="editInfoForm.schoolAddress"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="国家" prop="nationality">
            <el-select
              placeholder="请选择"
              v-model="editInfoForm.nationality"
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="item in dict_countries"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="editInfoForm.remark"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row type="flex" justify="center" style="margin-top: 20px">
      <el-col :span="4">
        <el-button type="primary" style="width: 100%" @click="saveClicked"
          >保存</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
import areasData from '@/assets/area.json'
import { addSysSchool } from '@/http/api'
export default {
  data () {
    return {
      dict_schoolType: this.$userInfo.dict_schoolType(),
      dict_countries: this.$userInfo.dict_countries(),
      areasData: areasData.areas,
      editInfoForm: JSON.parse(this.$route.query.editInfoForm),
      editInfoRules: {
        schoolArea: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolName: [{ required: true, message: '请输入', trigger: 'blur' }],
        schoolType: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolAddress: [{ required: true, message: '请输入', trigger: 'blur' }],
        nationality: [{ required: true, message: '请选择', trigger: 'change' }],
        remark: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
    }
  },
  created () {
    console.log('editInfoForm', JSON.parse(this.$route.query.editInfoForm))
  },
  methods: {
    saveClicked () {
      console.log('editInfoForm', this.editInfoForm)
      this.$refs.editInfoFormRef.validate(async valid => {
        if (!valid) return
        addSysSchool(this.editInfoForm).then((res) => {
          this.$message.success('操作成功')
          this.goBack()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      console.log('value', value)
      this.editInfoForm.provinceCode = this.editInfoForm.schoolArea[0]
      this.editInfoForm.cityCode = this.editInfoForm.schoolArea[1]
      this.editInfoForm.areaCode = this.editInfoForm.schoolArea[2]
      const deliveryAreaName = this.$refs.schoolAreaRef.getCheckedNodes()[0].pathLabels.join('/')
      const areaList = deliveryAreaName.split('/')
      this.editInfoForm.province = areaList[0]
      this.editInfoForm.city = areaList[1]
      this.editInfoForm.area = areaList[2]
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style>
</style>
