<template>
  <el-container class="home-container">
    <el-header>
      <div>
        <img src="../../assets/img/newerp_icon3.png" alt="" />
        <span>环球文遗ERP管理系统</span>
      </div>
      <div>
        <el-button type="text" @click="workTable">工作台</el-button>
        <el-dropdown @command="handleCommand">
          <span class="username" style="color: white"
            >系统管理员
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="changeRole">切换身份</el-dropdown-item> -->
            <el-dropdown-item command="logOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <el-aside :width="menuCollapse ? '64px' : '200px'">
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <el-menu
          ref="menuRef"
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409Bff"
          unique-opened
          :collapse="menuCollapse"
          :collapse-transition="false"
          router
          @open="menuOpen"
        >
          <!-- 一级菜单 -->
          <template v-for="item in subMenuList">
            <el-submenu
              v-if="item.childMenuList && item.childMenuList.length"
              :index="item.menu_id + ''"
              :key="item.menu_id"
            >
              <template slot="title"
                ><i :class="iconsObj[item.menu_id]"></i
                ><span>{{ item.menu_name }}</span></template
              >
              <!-- 二级菜单 -->
              <template v-for="itemChild in item.childMenuList">
                <el-submenu
                  v-if="
                    itemChild.childMenuList && itemChild.childMenuList.length
                  "
                  :index="itemChild.menu_id + ''"
                  :key="itemChild.menu_id"
                >
                  <template slot="title"
                    ><span>{{ itemChild.menu_name }}</span></template
                  >

                  <!-- 三级菜单 -->
                  <el-menu-item
                    v-for="itemChild_Child in itemChild.childMenuList"
                    :index="itemChild_Child.path"
                    :key="itemChild_Child.path"
                  >
                    <span slot="title">{{
                      itemChild_Child.menu_name
                    }}</span></el-menu-item
                  >
                </el-submenu>

                <el-menu-item
                  v-else
                  :index="itemChild.path"
                  :key="itemChild.path"
                  ><span slot="title">{{
                    itemChild.menu_name
                  }}</span></el-menu-item
                >
              </template>
            </el-submenu>

            <el-menu-item v-else :index="item.path" :key="item.path"
              ><i :class="iconsObj[item.menu_id]"></i
              ><span slot="title">{{ item.menu_name }}</span></el-menu-item
            >
          </template>
          <!-- <div v-for="subitem in subMenuList" :key="subitem.menu_id">
            <el-submenu :index="subitem.menu_id + ''" >
              <template slot="title">
                <i class="el-icon-location"></i
                      >
                <span>{{ subitem.menu_name }}</span>
              </template>
              <template>
                <template v-for="chnEngitem in subitem.childMenuList">
                  <el-submenu
                    v-if="
                      chnEngitem.childMenuList &&
                      chnEngitem.childMenuList.length
                    "
                    :index="chnEngitem.menu_id + ''"
                    :key="chnEngitem.menu_id"
                  >
                    <template slot="title"
                      ><span>{{ chnEngitem.menu_name }}</span></template
                    >
                    <div v-for="chnItemChild in chnEngitem.childMenuList" :key="chnItemChild.path">
                      <el-menu-item :index="chnItemChild.path"
                        ><span slot="title">{{
                          chnItemChild.menu_name
                        }}</span></el-menu-item
                      >
                      </div>
                  </el-submenu>
                  <el-menu-item
                    v-else
                    :index="chnEngitem.path"
                    :key="chnEngitem.path"
                    ><span slot="title">{{
                      chnEngitem.menu_name
                    }}</span></el-menu-item
                  >
                </template>
              </template>
            </el-submenu>
          </div> -->
        </el-menu>
      </el-aside>
      <el-main>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </el-main>
    </el-container>
    <!-- 选择用户的Dialog -->
    <el-dialog
      title="选择身份"
      :visible.sync="selectUserDialogViaible"
      width="50%"
    >
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="roleList"
        border
        stripe
      >
        <el-table-column label="部门" prop="deptName"></el-table-column>
        <el-table-column label="角色" prop="roleName"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              @click="userLogin(scope.row.userName)"
              >登录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  data () {
    return {
      iconsObj: {
        a_01: 'el-icon-setting',
        b_01: 'el-icon-school',
        c_01: 'el-icon-user',
        d_01: 'el-icon-document',
        e_01: 'el-icon-dish',
        f_01: 'el-icon-collection',
        g_01: 'el-icon-help',
        h_01: 'el-icon-setting'
      },
      username: window.sessionStorage.getItem('username'),
      roleWildcard: window.sessionStorage.getItem('roleWildcard'),
      deptWildcard: window.sessionStorage.getItem('deptWildcard'),
      isFirstLogin: window.sessionStorage.getItem('isFirstLogin'),
      subMenuList: [
        {
          menu_name: '赛务管理',
          path: '/home',
          menu_id: 'f_01',
          childMenuList: [
            { menu_name: '个人赛赛务管理', menu_id: 'f_01_01', path: '/raceManagement/individual_management/individual_manage_homeview/individual_race_management' }
            // { menu_name: '团体赛赛务管理', menu_id: 'f_01_02', path: '/raceManagement/team_management/team_race_management' }
          ]
        },
        {
          menu_name: '考务管理',
          path: '/home',
          menu_id: 'g_01',
          childMenuList: [
            { menu_name: '个人赛考务管理', menu_id: 'g_01_01', path: '/examination_Management/individual_examination_management/examination_home_manage/examination_home_manage' }
            // { menu_name: '团体赛考务管理', menu_id: 'g_01_02', path: '/examination_Management/team_examination_management/examination_teamhome_manage' }
          ]
        },
        // {
        //   menu_name: '系统数据管理',
        //   path: '/home',
        //   menu_id: 'a_01',
        //   childMenuList: [
        //     { menu_name: '系统学校信息', menu_id: 'a_01_01', path: '/system_data_Management/system_school_infomation' },
        //     { menu_name: '赛区信息管理', menu_id: 'a_01_02', path: '/system_data_Management/system_competition_management' }
        //   ]
        // },
        {
          menu_name: 'B端数据管理',
          path: '/b_data_Management/b_data_management',
          menu_id: 'b_01'
          // childMenuList: [
          //   // { menu_name: '赛区信息管理', menu_id: 'b_02_01', path: '/b_data_Management/b_division_management' },
          //   { menu_name: '注册学校信息', menu_id: 'b_02_03', path: '/b_data_Management/b_school_management' }
          //   // { menu_name: '赛点信息管理', menu_id: 'b_02_04', path: '/b_data_Management/b_matchpoint_management' }
          // ]
        },
        {
          menu_name: 'C端数据管理',
          path: '/c_data_Management/c_data_management_home',
          menu_id: 'c_01'
          // childMenuList: [
          //   { menu_name: '注册用户信息', menu_id: 'c_01_01', path: '/c_data_Management/c_player_registInfo' }
          //   // { menu_name: '教师信息管理', menu_id: 'c_01_03', path: '/c_data_Management/c_player_teacherinfo' }
          // ]
        },
        {
          menu_name: '产品管理',
          path: '/home',
          menu_id: 'd_01',
          childMenuList: [
            { menu_name: '产品订单管理', menu_id: 'd_01_01', path: '/product_Management/product_management' },
            // { menu_name: '字库订单管理', menu_id: 'd_01_03', path: '/product_Management/thesaurus_management' },
            {
              menu_name: '课程订单管理',
              menu_id: 'd_01_05',
              path: '/home',
              childMenuList: [
                { menu_name: '学校课程管理', menu_id: 'd_02_01', path: '/product_Management/course_schoolorder_management' },
                { menu_name: '教师课程管理', menu_id: 'd_02_02', path: '/product_Management/course_teacherorder_management' }
              ]
            }
            // { menu_name: '兑换码管理', menu_id: 'd_01_04', path: '/product_Management/productcode_management' }
          ]
        },
        {
          menu_name: '学术系统',
          path: '/home',
          menu_id: 'e_01',
          childMenuList: [
            // { menu_name: '字库管理', menu_id: 'e_04_01', path: '/academic_Management/academic_font_management/academic_font_management' },
            { menu_name: '课程管理', menu_id: 'e_04_02', path: '/academic_Management/academic_course_management/academic_course_management' }
          ]
        },
        {
          menu_name: '系统管理',
          path: '/system/home',
          menu_id: 'h_01'
        }
      ],
      menuCollapse: false,
      menuOpenIndex: '',
      selectUserDialogViaible: false,
      roleList: []
    }
  },
  created () {
    // this.getMenuList()
    this.navActivePath = window.sessionStorage.getItem('navActivePath')
    // 暂时去掉登陆直接显示工作台页面
    // if (this.isFirstLogin === 'true') {
    //   this.workTable()
    // }
  },
  methods: {
    async getMenuList () {
      const { data: res } = await this.$http.get('/erp/RightsManager/getMenus')
      console.log('getMenuList:', res)
      if (res.code !== 0) {
        this.$message.error(res.msg)
      }
      this.menuList = res.data
    },
    toggleCollapse () {
      this.menuCollapse = !this.menuCollapse
    },
    saveNavActivePath (navActivePath) {
      console.log(navActivePath)
      window.sessionStorage.setItem('navActivePath', navActivePath)
      this.navActivePath = navActivePath
    },
    workTable () {
      window.sessionStorage.setItem('isFirstLogin', false)
      if (this.navActivePath === '') {
        this.$refs.menuRef.close(this.menuOpenIndex)
        this.navActivePath = ''
        window.sessionStorage.setItem('navActivePath', '')
      }
      switch (this.roleWildcard) {
        case 'A2':
          this.$router.push('/workTable/a2')
          break
        case 'A1':
          this.$router.push('/workTable/a1')
          break
        case 'B1':
          switch (this.deptWildcard) {
            case 'sspcn':
              this.$router.push('/workTable/b1Sspcn')
              break
            case 'baby':
              this.$router.push('/workTable/b1Baby')
              break
            case 'itso':
              this.$router.push('/workTable/b1Itso')
              break
          }
          break
      }
    },
    menuOpen (value) {
      this.menuOpenIndex = value
    },
    handleCommand (command) {
      switch (command) {
        case 'logOut':
          window.sessionStorage.clear()
          this.$router.push('/login')
          break
        case 'changeRole':
          break
      }
    }
  }
}
</script>
å
<style lang="less" scoped>
.home-container {
  height: 100%;

  .el-header {
    background-color: #373d41;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 20px;
    > div {
      display: flex;
      align-items: center;
      img {
        height: 46px;
      }
      .username {
        font-size: 16px;
        margin-right: 15px;
      }
      span {
        padding-left: 15px;
      }
    }
  }

  .el-aside {
    background-color: #333744;

    .toggle-button {
      background-color: #4a5064;
      font-size: 10px;
      line-height: 24px;
      color: #fff;
      text-align: center;
      letter-spacing: 0.2em;
      cursor: pointer;
    }

    .el-menu {
      border-right: none;
    }

    .iconfont {
      margin-right: 10px;
    }
  }

  .el-main {
    background-color: #eaedf1;
    height: calc(100vh - 60px);
  }
}
</style>
