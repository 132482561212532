<template>
  <div>
    <!-- :class="hasPerms(item.authorityFlag) ? '' : 'no-permission'" -->
    <div class="system-btn-common"  @click="handleTo(item)">
      <i class="icon iconfont" :class="item.icon"></i>
      <div class="title">{{ item.title }}</div>
      <div class="desc">{{ item.desc }}</div>
    </div>
  </div>
</template>
<script>
// import { hasPermission } from '@/permission/index.js'

export default {
  props: {
    item: {
      type: Object
    },
    index: {
      type: Number
    }
  },
  data () {
    return {

    }
  },
  methods: {
    handleTo (item) {
      console.log(item)
      // if (!this.hasPerms(item.authorityFlag)) {
      //   return this.$message.warning('暂无操作权限')
      // }
      if (item.path) {
        this.$router.push({ path: item.path })
      }
    }
    // hasPerms: function (flag) {
    //   // 根据权限标识和外部指示状态进行权限判断
    //   return hasPermission(flag) // & !this.disabled
    // }
  }
}
</script>
<style lang="less">
.system-btn-common{
  border: 1px solid #0277ff;
  border-radius: 8px;
  background-color: #F4F5FC;
  text-align: center;
  padding: 20px 0;
  margin-right: 20px;
  width: 200px;
  cursor: pointer;
  &:hover{
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  }
  .icon{
    color: #0277ff;
    font-size: 30px;
  }
  .title{
    color: #0277ff;
    font-size: 16px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .desc{
    font-size: 12px;
    color: #7b7b82;
  }
}
</style>
