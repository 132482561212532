<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>个人赛赛务管理</el-breadcrumb-item>
      <el-breadcrumb-item>个人赛程</el-breadcrumb-item>
      <el-breadcrumb-item>复赛</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>报名信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="100px"
        ref="queryFormRef"
      >
        <el-row :gutter="0">
          <el-col :span="6">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.season"
                clearable
                @change="seasonHandleChange"
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="赛程" prop="raceSchedule">
              <el-select
                placeholder="请选择"
                @visible-change="changeRaceScheduleValue"
                style="width: 100%"
                v-model="queryForm.raceSchedule"
                clearable
              >
                <el-option
                  v-for="item in raceScheduleList"
                  :key="item.id"
                  :label="item.scheduleName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item label="学校省市区" prop="schoolArea">
              <el-cascader
                placeholder="请选择"
                v-model="queryForm.schoolArea"
                ref="schoolAreaRef"
                :options="areasData"
                style="width: 100%"
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'id',
                  children: 'areas',
                }"
                @change="provincesHandleChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="学校" prop="schoolId">
              <el-select
                style="width: 100%"
                placeholder="请选择"
                v-model="queryForm.schoolId"
                @change="schoolSelectChange"
                clearable
              >
                <el-option
                  v-for="item in provincesSchoolList"
                  :key="item.id"
                  :label="item.schoolName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单号" prop="orderNo">
              <el-input
                v-model="queryForm.orderNo"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="组别" prop="enrollmentLevel">
              <el-select
                style="width: 100%"
                placeholder="请选择"
                v-model="queryForm.enrollmentLevel"
                clearable
              >
                <el-option
                  v-for="item in dict_group"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开通类型" prop="openType">
              <el-select
                style="width: 100%"
                placeholder="请选择"
                v-model="queryForm.openType"
                clearable
              >
                <el-option
                  v-for="item in p_open_type"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getDataList('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 报名信息列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="title_class">报名信息列表</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="赛季" prop="season"></el-table-column>
        <el-table-column label="姓名" prop="userName"></el-table-column>
        <!-- <el-table-column label="选手照片" prop="userpicurl">
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row.userpicurl ? scope.row.userpicurl : 'https://cdn.spbcn.org/spbcnapp/userpic/yonghu_defult.png'"
              :preview-src-list="[scope.row.userpicurl]"
            >
            </el-image>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          label="性别"
          prop="gender"
          :formatter="genderFormatter"
        ></el-table-column> -->
        <!-- <el-table-column label="省市区" prop="provence">
           <template slot-scope="scope">
            <span>{{scope.row.provinceName}}/{{scope.row.cityName}}/{{scope.row.countyName}}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="学校" prop="schoolName">
          <template slot-scope="scope">
            <span
              v-if="scope.row.fillschoolName && scope.row.fillschoolName !== ''"
              >{{ scope.row.fillschoolName }}</span
            >
            <span v-else>{{ scope.row.schoolName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="组别"
          prop="enrollmentLevel"
          :formatter="groupFormatter"
        ></el-table-column>
        <el-table-column
          label="年级"
          prop="grade"
          :formatter="gradeFormatter"
        ></el-table-column>
        <el-table-column label="联系方式" prop="phone"></el-table-column>
        <el-table-column
          label="开通产品名称"
          prop="productName"
        ></el-table-column>
        <el-table-column
          label="开通类型"
          prop="openType"
          :formatter="openTypeFormatter"
          width="100px"
        ></el-table-column>
        <el-table-column label="参赛方式" prop="isOnline">
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.isOnline + '' === '1'"
              size="mini"
              >线上参赛</el-tag
            >
            <el-tag type="warning" v-else size="mini">线下参赛</el-tag>
            <!-- <span style="color: red" v-if="scope.row.isOnline === 1"
              >线上参赛</span
            >
            <span v-else style="color: green">线下参赛</span> -->
          </template>
        </el-table-column>
        <el-table-column label="开通时间" prop="createTime"></el-table-column>
        <el-table-column label="订单号" prop="orderNo"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import areasData from '@/assets/area.json'
import { queryTbEnrollmentList, getSchoolListWithCity, querySeasonRaceScheduleFlag } from '@/http/api'
export default {
  data () {
    return {
      timer: new Date().getTime(),
      getGradeData: this.$userInfo.getGradeData(),
      dict_group: this.$userInfo.dict_group(),
      p_open_type: this.$userInfo.getDataList(this, 'p_open_type').then(value => { this.p_open_type = value }),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      queryForm: {
        phone: '',
        name: '',
        season: this.$chnEngStatusCode.defaultSeason,
        group: '',
        schoolId: '',
        pageNum: 1,
        pageSize: 10,
        raceSchedule: '2',
        userName: ''
      },
      raceScheduleList: [],
      total: 0,
      dict_grade: this.$userInfo.getGradeData(),
      dict_gender: this.$userInfo.getDataList(this, 't_gender').then(value => { this.dict_gender = value }),
      dataList: [],
      provincesSchoolList: [],
      areasData: areasData.areas
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 赛季选择事件回调
    seasonHandleChange (value) {
      var seasonStr = ''
      if (!value) {
        seasonStr = this.queryForm.season
      } else {
        seasonStr = value
      }
      var formData = {
        activityType: this.$chnEngStatusCode.activityType,
        season: seasonStr,
        scheduleFlag: this.$route.query.scheduleFlag
      }
      this.searchScheduleWithFlag(formData)
    },
    // 根据赛季标识符查询赛程相关
    searchScheduleWithFlag (formData) {
      querySeasonRaceScheduleFlag(formData).then((res) => {
        this.queryForm.raceSchedule = res.data.id
        this.getDataList('search')
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取报名列表
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      queryTbEnrollmentList(this.queryForm).then((res) => {
        console.log('queryTbEnrollmentList', res)
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    genderFormatter (data) {
      var temp = '未知'
      for (const key in this.dict_gender) {
        if (Object.hasOwnProperty.call(this.dict_gender, key)) {
          const element = this.dict_gender[key]
          if (element.dictValue === data.gender + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    gradeFormatter (data) {
      var temp = '未知'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (element.dictValue === data.grade + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    openTypeFormatter (data) {
      var temp = '未知'
      for (const key in this.p_open_type) {
        if (Object.hasOwnProperty.call(this.p_open_type, key)) {
          const element = this.p_open_type[key]
          if (element.dictValue === data.openType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      var temp = '未知'
      for (const key in this.dict_group) {
        if (Object.hasOwnProperty.call(this.dict_group, key)) {
          const element = this.dict_group[key]
          if (element.dictValue === data.enrollmentLevel + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // 赛季选择事件回调
    // seasonHandleChange (value) {
    //   console.log('value', value)
    //   if (!value) return
    //   var formData = {
    //     activityType: this.$chnEngStatusCode.activityType,
    //     season: value
    //   }
    //   this.raceScheduleList = this.$chnEngPublicRequest.querySeasonRaceSchedule(this, formData).then(value => { this.raceScheduleList = value })
    // },
    // 赛程选择校验
    changeRaceScheduleValue (state) {
      console.log(state)
      if (state === true && this.queryForm.season === '') {
        this.$message.error('请先选择赛季')
      }
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      if (!value) return
      this.provincesSchoolList = []
      this.queryForm.schoolName = ''
      this.queryForm.schoolId = ''
      this.queryForm.provinceCode = value[0]
      this.queryForm.cityCode = value[1]
      this.queryForm.areaCode = value[2]
      console.log('queryForm', this.queryForm)
      this.getSchoolListData(value)
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      console.log('schoolArea', schoolArea)
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        console.log('getSchoolList', res)
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 学校选择赋值schoolId
    schoolSelectChange (schoolid) {
      this.queryForm.schoolId = schoolid
    }
  }
}
</script>

<style lang="less" scoped>
.title_class {
  display: -moz-inline-box;
  display: inline-block;
  width: 120px;
}
</style>
