<template>
  <div class="bg">
    <div class="login-container">
      <img src="../../assets/img/newerp_button3.png" class="logo" alt="">
      <div class="login-box">
        <!-- <div class="avatar_box">
          <img src="../../assets/img/newerp_icon3.png" alt="" />
        </div> -->
        <div class="login-txt">登录</div>
        <el-form
          ref="loginFormRef"
          class="login_form"
          :rules="loginFormRules"
          :model="loginForm"
        >
          <el-form-item prop="phone">
            <el-input class="input"
              v-model="loginForm.phone"
              prefix-icon="iconfont icon-user"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input class="input"
              v-model="loginForm.code"
              prefix-icon="iconfont icon-key"
              type="code"
              placeholder="请输入短信验证码"
            >
              <el-button class="send-btn"
                :loading="sending"
                :disabled="sendDisabled"
                size="small"
                @click="sendSmsClicked"
                slot="suffix"
                >{{ sendButtonText }}</el-button
              >
            </el-input>
          </el-form-item>
          <el-form-item class="btns">
            <el-button class="login-btn" type="primary" @click="mainLogin"  @keyup.enter="mainLogin(e)"
              >登 录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 选择用户的Dialog -->
      <el-dialog
        title="选择身份"
        :visible.sync="selectUserDialogViaible"
        width="50%"
      >
        <el-table header-cell-class-name="tableHeaderStyle" :data="userList" border stripe>
          <el-table-column label="部门" prop="deptName"></el-table-column>
          <el-table-column label="角色" prop="roleName"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="userLogin(scope.row.userName)"
                >登录</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { onSendSms, chnEngLoginRequest, getSysUserInfo } from '../../http/api'
export default {
  data () {
    return {
      loginForm: {
        phone: '',
        code: ''
      },
      loginFormRules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' }
        ]
      },
      userList: [],
      selectUserDialogViaible: false,
      sending: false,
      sendDisabled: false,
      loading: false,
      timer: 0
    }
  },
  mounted () {
    // 绑定监听事件
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed () {
    // 销毁事件
    window.removeEventListener('keydown', this.keyDown, false)
  },
  created () {
    localStorage.removeItem('Authorization') // 移除token，跳转至登录
    const lastSendTime = window.sessionStorage.getItem('last-send-time')
    if (lastSendTime) {
      const timer = 60 - this.$moment(new Date()).diff(this.$moment(lastSendTime), 'seconds')
      if (timer > 0) this.sendDisabled = true
      this.timer = timer > 0 ? timer : 0
    }
  },
  methods: {
    // 点击回车键登录
    keyDown (e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.mainLogin() // 定义的登录方法
      }
    },
    resetLoginForm () {
      this.$refs.loginFormRef.resetFields()
    },
    // async userLogin (userName) {
    //   const { data: res } = await this.$http.get('/erp/login', {
    //     params: {
    //       username: userName
    //     }
    //   })
    //   console.log('login', res)
    //   if (res.code !== 0) {
    //     return this.$message.error(res.msg)
    //   }
    //   window.sessionStorage.setItem('token', res.data.token)
    //   window.sessionStorage.setItem('deptWildcard', res.data.deptWildcard)
    //   window.sessionStorage.setItem('roleWildcard', res.data.roleWildcard)
    //   window.sessionStorage.setItem('username', userName)
    //   window.sessionStorage.setItem('roleId', res.data.roleId)
    //   window.sessionStorage.setItem('deptId', res.data.deptId)
    //   window.sessionStorage.setItem('userId', res.data.userId)
    //   window.sessionStorage.setItem('isFirstLogin', true)
    //   this.$router.push('/home')
    // },
    // 获取短信
    sendSmsClicked () {
      onSendSms({ phone: this.loginForm.phone, codeType: this.$chnEngStatusCode.LOGINSMSTYPE }).then((res) => {
        this.$message.success('短信发送成功，请注意查收')
        window.sessionStorage.setItem('loginLast-send-time', new Date())
        this.timer = 60
        this.sendDisabled = true
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 登录
    mainLogin () {
      const formData = new FormData()
      formData.append('phone', this.loginForm.phone)
      formData.append('code', this.loginForm.code)
      formData.append('type', this.$chnEngStatusCode.LOGINTYPE)
      formData.append('codeType', this.$chnEngStatusCode.LOGINSMSTYPE)
      this.$refs.loginFormRef.validate(async valid => {
        if (!valid) return
        this.isLogonState = true
        chnEngLoginRequest(formData).then((res) => {
          console.log('mainLoginres', res)
          this.$router.push('/home')
        }).catch(() => {
          this.isLogonState = false
        })
      })
    },
    // 获取用户信息
    getUserInfoData () {
      getSysUserInfo().then((res) => {
        console.log('success', res)
        this.isLogonState = false
        this.$message.success('登录成功！')
        console.log('turnUrl', this.turnUrl)
        if (this.turnUrl && this.turnUrl !== '') {
          this.$router.push(this.turnUrl)
        } else {
          this.$router.push('/')
        }
      }).catch((err) => {
        this.isLogonState = false
        console.log('返回的err', err)
      })
    }
  },
  computed: {
    sendButtonText () {
      if (this.timer === 0) {
        return '发送验证码'
      } else {
        return `${this.timer}秒后重发`
      }
    }
  },
  watch: {
    timer (num) {
      if (num > 0) {
        setTimeout(() => {
          this.timer = --num
        }, 1000)
      } else {
        this.sendDisabled = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.bg{
  background-color: #6a6969;
  height: 100%;
}

.login-container {
  background-image: url(https://cdn.spbcn.org/chineseWord/erp/product/images/hqbg.png);
  background-size: cover;
  height: 100%;
  position: relative;
  .logo{
    position: absolute;
    top: 20px;
    left: 40px;
    height: 60px;
  }
  .login-btn{
    width: 180px;
    border-radius: 40px;
    background-color: #231816;
    border-color: #231816;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .login-txt{
    font-size: 28px;
    color: #333;
    margin-left: 30px;
    margin-top: 40px;
    padding-bottom: 4px;
    border-bottom: 3px solid #ee0013;
    width: 66px;
  }
  .send-btn{
    border: none;
    &:hover, &:focus{
      color: #ee0013;
      background-color: transparent;
    }
  }
  .input ::v-deep .el-input__inner{
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #DCDFE6;
  }
  .login-box {
    width: 450px;
    height: 360px;
    background-color: #fff;
    border-radius: 12px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .avatar_box {
      height: 130px;
      width: 130px;
      border: 1px solid #eee;
      border-radius: 50%;
      padding: 10px;
      box-shadow: 0 0 10px #ddd;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;

      img {
        width: 100%;
        height: auto;
      }
    }

    .login_form {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;

      .btns {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
