import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/iconfont/iconfont.css'
import './assets/global.css' // 引入全局样式
import './style/common.less'
import moment from 'moment'
import VueRouter from 'vue-router'
import xcUtils from './utils/xcUtils'
import curUtils from './utils/curUtils'
import userInfo from './utils/userInfo'
import chnEngPublicRequest from './utils/chnEngPublicRequest'
import upLoadFileRequest from './utils/upLoadFileRequest'
import chnEngStatusCode from './utils/chnEngStatusCode'
import echarts from 'echarts'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import http from './http/request'
import env from './http/env.js'
import environment from './http/environment.js'

import downLoadFile from './utils/downLoadFile'
import cebsbLoading from '@/components/cebsbLoading/index.js'
import './utils/directive'
import system from '@/components/auth/system.vue'
Vue.component('system', system)

Vue.use(cebsbLoading)
NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false,
  trickleSpeed: 200,
  minimum: 0.3
})
// 路由监听
router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

// 路由跳转结束
router.afterEach(() => {
  NProgress.done()
})
Vue.prototype.$xcUtils = xcUtils
Vue.prototype.$curUtils = curUtils
Vue.prototype.$userInfo = userInfo
Vue.prototype.$chnEngPublicRequest = chnEngPublicRequest
Vue.prototype.$upLoadFileRequest = upLoadFileRequest
Vue.prototype.$chnEngStatusCode = chnEngStatusCode
Vue.prototype.$http = http
Vue.prototype.$env = env
Vue.prototype.$environment = environment
Vue.prototype.$downLoadFile = downLoadFile
Vue.prototype.$echarts = echarts
moment.locale('zh-cn')
Vue.prototype.$moment = moment
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
