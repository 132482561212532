import Vue from 'vue'
import VueRouter from 'vue-router'
// 404
const errorpage = () => import('../views/error/errorpage.vue')
// 登录
const login = () => import(/* webpackChunkName: "login_home_welcome" */ '../views/login/login.vue')
// 主页
const home = () => import(/* webpackChunkName: "login_home_welcome" */ '../views/home/home.vue')
// 欢迎页
const welcome = () => import(/* webpackChunkName: "login_home_welcome" */ '../views/home/welcome.vue')

// 赛务管理-个人赛赛务管理-个人赛
const individualRaceManagement = () => import('../views/home/raceManagement/individual_management/individual_manage_homeview/individual_race_management.vue')
// 赛务管理-个人赛赛务管理-初赛
const preliminaryRaceManagement = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_schedules/preliminary_raceManagement.vue')
// 赛务管理-个人赛赛务管理-展演
const performanceRegister = () => import('../views/home/raceManagement/individual_management/performance_register.vue')

// 赛务管理-个人赛赛务管理-复赛
const rematchRaceManagement = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_schedules/rematch_raceManagement.vue')
// 赛务管理-个人赛赛务管理-初赛-个人赛考量信息
// const bSchoolConsiderations = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/individual_school_considerations.vue')
// 赛务管理-个人赛赛务管理-初赛-个人赛考量信息-个人赛考量信息详情
const bSchoolManagementDetail = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/individual_school_management_detail.vue')
// 赛务管理-个人赛赛务管理-比赛时间管理
const individualGametimeManage = () => import('../views/home/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/individual_gametime_manage.vue')
// 赛务管理-个人赛赛务管理-团体赛
const teamRaceManagement = () => import('../views/home/raceManagement/team_management/team_race_management.vue')

// 考务管理-个人赛考务管理-初选
const examinationHomeManage = () => import('../views/home/examination_Management/individual_examination_management/examination_home_manage/examination_home_manage.vue')
// 考务管理-个人赛考务管理-初选-成绩查询
const scheduleIndividualCompetition = () => import('../views/home/examination_Management/individual_examination_management/schedule_competitions/schedule_individual_competition.vue')
// 考务管理-个人赛考务管理-初选-答题详情
const individualAnswerResult = () => import('../views/home/examination_Management/individual_examination_management/schedule_competitions/schedule_Childview/individual_answer_result.vue')
// 考务管理-个人赛考务管理-复选-成绩查询
const scheduleRematchCompetition = () => import('../views/home/examination_Management/individual_examination_management/schedule_competitions/schedule_rematch_competition.vue')
// 考务管理-团体赛考务管理
const examinationTeamhomeManage = () => import('../views/home/examination_Management/team_examination_management/examination_teamhome_manage.vue')

// 系统数据管理-系统学校基础信息
const sysSchoolInfomation = () => import('../views/home/system_data_Management/system_school_infomation.vue')
// 系统数据管理-系统赛区管理
const systemCompetitionManagement = () => import('../views/home/system_data_Management/system_competition_management.vue')
const sysSchoolClub = () => import('../views/home/system_data_Management/school_club/school_club_list.vue')
// 系统数据管理-系统赛区详情
const systemCompetitionDetails = () => import('../views/home/system_data_Management/system_competition_details.vue')
// 系统数据管理-系统学校基础信息-学校信息详情
const systemSchoolInfomationDetail = () => import('../views/home/system_data_Management/system_school_infomation_detail.vue')
// 系统数据管理-系统操作日志信息
const systemOperationLog = () => import('../views/home/system_data_Management/system_operation_log.vue')

// B端数据管理-首页
const bDataManagement = () => import('../views/home/b_data_Management/b_data_management.vue')
// B端数据管理-学校信息管理
const bSchoolManagement = () => import('../views/home/b_data_Management/b_school_management.vue')
// B端数据管理-赛区信息管理
const bDivisionManagement = () => import('../views/home/b_data_Management/b_division_management.vue')
// B端数据管理-赛点信息管理
const bMatchpointManagement = () => import('../views/home/b_data_Management/b_matchpoint_management.vue')

// C端数据管理-首页
const cDataManagementHome = () => import('../views/home/c_data_Management/c_data_management_home.vue')
// C端数据管理-注册信息查看
const cPlayerRegistInfo = () => import('../views/home/c_data_Management/c_player_registInfo.vue')
// C端数据管理-注册信息详情
const cPlayerRegistInfoDetails = () => import('../views/home/c_data_Management/c_player_registInfo_details.vue')
// C端数据管理-教师信息管理
const cPlayerTeacherinfo = () => import('../views/home/c_data_Management/c_player_teacherinfo.vue')

// 产品管理-字库管理
const thesaurusManagement = () => import('../views/home/product_Management/thesaurus_management.vue')
// 产品管理-课程订单管理-学校订单管理
const courseSchoolorderManagement = () => import('../views/home/product_Management/course_schoolorder_management.vue')
// 产品管理-课程订单管理-教师订单管理
const courseTeacherorderManagement = () => import('../views/home/product_Management/course_teacherorder_management.vue')
// 产品管理-开通课程订单管理
const courseOpenManagement = () => import('../views/home/product_Management/course_open_management.vue')
// 产品管理-兑换码管理
const productcodeManagement = () => import('../views/home/product_Management/productcode_management.vue')
// 产品管理-订单管理
const productManagement = () => import('../views/home/product_Management/product_management.vue')
// 产品管理-订单管理-订单详情
const productDetailManagement = () => import('../views/home/product_Management/product_detail_management.vue')

// 学术管理-字库管理
const academicFontManagement = () => import('../views/home/academic_Management/academic_font_management/academic_font_management.vue')
// 学术管理-课程管理
const academicCourseManagement = () => import('../views/home/academic_Management/academic_course_management/academic_course_management.vue')
// 学术管理-课程管理-新增课程
const academicCourseDetail = () => import('../views/home/academic_Management/academic_course_management/academic_course_detail.vue')
// 系统管理-首页
const systemHome = () => import('../views/home/system_Management/system_management_home.vue')
// 系统管理-字典
const dictManagement = () => import('../views/home/system_Management/dictionary/dictionary.vue')
const dictChild = () => import('../views/home/system_Management/dictionary/dictionary_child.vue')

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '*',
      name: '404',
      component: errorpage
    },
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      component: login
    },
    {
      path: '/home',
      component: home,
      redirect: '/welcome',
      children: [
        {
          path: '/welcome',
          component: welcome
        },
        {
          path: '/system_data_Management/system_school_infomation_detail',
          component: systemSchoolInfomationDetail
        },
        {
          path: '/system_data_Management/system_operation_log',
          component: systemOperationLog
        },
        {
          path: '/c_data_Management/c_player_registInfo',
          component: cPlayerRegistInfo
          // meta: {
          //   keepAlive: true // 缓存，保存状态，用于页面返回不刷新
          // }
        },
        {
          path: '/c_data_Management/c_data_management_home',
          component: cDataManagementHome
        },
        {
          path: '/c_data_Management/c_player_registInfo_details',
          component: cPlayerRegistInfoDetails
        },
        {
          path: '/c_data_Management/c_player_teacherinfo',
          component: cPlayerTeacherinfo
        },
        {
          path: '/system_data_Management/system_school_infomation',
          component: sysSchoolInfomation
        },
        {
          path: '/system_data_Management/system_competition_management',
          component: systemCompetitionManagement
        },
        {
          path: '/system_data_Management/system_competition_details',
          component: systemCompetitionDetails
        },
        {
          path: '/system_data_Management/school_club',
          component: sysSchoolClub
        },
        {
          path: '/b_data_Management/b_school_management',
          component: bSchoolManagement
        },
        {
          path: '/b_data_Management/b_data_management',
          component: bDataManagement
        },
        {
          path: '/b_data_Management/b_division_management',
          component: bDivisionManagement
        },
        {
          path: '/b_data_Management/b_matchpoint_management',
          component: bMatchpointManagement
        },
        {
          path: '/raceManagement/individual_management/individual_manage_homeview/individual_race_management',
          component: individualRaceManagement
        },
        // {
        //   path: '/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/individual_school_considerations',
        //   component: bSchoolConsiderations
        // },
        {
          path: '/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/individual_school_management_detail',
          component: bSchoolManagementDetail
        },
        {
          path: '/raceManagement/individual_management/raceManagement_childview/individual_racing_tool/individual_gametime_manage',
          component: individualGametimeManage
        },
        {
          path: '/product_Management/thesaurus_management',
          component: thesaurusManagement
        },
        {
          path: '/product_Management/course_open_management',
          component: courseOpenManagement
        },
        {
          path: '/product_Management/product_management',
          component: productManagement
          // meta: {
          //   keepAlive: true // 缓存，保存状态，用于页面返回不刷新
          // }
        },
        {
          path: '/product_Management/productcode_management',
          component: productcodeManagement
        },
        {
          path: '/product_Management/course_schoolorder_management',
          component: courseSchoolorderManagement
        },
        {
          path: '/product_Management/course_teacherorder_management',
          component: courseTeacherorderManagement
        },
        {
          path: '/product_Management/product_detail_management',
          component: productDetailManagement
        },
        {
          path: '/raceManagement/individual_management/raceManagement_childview/individual_racing_schedules/preliminary_raceManagement',
          component: preliminaryRaceManagement
        },
        {
          path: '/raceManagement/individual_management/performanceRegister',
          component: performanceRegister
        },
        {
          path: '/raceManagement/individual_management/raceManagement_childview/individual_racing_schedules/rematch_raceManagement',
          component: rematchRaceManagement
        },
        {
          path: '/examination_Management/individual_examination_management/examination_home_manage/examination_home_manage',
          component: examinationHomeManage
        },
        {
          path: '/examination_Management/individual_examination_management/schedule_competitions/schedule_individual_competition',
          component: scheduleIndividualCompetition
          // meta: {
          //   keepAlive: true // 缓存，保存状态，用于页面返回不刷新
          // }
        },
        {
          path: '/examination_Management/individual_examination_management/schedule_competitions/schedule_Childview/individual_answer_result',
          component: individualAnswerResult
        },
        {
          path: '/examination_Management/individual_examination_management/schedule_competitions/schedule_rematch_competition',
          component: scheduleRematchCompetition
          // meta: {
          //   keepAlive: true // 缓存，保存状态，用于页面返回不刷新
          // }
        },
        {
          path: '/examination_Management/team_examination_management/examination_teamhome_manage',
          component: examinationTeamhomeManage
        },
        {
          path: '/raceManagement/team_management/team_race_management',
          component: teamRaceManagement
        },
        {
          path: '/academic_Management/academic_font_management/academic_font_management',
          component: academicFontManagement
        },
        {
          path: '/academic_Management/academic_course_management/academic_course_management',
          component: academicCourseManagement
          // meta: {
          //   keepAlive: true // 缓存，保存状态，用于页面返回不刷新
          // }
        },
        {
          path: '/academic_Management/academic_course_management/academic_course_detail',
          component: academicCourseDetail
        },
        {
          path: '/system/home',
          component: systemHome
        },
        {
          path: '/system/dict',
          component: dictManagement
        },
        {
          path: '/system/dict/data',
          component: dictChild
        }
      ]
    }
  ]
})

// 挂载导航路由守卫
router.beforeEach((to, from, next) => {
  const whiteList = [
    '/login'
  ]
  if (whiteList.includes(to.path)) return next()
  if (!localStorage.getItem('Authorization')) return next('/login')
  next()
})

export default router
