<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>赛务管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/raceManagement/team_management/team_race_management' }">团体赛赛务管理 </el-breadcrumb-item>
      <el-breadcrumb-item>俱乐部管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-tabs type="border-card">
      <el-tab-pane label="俱乐部信息">
        <clubInfoForm></clubInfoForm>
      </el-tab-pane>
      <el-tab-pane label="战队信息" v-if="false">
        <teamInfoForm></teamInfoForm>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import teamInfoForm from './team_info_form.vue'
import clubInfoForm from './club_info_form.vue'

export default {
  components: { teamInfoForm, clubInfoForm },
  methods: {
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style>
</style>
