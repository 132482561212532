<template>
    <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>赛务管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/raceManagement/individual_management/individual_manage_homeview/individual_race_management' }">个人赛赛务工具</el-breadcrumb-item>
      <el-breadcrumb-item>试卷申请管理</el-breadcrumb-item>
      <el-breadcrumb-item>试卷申请详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>学校信息</span>
        </el-col>
      </el-row>
      <el-form
        :model="registModel"
        ref="schoolIndividualRegistRuleFormRef"
        label-width="140px"
        style="width: 100%; margin-top: 4%"
      >
        <div
          class="prompt-mailingInfo"
          style="font-size: 16px; font-weight: 700; margin-left: 100px"
        >
          个人赛考量信息
        </div>
        <el-row :gutter="24">
          <el-col :span="10" :offset="2">
            <el-form-item label="赛季" prop="season">
              <el-input v-model="seasonActivityName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10"
            ><el-form-item label="预计考试时间" prop="expectedTime">
              <el-date-picker
                disabled
                style="width: 100%"
                v-model="registModel.expectedTime"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="10" :offset="2">
            <el-form-item label="负责老师" prop="creator">
              <el-input
                disabled
                v-model="registModel.creator"
                placeholder="请输入姓名"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="总考量" prop="examNum">
              <el-input
                disabled
                v-model="registModel.examNum"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="10" :offset="2">
            <el-form-item label="参赛包含年级" prop="gameGroupList">
              <el-select
                disabled
                v-model="registModel.gameGroupList"
                multiple
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_grade"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="10"
            ><el-form-item label="每年级班级数" prop="classStr">
              <el-select
                disabled
                v-model="registModel.classStr"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in classListData"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <div
          class="gradeList-backview"
        >
          <div v-for="(groupItem, index) in registModel.gradeListStr" :key="index">
            <span class="group-titleclass">{{ groupItem.gradeName }}</span>
            <div class="grade-classnumber">
              <el-form-item
                v-for="(item, index) in groupItem.classListDto"
                :label="item.className + '预计考量'"
                :key="index"
              >
                <el-input
                  disabled
                  v-model="item.number"
                  placeholder="请输入考量"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div
          class="prompt-mailingInfo"
          style="font-size: 16px; font-weight: 700; margin-left: 100px"
        >
          邮寄信息
        </div>
        <el-row :gutter="24">
          <el-col :span="10" :offset="2">
            <el-form-item label="收件人姓名" prop="receiptName">
              <el-input
                disabled
                style="width: 100%"
                v-model="registModel.receiptName"
                placeholder="请输入收件人姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="收件人联系方式" prop="receiptPhone">
              <el-input
                disabled
                style="width: 100%"
                v-model="registModel.receiptPhone"
                placeholder="请输入收件人联系方式"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="10" :offset="2">
            <el-form-item label="收货地址" prop="goodsReceiveArea">
              <el-cascader
                disabled
                style="width: 100%"
                placeholder="请选择省市区"
                v-model="registModel.goodsReceiveArea"
                :options="areasData"
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'id',
                  children: 'areas',
                }"
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="10" :offset="2">
            <el-form-item label="详细地址" prop="receiptAddress">
              <el-input
                disabled
                style="width: 100%"
                type="textarea"
                :rows="2"
                placeholder="例：镇（街道），xx小区x号楼x单元门牌号"
                v-model="registModel.receiptAddress"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <!-- <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>试卷申请信息</span>
        </el-col>
      </el-row>
      <el-row :gutter="24">
          <el-col :span="10" :offset="2">
            <div class="rejectionReason">{{registModel.applyRemark}}</div>
          </el-col>
      </el-row>
    </el-card> -->
    </div>
</template>

<script>
import areasData from '@/assets/area.json'
import { queryPersonalPaperApplyInfo } from '@/http/api'
export default {
  data () {
    return {
      schoolDetailId: this.$route.query.schoolDetailId,
      classListData: [],
      registModel: {
        expectedTime: '',
        season: '',
        activityName: '',
        classStr: '',
        gameGroupList: [],
        gradeStr: '',
        gradeListStr: [],
        teacherName: '',
        phone: '',
        code: '',
        receiptName: '',
        receiptPhone: '',
        goodsReceiveArea: []
      },
      seasonActivityName: '',
      dict_grade: this.$userInfo.getGradeData().slice(0, 9),
      areasData: areasData.areas
    }
  },
  created () {
    for (let index = 0; index < 50; index++) {
      const item = {}
      item.dictCode = (index + 1) + ''
      item.dictLabel = (index + 1) + ''
      item.dictValue = (index + 1) + ''
      this.classListData.push(item)
    }
    const lastSendTime = window.sessionStorage.getItem('last-send-signUptime')
    if (lastSendTime) {
      const timer = 60 - this.$moment(new Date()).diff(this.$moment(lastSendTime), 'seconds')
      if (timer > 0) this.sendDisabled = true
      this.timer = timer > 0 ? timer : 0
    }
    console.log('schoolDetailId', this.$route.query)
    this.schoolDetailData()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取考量详情
    schoolDetailData () {
      queryPersonalPaperApplyInfo({ id: this.schoolDetailId }).then((res) => {
        console.log('queryPersonalPaperApplyInfo', res)
        this.seasonActivityName = res.data.paperApply.season + '赛季'
        this.registModel = res.data.paperApply
        this.registModel.goodsReceiveArea = [res.data.paperApply.provinceCode + '', res.data.paperApply.cityCode + '', res.data.paperApply.areaCode + '']
        this.registModel.gameGroupList = res.data.paperApply.gradeStr.split(',')
      }).catch((err) => {
        console.log('err1111', err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-form{
  margin-top: 0px !important;
}
.top-backview {
  width: 100%;
  padding-bottom: 10px;
  background-color: rgb(216, 216, 216);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contnet-backview {
  width: 1000px;
  background-color: #e6e6e6;
  display: flex;
  // align-items: center;
  flex-direction: column;
  position: relative;
}
.group-titleclass {
  width: 100px;
  font-size: 14px;
  color: #000000;
}
.grade-classnumber {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.gradeList-backview {
  width: 800px;
  margin-left: 100px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.payment-footview {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prompt-mailingInfo {
  color: #303133;
  margin-top: 20px;
  margin-bottom: 10px;
}
.address-infoview {
  width: 800px;
  margin-left: 100px;
}
.finish-orderview {
  width: 100%;
  padding: 140px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .prompt-order-oneview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .prompt-order-oneview-image {
      width: 130px;
      height: 130px;
      margin-bottom: 10px;
      // margin-right: 5px;
    }
    .prompt-order-titlestr {
      font-size: 15px;
      color: black;
    }
  }
  .wechat-codeimage {
    margin-top: 20px;
    width: 200px;
    height: 200px;
  }
}
</style>
