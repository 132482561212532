<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>产品订单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>订单筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="120px"
        ref="queryFormRef"
      >
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择赛季"
                style="width: 100%"
                clearable
                v-model="queryForm.season"
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属赛区" prop="districtId">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.districtId"
                clearable
              >
                <el-option
                  v-for="item in dict_district"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="学校省市区" prop="schoolArea">
              <el-cascader
                placeholder="请选择"
                clearable
                v-model="queryForm.schoolArea"
                ref="schoolAreaRef"
                :options="areasData"
                style="width: 100%"
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'id',
                  children: 'areas',
                }"
                @change="provincesHandleChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="学校" prop="schoolId">
              <el-select
                ref="schoolSelectRef"
                style="width: 100%"
                placeholder="请选择"
                v-model="queryForm.schoolId"
                clearable
                filterable
              >
                <el-option
                  v-for="item in provincesSchoolList"
                  :key="item.id"
                  :label="item.schoolName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <!-- <el-col :span="9">
            <el-form-item label="起止时间" prop="dateTimeRange">
              <el-date-picker
                v-model="queryForm.dateTimeRange"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
                clearable
              >
              </el-date-picker>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item label="姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单编号" prop="orderNo">
              <el-input
                v-model="queryForm.orderNo"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单来源" prop="orderSource">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.orderSource"
                clearable
              >
                <el-option
                  v-for="item in order_source"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="订单状态" prop="orderStatus">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.orderStatus"
                clearable
              >
                <el-option
                  v-for="item in order_status"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="end">
          <el-col :span="2">
            <el-button type="primary" style="width: 100%" @click="getDataList"
              >查询</el-button
            >
          </el-col>
          <el-col :span="2">
            <el-button type="primary" style="width: 100%" @click="reset"
              >重置</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <!-- 订单列表 -->
    <el-card class="list_card" style="margin-top: 20px">
      <el-row class="title_row">
        <el-col :span="12">
          <span>订单列表</span>
        </el-col>
        <el-col :span="12">
          <div style="text-align: right">
            <el-button type="primary" @click="exportOrderList"
              >导出订单</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="赛季" prop="season"></el-table-column>
        <el-table-column
          label="订单名称"
          prop="orderName"
          width="150px"
        ></el-table-column>
        <el-table-column label="订单号" prop="orderNo"></el-table-column>
        <el-table-column label="购买用户" prop="userName"></el-table-column>
        <el-table-column label="手机号" prop="phone"></el-table-column>
        <el-table-column label="订单金额" prop="orderAmount"></el-table-column>
        <!-- <el-table-column
          label="优惠金额"
          prop="discountAmount"
        ></el-table-column> -->
        <el-table-column
          label="实付金额"
          prop="paymentAmount"
        ></el-table-column>
        <el-table-column
          label="订单来源"
          prop="orderSource"
          :formatter="sourceFormatter"
        >
        </el-table-column>
        <el-table-column label="下单时间" prop="orderTime"></el-table-column>
        <el-table-column label="是否寄送" prop="isSend" width="100px">
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.isSend + '' === '1'"
              size="mini"
              >需要寄送</el-tag
            >
            <el-tag type="warning" v-else size="mini">无需寄送</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="订单状态"
          prop="orderStatus"
          width="100px"
          :formatter="statusFormatter"
        >
          <template slot-scope="scope">
            <el-tag
              style="color: #28526c"
              v-if="scope.row.orderStatus + '' === '1'"
              size="mini"
              >待支付</el-tag
            >
            <el-tag
              type="warning"
              v-else-if="scope.row.orderStatus + '' === '2'"
              size="mini"
              >待发货</el-tag
            >
            <el-tag
              type="primary"
              v-else-if="scope.row.orderStatus + '' === '3'"
              size="mini"
              >已发货</el-tag
            >
            <el-tag
              style="color: #498327"
              v-else-if="scope.row.orderStatus + '' === '4'"
              size="mini"
              >订单完成</el-tag
            >
            <el-tag
              style="color: #fc530a"
              v-else-if="scope.row.orderStatus + '' === '5'"
              size="mini"
              >申请退款</el-tag
            >
            <el-tag
              style="color: #fcbc07"
              v-else-if="scope.row.orderStatus + '' === '6'"
              size="mini"
              >退款审核中</el-tag
            >
            <el-tag
              type="success"
              v-else-if="scope.row.orderStatus + '' === '7'"
              size="mini"
              >退款成功</el-tag
            >
            <el-tag
              type="danger"
              v-else-if="scope.row.orderStatus + '' === '8'"
              size="mini"
              >退款失败</el-tag
            >
            <el-tag
              type="info"
              v-else-if="scope.row.orderStatus + '' === '9'"
              size="mini"
              >订单关闭</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="showProductDetailDialog(scope.row)"
              >查看详情</el-button
            >
            <el-button
              v-if="
                scope.row.orderStatus &&
                scope.row.orderStatus + '' === '2' &&
                scope.row.isSend + '' === '1'
              "
              type="text"
              size="small"
              @click="showSendProductDetailDialogClicked(scope.row)"
              >寄送信息</el-button
            >
            <el-button
              v-if="
                scope.row.orderStatus &&
                scope.row.orderStatus + '' === '3' &&
                scope.row.isSend + '' === '1'
              "
              type="text"
              size="small"
              @click="confirmReceiptGoods(scope.row)"
              >确认收货</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 填写快递信息弹窗 -->
    <el-dialog
      title="快递信息"
      :visible.sync="showSendProductDetailDialogVisible"
      @close="showSendProductDetailDialogClose"
      width="30%"
    >
      <el-form
        :model="sendProductForm"
        label-width="100px"
        ref="sendProductFormRef"
        :rules="sendProductInfoRules"
        style="width: 100%"
      >
        <el-form-item label="快递公司" prop="expressType">
          <el-select
            placeholder="请选择"
            v-model="sendProductForm.expressType"
            style="width: 100%"
          >
            <el-option
              v-for="item in dict_express"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号" prop="expressNumber">
          <el-input
            type="textarea"
            v-model="sendProductForm.expressNumber"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSendProductDetailDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="subSendProductMessage"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import env from '@/http/env'
import areasData from '@/assets/area.json'
import { getTbOrderList, fillInTheTrackingNumber, executionOrderCompletion, getSchoolListWithCity } from '@/http/api'
export default {
  data () {
    return {
      showSendProductDetailDialogVisible: false,
      sendProductForm: {
        expressType: '',
        expressNumber: ''
      },
      areasData: areasData.areas,
      dataList: [],
      dict_express: this.$userInfo.getDataList(this, 'express_type').then(value => { this.dict_express = value }),
      dict_district: this.$userInfo.getDataList(this, 't_district').then(value => { this.dict_district = value }),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      order_source: this.$userInfo.orderSource(),
      order_status: this.$userInfo.orderStatus(),
      sendProductInfoRules: {
        expressType: [{ required: true, message: '请选择', trigger: 'change' }],
        expressNumber: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      provincesSchoolList: [],
      queryForm: {
        userName: '',
        activityType: this.$chnEngStatusCode.activityType,
        orderSource: '',
        orderStatus: '',
        season: '',
        phone: '',
        pageNum: 1,
        pageSize: 10
      },
      total: 0
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 获取订单列表
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      getTbOrderList(this.queryForm).then((res) => {
        console.log('getTbOrderList', res)
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      console.log('value', value)
      if (!value) return
      this.provincesSchoolList = []
      this.queryForm.schoolId = ''
      this.getSchoolListData(value)
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        console.log('getSchoolList', res)
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    // 查看订单详情
    showProductDetailDialog (data) {
      this.$router.push(
        {
          path: '/product_Management/product_detail_management',
          query: {
            order_no: data.orderNo
          }
        }
      )
    },
    // 显示填写快递信息弹窗
    showSendProductDetailDialogClicked (rowData) {
      console.log('rowData', rowData)
      this.sendProductForm.userId = rowData.userId
      this.sendProductForm.orderNo = rowData.orderNo
      this.showSendProductDetailDialogVisible = true
    },
    // 提交快递信息
    subSendProductMessage () {
      fillInTheTrackingNumber(this.sendProductForm).then((res) => {
        console.log('fillInTheTrackingNumber', res)
        this.showSendProductDetailDialogVisible = false
        this.$message.success('操作成功')
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 确认收货
    confirmReceiptGoods (rowData) {
      this.$confirm('是否确认用户已收到货物。', '提示', {
        confirmButtonText: '确认收到',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var formData = {
          orderNo: rowData.orderNo
        }
        executionOrderCompletion(formData).then((res) => {
          console.log('executionOrderCompletion', res)
          this.$message.success('操作成功')
          this.getDataList()
        }).catch((err) => {
          console.log('err', err)
        })
        // 确认收货
      }).catch(() => {
      })
    },
    showSendProductDetailDialogClose () {
      this.$refs.sendProductFormRef.resetFields()
    },
    // 导出订单
    exportOrderList () {
      var downLoadUrl = env.baseIP + 'order/excelTbOrderList'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, this.queryForm)
    },
    sourceFormatter (data) {
      var temp = '未知'
      for (const key in this.order_source) {
        if (Object.hasOwnProperty.call(this.order_source, key)) {
          const element = this.order_source[key]
          if (element.dictValue === data.orderSource + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    statusFormatter (data) {
      var temp = '未知'
      for (const key in this.order_status) {
        if (Object.hasOwnProperty.call(this.order_status, key)) {
          const element = this.order_status[key]
          if (element.dictValue === data.orderStatus + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style>
</style>
