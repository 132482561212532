<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>B端数据管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row :gutter="16">
      <el-col :span="12">
      <!-- 数据管理 -->
      <el-card style="margin-top: 15px">
        <el-row class="title_row">
          <el-col>
            <span class="title_class">数据管理</span>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top: 34px">
          <el-col
            :span="8"
            v-for="(item, i) of dataManagementList"
            v-bind:key="i"
          >
            <div
              class="racing-tool-back"
              @click="racingToolClicked(item.path)"
            >
              <div
                class="racing-tool-leftimg"
                :class="'coloricon' + item.menuTag"
              >
                <i class="icon iconfont" :class="item.icon"></i>
              </div>
              <div class="racing-tool-right">
                <div>{{ item.rightTitle }}</div>
                <div class="racing-tool-desc">{{ item.rightDesc }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
      </el-col>
      <el-col :span="12">
      <!-- 待处理事务 -->
        <el-card style="margin-top: 15px">
          <el-row class="title_row">
            <el-col>
              <span class="title_class">待处理事务</span>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col
              :span="10"
              v-for="(item, i) of processedList"
              v-bind:key="i"
            >
              <div class="processed-list-con" @click="racingToolClicked(item.path)">
                <!-- <el-image
                  class="processed-image"
                  :src="item.iconImg"
                  fit="contain"
                ></el-image> -->
                <div class="processed-image" :class="'coloricon' + item.menuTag">
                  <i class="icon iconfont" :class="item.icon"></i>
                </div>
                <div class="processed-count">
                  <div class="processed-desc">{{ item.number }}</div>
                  <div class="processed-title">{{ item.title }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="16">
      <!-- 数据统计 -->
      <el-col :span="24">
        <el-card style="margin-top: 15px">
          <el-row class="title_row">
            <el-col>
              <span class="title_class">数据统计</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col
          :span="4"
          v-for="(item, i) of dataStatisticsList"
          v-bind:key="i"
        >
          <div class="processed-list-con" @click="racingToolClicked(item.path)">
            <div class="processed-image processed-image-big" :class="'coloricon' + item.menuTag">
              <i class="icon iconfont" :class="item.icon"></i>
            </div>
            <div class="processed-count">
              <div class="processed-desc">{{ item.number }}</div>
              <div class="processed-title">{{ item.title }}</div>
            </div>
          </div>
        </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- <el-card style="margin-top: 15px">
      <el-row>
        <el-col :span="8">
          <el-row class="title_row">
            <el-col>
              <span class="title_class">实时情况</span>
            </el-col>
          </el-row>
          <div class="real-time-backview">
            <div
              class="real-time-item"
              v-for="(item, index) in realTimeDataList"
              v-bind:key="index"
            >
              <div class="real-time-title">{{ item.realTimeTitle }}</div>
              <div class="real-time-desc">{{ item.realTimeDesc }}<span>所</span></div>
            </div>
          </div>
        </el-col>
        <el-col :span="16">
          <chinaMap
            style="width: 800px; height: 500px; margin-top: 40px"
          ></chinaMap>
        </el-col>
      </el-row>
    </el-card> -->
    <!-- <el-tabs
      type="border-card"
      style="margin-top: 15px"
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane label="直营赛区" name="first">
        <directCompetitionArea
          style="width: 100%"
          ref="directRef"
        ></directCompetitionArea>
      </el-tab-pane>
      <el-tab-pane label="授权赛区" name="second">
        <authCompetitionArea
          style="width: 100%"
          ref="directRef"
        ></authCompetitionArea>
      </el-tab-pane>
    </el-tabs> -->
    <!-- <el-card style="margin-top: 15px"> </el-card> -->
  </div>
</template>

<script>
// import chinaMap from '@/components/bData/chinaMap/chinaMap.vue'
// import directCompetitionArea from '@/components/bData/districtData/directCompetitionArea.vue'
// import authCompetitionArea from '@/components/bData/districtData/authCompetitionArea.vue'
// import { pendingMatter } from '@/http/api'

export default {
  data () {
    return {
      activeName: 'first',
      realTimeDataList: [
        {
          realTimeTitle: '直营赛区参赛校',
          realTimeDesc: 0
        },
        {
          realTimeTitle: '授权赛区参赛校',
          realTimeDesc: 0
        }, {
          realTimeTitle: '直营赛区机构',
          realTimeDesc: 0
        }, {
          realTimeTitle: '授权赛区机构',
          realTimeDesc: 0
        }
      ],
      dataManagementList: [
        {
          icon: 'icon-xuexiao_xuexiaoxinxi',
          rightTitle: '学校管理',
          rightDesc: '可新增学校，修改学校信息',
          path: '/system_data_Management/system_school_infomation',
          menuTag: '1'
        },
        {
          icon: 'icon-saichejingsu',
          rightTitle: '赛区管理',
          rightDesc: '可新增直营赛区，授权赛区',
          path: '/system_data_Management/system_competition_management',
          menuTag: '2'
        },
        {
          icon: 'icon-tuandui',
          rightTitle: '俱乐部管理',
          rightDesc: '俱乐部、战队管理',
          path: '/system_data_Management/school_club',
          menuTag: '3'
        }
        // {
        //   icon: 'icon-jigou',
        //   rightTitle: '机构管理',
        //   rightDesc: '可新增、修改、审核机构信息',
        //   path: '',
        //   menuTag: '3'
        // }
      ],
      processedList: [
        {
          icon: 'icon-tuandui',
          title: '待审核学校',
          key: 'schoolToBeAudited',
          number: 0,
          path: '/b_data_Management/b_school_management?tab=1',
          menuTag: '7'
        }
        // {
        //   icon: 'icon-jigou',
        //   title: '待审核机构',
        //   number: 0,
        //   menuTag: '2'
        // }
      ],
      dataStatisticsList: [
        {
          icon: 'icon-tuandui',
          title: '已创建俱乐部',
          number: 0,
          color: '#57a3f7',
          menuTag: '9',
          path: '/b_data_Management/b_school_management?tab=0'
        },
        {
          icon: 'icon-xuexiao',
          title: '已参与学校',
          number: 4,
          color: '#fb6260',
          menuTag: '8'
          // path: '/b_data_Management/b_school_management'
        },
        {
          icon: 'icon-xuexiao_xuexiaoxinxi',
          title: '系统学校',
          number: 1492,
          color: '#8167f5',
          menuTag: '7',
          path: '/system_data_Management/system_school_infomation'
        },
        {
          icon: 'icon-renzhengyonghu',
          title: '已注册战队',
          number: 0,
          color: '#8167f5',
          menuTag: '6'
        }
        // {
        //   icon: 'icon-jiaofei',
        //   title: '已缴费战队',
        //   number: 0,
        //   color: '#8167f5',
        //   menuTag: '5'
        // }
      ]
    }
  },
  // components: {
  //   chinaMap,
  //   directCompetitionArea,
  //   authCompetitionArea
  // },
  created () {
    this.getData()
  },
  methods: {
    handleClick (tab, event) {
      if (tab._props.name === 'first') {
        // console.log('11111')
        this.$refs.directRef.initCharts()
      } else {
        // console.log('22222')
        this.$refs.directRef.initCharts()
      }
    },
    racingToolClicked (path) {
      if (path) {
        this.$router.push({ path })
      }
    },
    getData () {
      // 待处理事务
      // pendingMatter({ activityType: this.$chnEngStatusCode.activityType }).then(res => {
      //   if (res.code === 200) {
      //     Object.keys(res.data).forEach(key => {
      //       this.processedList.find(item => item.key === key).number = res.data[key]
      //     })
      //   }
      // })
    }
  }
}
</script>

<style lang="less" scoped>
.real-time-backview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .real-time-item {
    width: 50%;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    .real-time-title {
      width: 100%;
      font-size: 14px;
      color: #333;
      margin-bottom: 8px;
    }
    .real-time-desc {
      width: 100%;
      font-size: 24px;
      color: #1e96f1;
      span{
        font-size: 14px;
        color: #333;
      }
    }
  }
}
.racing-tool-back {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

// .racing-tool-leftimg {
//   width: 50px;
//   height: 60px;
//   margin-right: 5px;
// }

// .racing-tool-right {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   font-size: 16px;
//   color: rgb(107, 107, 107);
// }
// .data-tool-leftimg {
//   width: 60px;
//   height: 60px;
//   border-radius: 10px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-right: 5px;
// }
// .data-tool-img {
//   width: 35px;
//   height: 35px;
//   filter: grayscale(100%) brightness(0%) invert(100%);
// }

// .racing-tool-desc {
//   font-size: 12px;
// }
.redcolor {
  color: red;
}
.data-statistics-title {
  font-size: 14px;
  color: rgb(107, 107, 107);
}
.data-statistics-desc {
  font-size: 18px;
  color: rgb(65, 65, 65);
  font-weight: 900;
}
.processed-list-con{
  height: 138px;
}
</style>
