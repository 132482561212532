<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统学校信息管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统学校信息详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-tabs type="border-card" @tab-click="handleClick">
      <el-tab-pane label="基本信息">
        <!-- 基本信息 -->
        <schoolDetailInfo></schoolDetailInfo>
      </el-tab-pane>
      <el-tab-pane label="社团管理" v-if="changeType === 'change'">
        <associationDetailInfo
          ref="schoolDetailInfoRef"
        ></associationDetailInfo>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import schoolDetailInfo from '@/components/school_detailComponents/school_detail_info'
import associationDetailInfo from '@/components/school_detailComponents/association_detail_info'
export default {
  data () {
    return {
      changeType: this.$route.query.changeType
    }
  },
  components: {
    schoolDetailInfo,
    associationDetailInfo
  },
  created () {
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    handleClick (tab, event) {
      console.log(tab, event)
      this.$refs.schoolDetailInfoRef.getSchoolTeacherList()
    }
  }
}
</script>

<style>
</style>
