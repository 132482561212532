<template>
  <div>
    <div id="myChart" style="width: 600px; height: 400px"></div>
  </div>
</template>

<script>
export default {
  name: 'app',
  methods: {
    drawChart () {
      // 基于准备好的dom，初始化echarts实例【这里存在一个问题，请看到最后】
      const myChart = this.$echarts.init(document.getElementById('myChart'))
      var option = {
        title: {
          text: 'ECharts 入门示例'
        },
        tooltip: {},
        legend: {
          data: ['销量']
        },
        xAxis: {
          data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
        },
        yAxis: {},
        series: [
          {
            name: '销量',
            type: 'bar',
            data: [5, 20, 36, 10, 10, 20]
          }
        ]
      }
      myChart.setOption(option)
    }
  },
  mounted () {
    this.drawChart()
  }
}
</script>

<style>
</style>
