<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>赛务管理</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          path: '/raceManagement/individual_management/individual_manage_homeview/individual_race_management',
        }"
        >个人赛赛务工具</el-breadcrumb-item
      >
      <el-breadcrumb-item>比赛时间管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card>
      <el-tabs v-model="activeName" type="card" @tab-click="tabHandleClick">
        <el-tab-pane label="赛区管理" name="first">
          <el-card style="margin-top: 15px">
            <el-row class="title_row">
              <el-col>
                <span>赛区筛选</span>
              </el-col>
            </el-row>
            <el-form
              label-width="80px"
              ref="queryDistrictTimeFormRef"
              :model="queryDistrictTimeForm"
            >
              <el-row :gutter="10">
                <el-col :span="6">
                  <el-form-item label="赛季" prop="season">
                    <el-select
                      placeholder="默认当前赛季"
                      style="width: 100%"
                      v-model="queryDistrictTimeForm.season"
                    >
                      <el-option
                        v-for="item in dict_season"
                        :key="item.dictValue"
                        :label="item.dictName"
                        :value="item.dictValue"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="赛区" prop="districtTimeId">
                    <el-select
                      placeholder="请选择"
                      style="width: 100%"
                      v-model="queryDistrictTimeForm.districtTimeId"
                      clearable
                    >
                      <el-option
                        v-for="item in dict_district"
                        :key="item.id"
                        :label="item.districtName"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="赛程" prop="raceSchedule">
                    <el-select
                      placeholder="请选择"
                      style="width: 100%"
                      v-model="queryDistrictTimeForm.raceSchedule"
                      clearable
                    >
                      <el-option
                        v-for="item in dict_matchType"
                        :key="item.id"
                        :label="item.scheduleName"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
              <el-col :span="2">
                <el-button
                  type="primary"
                  style="width: 100%"
                  @click="getQueryDistrictTimePage()"
                  >查询</el-button
                >
              </el-col>
              <el-col :span="2">
                <el-button type="primary" style="width: 100%" @click="reset"
                  >重置</el-button
                >
              </el-col>
              <el-col :span="2">
                <el-button
                  type="primary"
                  style="width: 100%"
                  @click="addDistrictTime"
                  >新增</el-button
                >
              </el-col>
            </el-row>
          </el-card>
          <!-- 赛区列表 -->
          <el-card style="margin-top: 15px">
            <el-row class="el-row title_row" type="flex">
              <el-col :span="12">
                <span>赛区列表</span>
              </el-col>
            </el-row>
            <el-table
              header-cell-class-name="tableHeaderStyle"
              changes
              border
              :data="divisionList"
            >
              <el-table-column label="#" type="index"></el-table-column>
              <el-table-column label="赛季" prop="season"></el-table-column>
              <el-table-column
                label="赛区"
                prop="districtTimeName"
              ></el-table-column>
              <el-table-column
                label="赛程"
                prop="districtType"
                :formatter="matchTypeFormatter"
              >
              </el-table-column>
              <el-table-column label="报名开始时间" prop="signUpStartTime">
                <template slot-scope="scope">
                  {{ scope.row.signUpStartTime }}
                </template>
              </el-table-column>
              <el-table-column label="报名结束时间" prop="signUpEndTime">
                <template slot-scope="scope">
                  {{ scope.row.signUpEndTime }}
                </template>
              </el-table-column>
              <el-table-column label="比赛开始时间" prop="examinationStartTime">
                <template slot-scope="scope">
                  {{ scope.row.examinationStartTime }}
                </template></el-table-column
              >
              <el-table-column label="比赛结束时间" prop="examinationEndTime">
                <template slot-scope="scope">
                  {{ scope.row.examinationEndTime }}
                </template></el-table-column
              >
              <el-table-column label="成绩公布时间" prop="publishTime">
                <template slot-scope="scope">
                  {{ scope.row.publishTime }}
                </template></el-table-column
              >
              <el-table-column
                label="提示信息"
                prop="signUpMessage"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column label="操作" width="160px">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="showEditDistrictTimeDialog(scope.row)"
                    >修改</el-button
                  >
                  <el-button
                    type="danger"
                    size="mini"
                    @click="deleteDialog(scope.row.id)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryDistrictTimeForm.pageNum"
              :page-sizes="[10, 20, 40, 80]"
              :page-size="queryDistrictTimeForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="学校管理" name="second">
          <el-card style="margin-top: 15px">
            <el-row class="title_row">
              <el-col>
                <span>学校筛选</span>
              </el-col>
            </el-row>
            <el-form
              label-width="80px"
              ref="querySchoolTimeFormRef"
              :model="querySchoolTimeForm"
            >
              <el-row :gutter="10">
                <el-col :span="6">
                  <el-form-item label="赛季" prop="season">
                    <el-select
                      placeholder="默认当前赛季"
                      style="width: 100%"
                      v-model="querySchoolTimeForm.season"
                    >
                      <el-option
                        v-for="item in dict_season"
                        :key="item.dictValue"
                        :label="item.dictName"
                        :value="item.dictValue"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="赛区" prop="districtTimeId">
                    <el-select
                      placeholder="请选择"
                      style="width: 100%"
                      v-model="querySchoolTimeForm.districtTimeId"
                      clearable
                    >
                      <el-option
                        v-for="item in dict_district"
                        :key="item.id"
                        :label="item.districtName"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="赛程" prop="raceSchedule">
                    <el-select
                      placeholder="请选择"
                      style="width: 100%"
                      v-model="querySchoolTimeForm.raceSchedule"
                      clearable
                    >
                      <el-option
                        v-for="item in dict_matchType"
                        :key="item.id"
                        :label="item.scheduleName"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="学校名称" prop="districtTimeName">
                    <el-input
                      placeholder="请输入学校名称"
                      clearable
                      v-model="querySchoolTimeForm.districtTimeName"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
              <el-col :span="2">
                <el-button
                  type="primary"
                  style="width: 100%"
                  @click="getQueryDistrictTimePage()"
                  >查询</el-button
                >
              </el-col>
              <el-col :span="2">
                <el-button type="primary" style="width: 100%" @click="reset"
                  >重置</el-button
                >
              </el-col>
              <el-col :span="2">
                <el-button
                  type="primary"
                  style="width: 100%"
                  @click="addDistrictTime"
                  >新增</el-button
                >
              </el-col>
            </el-row>
          </el-card>
          <el-card style="margin-top: 15px">
            <el-row class="el-row" type="flex">
              <el-col :span="2">
                <span>学校列表</span>
              </el-col>
            </el-row>
            <el-table
              header-cell-class-name="tableHeaderStyle"
              changes
              border
              :data="schoolList"
            >
              <el-table-column label="#" type="index"></el-table-column>
              <el-table-column label="赛季" prop="season"></el-table-column>
              <el-table-column
                label="学校"
                prop="districtTimeName"
              ></el-table-column>
              <el-table-column
                label="赛程"
                prop="districtType"
                :formatter="matchTypeFormatter"
              >
              </el-table-column>
              <el-table-column
                label="报名开始时间"
                prop="signUpStartTime"
              ></el-table-column>
              <el-table-column
                label="报名结束时间"
                prop="signUpEndTime"
              ></el-table-column>
              <el-table-column
                label="比赛开始时间"
                prop="examinationStartTime"
              ></el-table-column>
              <el-table-column
                label="比赛结束时间"
                prop="examinationEndTime"
              ></el-table-column>
              <el-table-column
                label="成绩公布时间"
                prop="publishTime"
              ></el-table-column>
              <el-table-column
                label="提示信息"
                prop="signUpMessage"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column label="操作" width="150px">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="showEditDistrictTimeDialog(scope.row)"
                    >修改</el-button
                  >
                  <el-button
                    type="danger"
                    size="mini"
                    @click="deleteDialog(scope.row.id)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="querySchoolTimeForm.pageNum"
              :page-sizes="[10, 20, 40, 80]"
              :page-size="querySchoolTimeForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <!-- 修改时间弹窗 -->
    <el-dialog
      title="修改时间"
      :visible.sync="editDistrictTimeDialogVisible"
      width="60%"
      @close="editDistrictTimeDialogClosed"
    >
      <el-form
        label-width="110px"
        :model="editDistrictTimeForm"
        :rules="editDistrictTimeFormRules"
        ref="editDistrictTimeFormRef"
      >
        <el-row>
          <el-col :span="10">
            <el-form-item
              label="赛季"
              prop="season"
              v-if="modifyType === 'add'"
            >
              <el-select
                placeholder="请选择赛季"
                style="width: 100%"
                v-model="editDistrictTimeForm.season"
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictValue"
                  :label="item.dictName"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" v-if="modifyType === 'add'">
            <el-form-item label="赛区" prop="districtTimeId">
              <el-select
                ref="districtSelectRef"
                placeholder="请选择"
                style="width: 100%"
                v-model="editDistrictTimeForm.districtTimeId"
                clearable
              >
                <el-option
                  v-for="item in dict_district"
                  :key="item.id"
                  :label="item.districtName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10" v-if="modifyType === 'add'">
            <el-form-item label="赛程" prop="raceSchedule">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="editDistrictTimeForm.raceSchedule"
                clearable
              >
                <el-option
                  v-for="item in dict_matchType"
                  :key="item.id"
                  :label="item.scheduleName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="提示信息" prop="signUpMessage">
              <el-input
                v-model="editDistrictTimeForm.signUpMessage"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="报名起止时间" prop="signUpStartEndTime">
              <el-date-picker
                style="width: 100%"
                :default-time="['00:00:00', '23:59:59']"
                v-model="editDistrictTimeForm.signUpStartEndTime"
                type="datetimerange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                @input="signUpTimeChange"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="比赛起止时间" prop="examinationStartEndTime">
              <el-date-picker
                style="width: 100%"
                :default-time="['00:00:00', '23:59:59']"
                v-model="editDistrictTimeForm.examinationStartEndTime"
                type="datetimerange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                @input="examTimeChange"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="0">
          <el-col :span="10">
            <el-form-item label="成绩公布时间" prop="publishTime">
              <el-date-picker
                v-model="editDistrictTimeForm.publishTime"
                type="datetime"
                style="width: 100%"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker
            ></el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDistrictTimeDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="saveDistrictTime">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改当前正在进行的赛程弹窗-->
    <el-dialog
      title="修改时间"
      :visible.sync="editNowDistrictDialogVisible"
      width="40%"
      @close="editNowDistrictFormDialogClosed"
    >
      <el-form
        label-width="110px"
        :model="editNowDistrictForm"
        :rules="editNowDistrictFormRules"
        ref="editNowDistrictFormRef"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-select
              v-model="editNowDistrictForm.id"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in divisionList"
                :key="item.id"
                :label="item.scheduleName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editNowDistrictDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="saveNowDistrictClicked"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryDistrictPage, setSeasonRaceScheduleIng, queryDistrictTimePage, saveOrUpdateDistrictTime, deleteDistrictTime } from '@/http/api'
export default {
  data () {
    return {
      editNowDistrictDialogVisible: false,
      editDistrictTimeDialogVisible: false,
      editDistrictTimeForm: {
      },

      editDistrictTimeFormRules: {
        season: [{ required: true, message: '请选择赛季', trigger: 'change' }],
        districtTimeId: [{ required: true, message: '请选择赛季', trigger: 'change' }],
        raceSchedule: [{ required: true, message: '请选择赛季', trigger: 'change' }],
        signUpStartEndTime: [{ required: true, message: '请选择报名起止时间', trigger: 'change' }],
        examinationStartEndTime: [{ required: true, message: '请选择比赛起止时间', trigger: 'change' }],
        publishTime: [{ required: true, message: '请选择成绩公布时间', trigger: 'change' }]
      },
      // 获取赛区数据
      total: 0,
      dict_district: [],
      activeName: 'first',
      queryDistrictTimeForm: {
        raceSchedule: '',
        districtTimeId: '',
        season: this.$chnEngStatusCode.defaultSeason,
        districtType: 0,
        schoolId: '',
        districtTimeName: '',
        pageNum: 1,
        pageSize: 10
      },
      querySchoolTimeForm: {
        raceSchedule: '',
        districtTimeId: '',
        season: this.$chnEngStatusCode.defaultSeason,
        districtType: 0,
        schoolId: '',
        districtTimeName: '',
        pageNum: 1,
        pageSize: 10
      },
      modifyType: '',
      editNowDistrictForm: {
        activityType: this.$chnEngStatusCode.activityType,
        season: '2022~2023',
        scheduleIng: 1,
        id: null
      },
      editNowDistrictFormRules: {
        id: [{ required: true, message: '请选择当前赛程', trigger: 'change' }]
      },
      divisionList: [],
      schoolList: [],
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value })
    }
  },
  created () {
    this.getDistrictList()
    this.dict_matchType = this.$chnEngPublicRequest.querySeasonRaceSchedule(this, {}).then(value => { this.dict_matchType = value })
    // this.getQuerySeasonRaceSchedule()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // tab卡切换点击事件
    tabHandleClick (tag) {
      this.getQueryDistrictTimePage()
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
        this.getQueryDistrictTimePage()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    handleSizeChange (newSize) {
      if (this.activeName === 'first') {
        this.queryDistrictTimeForm.pageSize = newSize
      } else {
        this.querySchoolTimeForm.pageSize = newSize
      }
      this.getQueryDistrictTimePage()
    },
    handleCurrentChange (newCurPage) {
      if (this.activeName === 'first') {
        this.queryDistrictTimeForm.pageNum = newCurPage
      } else {
        this.querySchoolTimeForm.pageNum = newCurPage
      }
      this.getQueryDistrictTimePage()
    },
    // 获取赛区、学校列表数据
    getQueryDistrictTimePage () {
      this.$refs.queryDistrictTimeFormRef.validate(async valid => {
        if (!valid) return
        let formDatas = {}
        if (this.activeName === 'first') {
          formDatas = this.queryDistrictTimeForm
          formDatas.districtType = 0
        } else {
          formDatas = this.queryDistrictTimeForm
          formDatas.districtType = 1
        }
        queryDistrictTimePage(formDatas).then((res) => {
          console.log('queryDistrictTimePage', res)
          if (this.activeName === 'first') {
            this.divisionList = res.data.list
          } else {
            this.schoolList = res.data.list
          }
          this.total = res.data.total
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 重置搜索条件
    reset () {
      if (this.activeName === 'first') {
        this.$refs.queryDistrictTimeFormRef.resetFields()
      } else {
        this.$refs.querySchoolTimeFormRef.resetFields()
      }
      this.getQueryDistrictTimePage()
    },
    // 设置当前赛程点击事件
    setNowScheduleMatchTime () {
      this.editNowDistrictDialogVisible = true
    },
    editNowDistrictFormDialogClosed () {
      this.$refs.editNowDistrictFormRef.resetFields()
    },
    // 保存当前正在进行的弹窗
    saveNowDistrictClicked () {
      this.editNowDistrictForm.season = this.queryDistrictTimeForm.season
      setSeasonRaceScheduleIng(this.editNowDistrictForm).then((res) => {
        console.log('setSeasonRaceScheduleIng', res)
        this.editNowDistrictDialogVisible = false
      }).catch((err) => {
        console.log('err1111', err)
      })
    },
    // 报名起止时间
    signUpTimeChange (e) {
      console.log('this.editDistrictTimeForm', this.editDistrictTimeForm)
      if (this.editDistrictTimeForm.signUpStartEndTime && this.editDistrictTimeForm.signUpStartEndTime.length === 2) {
        this.editDistrictTimeForm.signUpStartTime = this.editDistrictTimeForm.signUpStartEndTime[0]
        this.editDistrictTimeForm.signUpEndTime = this.editDistrictTimeForm.signUpStartEndTime[1]
      } else {
        this.editDistrictTimeForm.signUpStartEndTime = null
      }
      this.$forceUpdate()
    },
    // 比赛起止时间选择
    examTimeChange (e) {
      console.log('this.editDistrictTimeForm', this.editDistrictTimeForm)
      if (this.editDistrictTimeForm.examinationStartEndTime && this.editDistrictTimeForm.examinationStartEndTime.length === 2) {
        this.editDistrictTimeForm.examinationStartTime = this.editDistrictTimeForm.examinationStartEndTime[0]
        this.editDistrictTimeForm.examinationEndTime = this.editDistrictTimeForm.examinationStartEndTime[1]
      } else {
        this.editDistrictTimeForm.examinationStartEndTime = null
      }
      this.$forceUpdate()
    },
    // 修改时间点击事件
    showEditDistrictTimeDialog (rowData) {
      console.log('rowData', rowData)
      this.modifyType = 'change'
      this.editDistrictTimeForm = JSON.parse(JSON.stringify(rowData))
      this.editDistrictTimeForm.signUpStartEndTime = [this.editDistrictTimeForm.signUpStartTime, this.editDistrictTimeForm.signUpEndTime]
      this.editDistrictTimeForm.examinationStartEndTime = [this.editDistrictTimeForm.examinationStartTime, this.editDistrictTimeForm.examinationEndTime]
      this.editDistrictTimeDialogVisible = true
    },
    // 修改时间弹窗关闭事件
    editDistrictTimeDialogClosed () {
      this.editDistrictTimeForm = {}
      this.$refs.editDistrictTimeFormRef.resetFields()
    },
    // 修改和新增时间信息
    saveDistrictTime () {
      console.log('this.editDistrictTimeForm', this.editDistrictTimeForm)
      this.$refs.editDistrictTimeFormRef.validate(async valid => {
        if (!valid) return
        if (this.modifyType === 'add') {
          this.editDistrictTimeForm.districtTimeName = this.$refs.districtSelectRef.selectedLabel
          if (this.activeName === 'first') {
            this.editDistrictTimeForm.districtType = 0
          } else {
            this.editDistrictTimeForm.districtType = 1
          }
        }
        saveOrUpdateDistrictTime(this.editDistrictTimeForm).then((res) => {
          console.log('saveOrUpdateDistrictTime', res)
          this.editDistrictTimeDialogVisible = false
          this.getQueryDistrictTimePage()
        }).catch((err) => {
          console.log('err1111', err)
        })
      })
    },
    // 删除赛区时间
    deleteDialog (dataId) {
      this.$confirm('是否确认删除该时间。', '提示', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteDistrictTime({ id: dataId }).then((res) => {
          this.$message.success('删除成功')
          this.getQueryDistrictTimePage()
        }).catch((err) => {
          console.log('err1111', err)
        })
      }).catch(() => {
      })
    },
    // 新增赛区时间
    addDistrictTime () {
      this.modifyType = 'add'
      this.editDistrictTimeDialogVisible = true
    },
    // 比赛类型转文字
    matchTypeFormatter (data) {
      var temp = '未知'
      for (const key in this.dict_matchType) {
        if (Object.hasOwnProperty.call(this.dict_matchType, key)) {
          const element = this.dict_matchType[key]
          if (element.id === data.raceSchedule + '') {
            temp = element.scheduleName
          }
        }
      }
      return temp
    }
  }
}
</script>

<style lang="less" scoped>
.div_algin_right {
  text-align: right;
}
</style>
