<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>字典管理</el-breadcrumb-item>
      <el-breadcrumb-item>字典数据</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="12">
          <span class="title_class">字典信息</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <span class="label">字典名称：</span>
          <span class="value">{{dictName}}</span>
          <span class="label">字典类型：</span>
          <span class="value">{{dictType}}</span>
        </el-col>
        <el-col :span="4" style="text-align: right;">
          <el-button type="primary" icon="el-icon-refresh" circle @click="getList"></el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="12">
          <span class="title_class">字典信息列表</span>
        </el-col>
        <el-col :span="12" style="text-align: right;">
          <el-button type="primary" icon="el-icon-plus" @click="showDialog(null)"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <!-- 列表表格区域 -->
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="accountList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="显示值" prop="dictLabel"></el-table-column>
        <el-table-column label="传递值" prop="dictValue"></el-table-column>
        <el-table-column label="备注" prop="dictName"></el-table-column>
        <!-- <el-table-column label="状态" prop="status"></el-table-column> -->
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-button :disabled="scope.row.status === '0'"
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="showDialog(scope.row)"
            >修改</el-button>
            <!-- <el-button :disabled="scope.row.status === '0'"
              type="text"
              icon="el-icon-delete"
              size="mini"
              :class="scope.row.status === '1' ? 'del-btn' : ''"
              @click="showDeleteAccountTip(scope.row.id)"
            >删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination> -->
    </el-card>
    <el-dialog
      :title="title + '字典数据'"
      :visible.sync="visible"
      width="500px"
      :close-on-click-modal="false"
    >
    <el-form
        :model="editForm"
        label-width="100px"
        ref="editFormRef"
        :rules="editFormRules"
        style="width: 100%"
      >
        <el-form-item label="显示值" prop="dictLabel">
          <el-input
            v-model="editForm.dictLabel"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="传递值" prop="dictValue">
          <el-input
            v-model="editForm.dictValue"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="dictName">
          <el-input v-model="editForm.dictName" placeholder="请输入">
          </el-input>
        </el-form-item>
        <el-form-item label="修改权限" prop="status">
          <el-radio-group v-model="editForm.status">
            <el-radio label="1">可修改</el-radio>
            <el-radio label="0">不可修改</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="saveFn">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSysDictDataList, addSysDictTypeValue } from '@/http/api'
export default {
  name: 'account',
  data () {
    return {
      accountList: [],
      visible: false,
      title: '',
      editType: 0,
      editForm: {
        // id: 0,
        dictLabel: '',
        dictValue: '',
        dictName: '',
        status: '1'
      },
      editFormRules: {
        dictLabel: [{ required: true, message: '请输入', trigger: 'blur' }],
        dictValue: [{ required: true, message: '请输入', trigger: 'blur' }],
        dictName: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      dictName: '',
      dictType: ''
    }
  },
  created () {
    this.dictName = sessionStorage.getItem('dictName')
    this.dictType = this.$route.query.t
    this.getList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    getList () {
      getSysDictDataList({ dictType: this.dictType }).then(res => {
        this.accountList = res.data
      })
    },
    searchFn () {
      // this.queryForm.pageNum = 1
      this.getList()
    },
    resetFn () {
      this.$refs.queryFormRef.resetFields()
      this.getList()
    },
    // handleCurrentChange (e) {
    //   this.queryForm.pageNum = e
    //   this.getList()
    // },
    // handleSizeChange (e) {
    //   this.queryForm.pageSize = e
    //   this.getList()
    // },
    showDialog (item) {
      this.visible = true
      this.title = item ? '修改' : '添加'
      this.editType = item ? 1 : 0
      console.log(item)
      if (item) {
        this.editForm = {
          dictLabel: item.dictLabel,
          dictValue: item.dictValue,
          dictName: item.dictName,
          dictType: this.dictType,
          id: item.id,
          status: item.status
        }
      } else {
        this.editForm = {
          dictLabel: '',
          dictName: '',
          dictValue: '',
          dictType: this.dictType,
          // id: 0,
          status: '1'
        }
      }
    },
    showDeleteAccountTip (id) {
      this.$confirm('此操作将永久删除该字典数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(id)
        // delAccount({ id }).then(res => {
        //   this.searchFn()
        //   this.$message.success('已删除')
        // })
      })
    },
    dialogCloseFn () {
      this.visible = false
    },
    saveFn () {
      console.log(this.editForm)

      this.$refs.editFormRef.validate(valid => {
        if (!valid) return
        addSysDictTypeValue(this.editForm).then(res => {
          if (this.dictType === 't_season' && this.editType === 0) {
            this.handleToSeason(this.editForm.dictValue)
          } else {
            this.$message.success(`${this.title}成功`)
          }
          if (this.editType) {
            this.getList()
          } else {
            this.searchFn()
          }
          this.visible = false
        })
      })
    },
    handleToSeason (season) {
      this.$confirm('去赛季管理添加？', '字典添加完成', {
        confirmButtonText: '去添加',
        cancelButtonText: '稍后',
        type: 'success'
      }).then(async () => {
        this.$router.push(`/system/season?season=${season}`)
      }).catch(() => { })
    }
  }
}

</script>
<style scoped lang="less">
.label{
  color: #606266;
  font-size: 14px;
  font-weight: bold;
}
.value{
  font-size: 14px;
  margin-right: 60px;
}

.del-btn{
  color: rgb(245, 108, 108);
}
</style>
