<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>课程订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>开通课程</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>开通信息设置</span>
        </el-col>
      </el-row>
      <el-form
        :model="editInfoForm"
        label-width="120px"
        ref="editInfoFormRef"
        :rules="editInfoRules"
        style="width: 100%"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择"
                v-model="editInfoForm.season"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="学校省市区" prop="schoolArea">
              <el-cascader
                placeholder="请选择"
                clearable
                v-model="editInfoForm.schoolArea"
                :options="areasData"
                style="width: 100%"
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'id',
                  children: 'areas',
                }"
                @change="provincesHandleChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="学校" prop="schoolId">
              <el-select
                ref="schoolSelectRef"
                style="width: 100%"
                placeholder="请选择"
                v-model="editInfoForm.schoolId"
                @change="schoolSelectClicked"
                clearable
                filterable
              >
                <el-option
                  v-for="item in provincesSchoolList"
                  :key="item.id"
                  :label="item.schoolName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="开通方式" prop="openType">
              <el-select
                placeholder="请选择"
                v-model="editInfoForm.openType"
                style="width: 100%"
              >
                <el-option
                  v-for="item in p_open_type"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="是否试看" prop="isFree">
              <el-select
                placeholder="请选择"
                v-model="editInfoForm.isFree"
                style="width: 100%"
              >
                <el-option
                  v-for="item in p_open_isFree"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="8" v-if="openTurnType === 'teacher'">
            <el-form-item label="开通社团老师" prop="userId">
              <el-select
                placeholder="请选择"
                v-model="editInfoForm.userId"
                style="width: 100%"
                @change="teacherSelectClicked"
                clearable
                filterable
              >
                <el-option
                  v-for="item in teacherList"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="editInfoForm.remark"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开通天数" prop="openDays">
              <el-input
                v-model.number="editInfoForm.openDays"
                placeholder="请输入(不填写默认取课程有效期)"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="课程分类" prop="category">
              <el-select
                v-model="editInfoForm.category"
                style="width: 100%"
                ref="categoryRef"
                @change="categoryChange"
                clearable
              >
                <el-option
                  v-for="(item, index) in dict_category"
                  :key="item.id"
                  :label="item.typeName"
                  :value="item.id"
                  :index="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="课程主题" prop="subCategory">
              <el-select
                v-model="editInfoForm.subCategory"
                style="width: 100%"
                @change="subCategoryChange"
                clearable
              >
                <el-option
                  v-for="item in area_category"
                  :key="item.id"
                  :label="item.typeName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label="主题:" prop="typeId">
              <el-cascader
                style="width: 250px;"
                :options="dict_category"
                v-model="typeId"
                clearable
                :props="{
                  label: 'typeName',
                  value: 'id',
                  children: 'childrenCourseType',
                  checkStrictly: true
                }"
                @change="courseTypeArrayChange(1)"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="区域分类" prop="courseTypeId">
              <el-cascader
                style="width: 250px;"
                :options="area_category"
                v-model="courseTypeId"
                clearable
                :props="{
                  label: 'typeName',
                  value: 'id',
                  children: 'childrenCourseType',
                  checkStrictly: true
                }"
                @change="courseTypeArrayChange(2)"
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getCourseList()"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row> -->
    </el-card>
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="15">
          <span class="title-class">课程信息列表</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        @selection-change="handleSelectionChange"
        :data="dataList"
        :row-key="getRowKey"
        border
        stripe
        v-if="openTurnType === 'school'"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
        <el-table-column label="主题" prop="typeIdStr" width="200px">
          <template slot-scope="scope">
            <el-tag size="mini" type="warning">{{ scope.row.typeIdStr }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="区域" prop="courseTypeIdStr" width="220px">
          <template slot-scope="scope">
            <el-tag size="mini" v-for="(tag, i) of scope.row.courseTypeArr" :key="i">{{ tag }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="课程名称" prop="productName"></el-table-column>
        <el-table-column label="价格" prop="price" width="120px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isFree === 0 || scope.row.isFree === 2" :type="scope.row.isFree === 0 ? 'success' : scope.row.isFree === 2 ? 'danger' : ''" size="mini">
              {{ scope.row.isFree === 0 ? '试看' : scope.row.isFree === 2 ? '特惠' : '' }}
            </el-tag>
            <span style="color: red; font-size: 14px;" v-if="scope.row.price && scope.row.isFree"> ￥{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column label="有效天数" prop="days">
          <template slot-scope="scope">
            <span>{{
              scope.row.days
                ? scope.row.days == "9999"
                  ? "永久有效"
                  : scope.row.days
                : "本赛季有效"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="有效类型"
          prop="handType"
          :formatter="handTypeFormatter"
        ></el-table-column>
        <el-table-column label="到期时间" prop="expiryDate"></el-table-column>
        <el-table-column label="开通时间" prop="createTime"></el-table-column>
      </el-table>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        @selection-change="handleSelectionChange"
        :data="dataList"
        :row-key="getRowKey"
        border
        stripe
        v-else
      >
        <el-table-column type="selection" width="55" :reserve-selection="true">
        </el-table-column>
        <el-table-column label="课程名称" prop="courseName"></el-table-column>
        <el-table-column label="有效天数" prop="days">
          <template slot-scope="scope">
            <span>{{
              scope.row.days
                ? scope.row.days == "9999"
                  ? "永久有效"
                  : scope.row.days
                : "本赛季有效"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="有效类型"
          prop="handType"
          :formatter="handTypeFormatter"
        ></el-table-column>
        <!-- <el-table-column label="有效期类型">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.handType === 6" type="success">试看</el-tag>
            <el-tag v-else-if="scope.row.handType === 1" type="warning"
              >有效天数</el-tag
            >
            <el-tag v-else type="danger">到期时间</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="有效天数" prop="days">
          <template slot-scope="scope">
            <span style="color: #409eff">{{ scope.row.days }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="过期时间" prop="endTime"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="editInfoForm.pageNum"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="editInfoForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <!-- <el-col :span="15">
          <span class="title-class">已选课程列表</span>
        </el-col>
        <el-col :span="9">
          <div class="div_algin_right">
            <el-button type="primary" @click="openCoursesInfoDetail"
              >开 通</el-button
            >
          </div>
        </el-col> -->
        <el-col :span="15">
          <span class="title-class">已选课程列表</span>
        </el-col>
        <el-col :span="9">
          <div class="div_algin_right">
            <el-button type="primary" @click="openCoursesInfoDetail()"
              >开 通</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="selectDataList"
        v-if="openTurnType === 'school'"
        border
      >
      <el-table-column label="主题" prop="typeIdStr" width="200px">
          <template slot-scope="scope">
            <el-tag size="mini" type="warning">{{ scope.row.typeIdStr }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="区域" prop="courseTypeIdStr" width="220px">
          <template slot-scope="scope">
            <el-tag size="mini" v-for="(tag, i) of scope.row.courseTypeArr" :key="i">{{ tag }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="课程名称" prop="productName"></el-table-column>
        <el-table-column label="价格" prop="price" width="120px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isFree === 0 || scope.row.isFree === 2" :type="scope.row.isFree === 0 ? 'success' : scope.row.isFree === 2 ? 'danger' : ''" size="mini">
              {{ scope.row.isFree === 0 ? '试看' : scope.row.isFree === 2 ? '特惠' : '' }}
            </el-tag>
            <span style="color: red; font-size: 14px;" v-if="scope.row.price && scope.row.isFree"> ￥{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column label="有效天数" prop="days">
          <template slot-scope="scope">
            <span>{{
              scope.row.days
                ? scope.row.days == "9999"
                  ? "永久有效"
                  : scope.row.days
                : "本赛季有效"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="有效类型"
          prop="handType"
          :formatter="handTypeFormatter"
        ></el-table-column>
        <el-table-column label="到期时间" prop="expiryDate"></el-table-column>
        <el-table-column label="开通时间" prop="createTime"></el-table-column>
      </el-table>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="selectDataList"
        v-else
        border
      >
        <el-table-column label="课程名称" prop="courseName"></el-table-column>
        <el-table-column label="有效期类型">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.handType === 6" type="success">试看</el-tag>
            <el-tag v-else-if="scope.row.handType === 1" type="warning"
              >有效天数</el-tag
            >
            <el-tag v-else type="danger">到期时间</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="有效天数" prop="days">
          <template slot-scope="scope">
            <span style="color: #409eff">{{ scope.row.days ? scope.row.days : '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="过期时间" prop="endTime"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import {
  getSysUserInfo,
  secondaryLinkageCourseQuery,
  getTbProductListInfoPage,
  getSchoolListWithCity,
  queryProductSchoolList,
  getSchoolTeacherList,
  addTbProductSchoolList,
  addOpenProductList
} from '@/http/api'
import areasData from '@/assets/area.json'
export default {
  data () {
    return {
      provincesSchoolList: [],
      areasData: areasData.areas,
      dict_handType: this.$userInfo.getDataList(this, 'p_hand_type').then(value => { this.dict_handType = value }),
      isExpired: 0,
      editInfoDialogVisible: false,
      dataList: [],
      selectDataList: [],
      dict_category: [],
      area_category: [],
      teacherList: [],
      total: 0,
      openTurnType: this.$route.query.openTurnType,
      userInfoData: {},
      userInfoForm: {
        phone: ''
      },
      courseQueryForm: {
        activityType: this.$chnEngStatusCode.activityCourseType,
        pageNum: 1,
        pageSize: 10,
        isFree: 1,
        season: this.$route.query.season,
        productList: [],
        courseTypeId: '',
        typeId: ''
      },
      courseTypeId: '',
      typeId: '',
      editInfoForm: {
        activityType: this.$chnEngStatusCode.activityCourseType,
        season: this.$route.query.season,
        isFree: 1,
        beginTime: null,
        endTime: null,
        openType: null,
        openDays: null,
        remark: '',
        userId: ''
      },
      userInfoRules: {
        phone: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      editInfoRules: {
        schoolArea: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolId: [{ required: true, message: '请选择', trigger: 'change' }],
        selectType: [{ required: true, message: '请选择', trigger: 'change' }],
        season: [{ required: true, message: '请选择', trigger: 'change' }],
        openType: [{ required: true, message: '请选择', trigger: 'change' }],
        remark: [{ required: true, message: '请输入', trigger: 'blur' }],
        // isFree: [{ required: true, message: '请选择', trigger: 'change' }],
        userId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      p_open_isFree: this.$userInfo.p_open_isFree(),
      dict_group: this.$userInfo.dict_group(),
      p_select_type: this.$userInfo.dict_selectType(),
      dict_courseType: this.$userInfo.dict_courseType(),
      dict_season: this.$userInfo
        .getDataList(this, 't_season')
        .then((value) => {
          this.dict_season = value
        }),
      p_open_type: this.$userInfo
        .getDataList(this, 'p_open_type')
        .then((value) => {
          this.p_open_type = value
        }),
      p_word_product_type: this.$userInfo
        .getDataList(this, 'p_word_product_type')
        .then((value) => {
          this.p_word_product_type = value
        }),
      addTimepickerOptionsStart: {},
      addTimepickerOptionsEnd: {}
    }
  },
  created () {
    this.getSecondaryLinkageCourseQuery(1)
    this.getSecondaryLinkageCourseQuery(2)
    // this.getCourseList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },

    openTypeFormatter (data) {
      var temp = '未知'
      for (const key in this.p_open_type) {
        if (Object.hasOwnProperty.call(this.p_open_type, key)) {
          const element = this.p_open_type[key]
          if (element.dictValue === data.openType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // 修改开始时间的判断
    addBeginTimeChangeStart () {
      if (this.editInfoForm.endTime) {
        if (this.editInfoForm.endTime <= this.editInfoForm.beginTime) {
          this.$message.warning('开始时间必须小于结束时间！')
          this.editInfoForm.beginTime = ''
        }
      }
      this.addTimepickerOptionsEnd = Object.assign(
        {},
        this.addTimepickerOptionsEnd,
        {
          disabledDate: (time) => {
            if (this.editInfoForm.beginTime) {
              return (
                new Date(time).getTime() <
                new Date(this.editInfoForm.beginTime).getTime()
              )
            }
          }
        }
      )
    },
    // 修改结束时间的判断
    addEndTimeChangeEnd () {
      if (this.editInfoForm.beginTime) {
        if (this.editInfoForm.endTime <= this.editInfoForm.beginTime) {
          this.$message.warning('结束必须大于开始时间！')
          this.editInfoForm.endTime = ''
        }
      }
      this.addTimepickerOptionsStart = Object.assign(
        {},
        this.addTimepickerOptionsStart,
        {
          disabledDate: (time) => {
            if (this.editInfoForm.endTime) {
              return (
                new Date(time).getTime() >
                new Date(this.editInfoForm.endTime).getTime()
              )
            }
          }
        }
      )
    },
    // 获取用户详情
    getUserInfoDetail () {
      console.log('this.editInfoForm', this.editInfoForm)
      getSysUserInfo({ phone: this.userInfoForm.phone })
        .then((res) => {
          console.log('getSysUserInfo', res)
          this.userInfoData = res.data
          this.editInfoForm.userId = res.data.id
          this.editInfoForm.phone = this.userInfoForm.phone
          this.editInfoForm.openLevel = res.data.level
          this.editInfoForm.grade = res.data.grade
        })
        .catch((err) => {
          console.log('返回的err', err)
        })
    },
    subTypeCategoryFormatter (data) {
      var temp = '-'
      for (const key in this.dict_courseType) {
        if (Object.hasOwnProperty.call(this.dict_courseType, key)) {
          const element = this.dict_courseType[key]
          if (element.dictValue === data.subTypeCategory) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      var temp = '未知'
      for (const key in this.dict_group) {
        if (Object.hasOwnProperty.call(this.dict_group, key)) {
          const element = this.dict_group[key]
          if (element.dictValue === data.openLevel + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    categoryFormatter (data) {
      var temp = '-'
      for (const key in this.dict_category) {
        if (Object.hasOwnProperty.call(this.dict_category, key)) {
          const element = this.dict_category[key]
          if (element.id === data.category) {
            temp = element.typeName
          }
        }
      }
      return temp
    },
    handTypeFormatter (data) {
      var temp = '-'
      for (const key in this.dict_handType) {
        if (Object.hasOwnProperty.call(this.dict_handType, key)) {
          const element = this.dict_handType[key]
          if (element.dictValue === data.handType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      if (!value) return
      if (this.editInfoForm.schoolId) {
        this.editInfoForm.schoolId = null
      }
      if (this.editInfoForm.userId) {
        this.editInfoForm.userId = ''
      }
      this.getSchoolListData(value)
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        console.log('getSchoolList', res)
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 根据学校获取学校老师
    schoolSelectClicked (value) {
      if (!value) return
      if (this.editInfoForm.userId) {
        this.editInfoForm.userId = ''
      }
      if (this.openTurnType === 'teacher') {
        this.courseQueryForm.schoolId = value
      }
      getSchoolTeacherList({ schoolId: value, roleId: this.$chnEngStatusCode.ADMINISTRATOR_ASSOCIATION }).then((res) => {
        console.log('getSchoolTeacherList', res)
        this.teacherList = res.data
        if (this.openTurnType === 'teacher') {
          this.getCourseList()
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 弹窗关闭
    editInfoDialogClose () {
      this.$refs.userInfoFormRef.resetFields()
      this.$refs.editInfoFormRef.resetFields()
    },
    // 获取课程分类二级菜单
    // getSecondaryLinkageCourseQuery () {
    //   secondaryLinkageCourseQuery().then((res) => {
    //     this.dict_category = res.data
    //     if (this.openTurnType === 'school') {
    //       this.getCourseList()
    //     }
    //   }).catch((err) => {
    //     console.log('返回的err', err)
    //   })
    // },
    // 获取课程分类二级菜单
    getSecondaryLinkageCourseQuery (type) {
      secondaryLinkageCourseQuery({ type }).then((res) => {
        console.log('res', res)
        if (type === 1) {
          this.dict_category = res.data
        } else if (type === 2) {
          this.area_category = res.data
        }
        if (this.openTurnType === 'school') {
          this.getCourseList()
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 课程分类选择
    categoryChange (value) {
      this.area_category = this.dict_category[this.$refs.categoryRef.hoverIndex].childrenCourseType
      this.editInfoForm.subCategory = null
      this.getCourseList()
    },
    //  课程主题选择
    subCategoryChange (value) {
      console.log('value', value)
      this.getCourseList()
    },
    handleSizeChange (newSize) {
      this.courseQueryForm.pageSize = newSize
      this.getCourseList()
    },
    handleCurrentChange (newCurPage) {
      this.courseQueryForm.pageNum = newCurPage
      this.getCourseList()
    },
    // 获取主题下的课程列表
    getCourseList () {
      this.courseQueryForm.subCategory = this.editInfoForm.subCategory
      this.courseQueryForm.category = this.editInfoForm.category
      if (this.openTurnType === 'school') {
        // 获取课程列表
        getTbProductListInfoPage(this.courseQueryForm).then((res) => {
          res.data.list.forEach(item => {
            item.courseTypeArr = item.courseTypeIdStr ? item.courseTypeIdStr.split(',') : []
          })
          this.dataList = res.data.list
          this.total = res.data.total
        }).catch((err) => {
          console.log('返回的err', err)
        })
      } else {
        this.getCourseListWithSchool()
      }
    },
    // 获取学校下的课程列表
    getCourseListWithSchool () {
      this.courseQueryForm.season = this.editInfoForm.season
      if (!this.courseQueryForm.schoolId || this.courseQueryForm.schoolId === '') {
        return this.$message.error('请先选择学校！')
      }
      queryProductSchoolList(this.courseQueryForm).then((res) => {
        console.log('res', res)
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        this.dataList = []
        this.total = 0
        console.log('返回的err', err)
      })
    },
    // 课程多选回调
    handleSelectionChange (val) {
      console.log('val', val)
      var productDataList = []
      for (let index = 0; index < val.length; index++) {
        const element = val[index]
        productDataList.push(element)
      }
      this.editInfoForm.productList = productDataList
      this.selectDataList = productDataList
    },
    // 教师选择回调
    teacherSelectClicked (value) {
      this.editInfoForm.userId = value
    },
    getRowKey (row) {
      return row.id
    },
    // 开通课程
    openCoursesInfoDetail () {
      this.$refs.editInfoFormRef.validate(async (valid) => {
        if (!valid) return
        if (!this.editInfoForm.productList || this.editInfoForm.productList.length === 0) {
          this.$message.error('请先选择要开通的课程！')
          return
        }
        if (this.editInfoForm.openDays == null || this.editInfoForm.openDays === '') {
          this.editInfoForm.type = this.$chnEngStatusCode.OPEN_TYPE_DEFAULT
        } else {
          this.editInfoForm.type = this.$chnEngStatusCode.OPEN_TYPE_DAYS
        }
        if (this.openTurnType === 'school') {
          this.schoolOpenCourseRequest()
        } else {
          this.teacherOpenCourseRequest()
        }
      })
    },
    // 学校开通课程
    schoolOpenCourseRequest () {
      addTbProductSchoolList(this.editInfoForm)
        .then((res) => {
          console.log('addTbProductSchoolList', res)
          this.$message.success('审核通过！')
          this.goBack()
        })
        .catch((err) => {
          console.log('返回的err', err)
        })
    },
    // 教师开通课程
    teacherOpenCourseRequest () {
      if (!this.editInfoForm.userId || this.editInfoForm.userId === '') {
        this.$message.error('请先选择要开通的教师！')
        return
      }
      addOpenProductList(this.editInfoForm)
        .then((res) => {
          console.log('addOpenProductList', res)
          this.$message.success('审核通过！')
          this.goBack()
        })
        .catch((err) => {
          console.log('返回的err', err)
        })
    },
    courseTypeArrayChange (type) {
      if (type === 1) {
        this.courseQueryForm.typeId = this.typeId[this.typeId.length - 1]
      } else if (type === 2) {
        this.courseQueryForm.courseTypeId = this.courseTypeId[this.courseTypeId.length - 1]
      }
      this.getCourseList()
    }
  }
}
</script>

<style lang="less" scoped>
.div_algin_right {
  text-align: right;
}
</style>
