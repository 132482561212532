<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>C端数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户注册信息</el-breadcrumb-item>
      <el-breadcrumb-item>注册信息详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card style="margin-top: 15px">
      <el-descriptions title="用户信息" border>
        <el-descriptions-item label="手机号">{{
          userInfo.phone
        }}</el-descriptions-item>
        <el-descriptions-item label="用户名">{{
          userInfo.userName
        }}</el-descriptions-item>
        <el-descriptions-item label="性别">
          <template>
            <el-tag type="success" v-if="userInfo.sex === '1'" size="small"
              >男</el-tag
            >
            <el-tag type="danger" v-else-if="userInfo.sex === '0'" size="small"
              >女</el-tag
            >
            <span v-else>-</span>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="国籍">{{
          userInfo.nationality ? userInfo.nationality : '-'
        }}</el-descriptions-item>
        <el-descriptions-item label="证件类型">
          <template>
            <el-tag type="danger" v-if="userInfo.cardType === '1'" size="mini"
              >身份证</el-tag
            >
            <el-tag
              type="success"
              v-else-if="userInfo.cardType === '2'"
              size="mini"
              >护照</el-tag
            >
            <span v-else>-</span>
          </template></el-descriptions-item
        >
        <el-descriptions-item label="证件号">{{
          userInfo.idCard
        }}</el-descriptions-item>
        <el-descriptions-item label="邮箱">{{
          userInfo.email
        }}</el-descriptions-item>
        <el-descriptions-item label="学校省市区"
          >{{ userInfo.province }}/{{ userInfo.city }}/{{
            userInfo.area
          }}</el-descriptions-item
        >
        <el-descriptions-item label="学校名">{{
          userInfo.schoolName
        }}</el-descriptions-item>
        <el-descriptions-item label="年级"
          >{{ userInfo.grade }}年级</el-descriptions-item
        >
      </el-descriptions>
    </el-card>
    <!-- 信息列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="title_class">地址信息</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="userInfoAddressList"
        border
        stripe
      >
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="收件人姓名" prop="receiptName"></el-table-column>
        <el-table-column label="收件人手机号" prop="receiptPhone"></el-table-column>
        <el-table-column label="省市区" >
          <template slot-scope="scope">
            <span>{{scope.row.province}}-{{scope.row.city}}-{{scope.row.area}}</span>
          </template>
        </el-table-column>
        <el-table-column label="详细地址" prop="receiptAddress"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { getSysUserInfo, getSysUserAddressListByUserId } from '@/http/api'
export default {
  data () {
    return {
      userInfo: {},
      userInfoAddressList: [],
      userId: this.$route.query.userId
    }
  },
  created () {
    this.getUserInfoData()
    this.getUserInfoAddressList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取用户信息详情
    getUserInfoData () {
      getSysUserInfo({ userId: this.userId }).then((res) => {
        console.log('getSysUserInfo', res)
        this.userInfo = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取用户地址列表
    getUserInfoAddressList () {
      getSysUserAddressListByUserId({ userId: this.userId }).then((res) => {
        console.log('getSysUserAddressListByUserId', res)
        this.userInfoAddressList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    }
  }
}
</script>

<style>
</style>
