<template>
  <div>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col :span="12">
          <span>报名信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="80px"
        ref="queryFormRef"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="学校" prop="schoolName">
              <el-input
                placeholder="请输入"
                clearable
                v-model="queryForm.schoolName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="组别" prop="level">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.level"
                clearable
              >
                <el-option
                  v-for="item in dict_schoolGroup"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="getData('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col :span="12">
          <span>俱乐部列表</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column
          align="center"
          label="#"
          type="index"
          width="50px"
        ></el-table-column>
        <el-table-column
          align="center"
          label="学校"
          prop="schoolName"
          width="220px"
        ></el-table-column>
        <el-table-column align="center" label="小学组">
          <el-table-column align="center" label="俱乐部">
            <template slot-scope="scope">
              <el-link
                v-if="scope.row.pclubName != '无资格'"
                type="primary"
                @click="goClubDetail(scope.row.pclubId)"
                >{{ scope.row.pclubName ? scope.row.pclubName : '-' }}</el-link
              >
              <span v-else>{{ scope.row.pclubName }}</span>
            </template></el-table-column
          >
          <el-table-column
            align="center"
            label="指导老师账号"
            prop="pteacherPhone"
          ></el-table-column>
        </el-table-column>
        <el-table-column align="center" label="初中组">
          <el-table-column align="center" label="俱乐部" prop="mclubName">
            ><template slot-scope="scope">
              <el-link
                v-if="scope.row.mclubName != '无资格'"
                type="primary"
                @click="goClubDetail(scope.row.mclubId)"
                >{{ scope.row.mclubName ? scope.row.mclubName : '-' }}</el-link
              >
              <span v-else>{{ scope.row.mclubName }}</span>
            </template></el-table-column
          >
          <el-table-column
            align="center"
            label="指导老师账号"
            prop="mteacherPhone"
          ></el-table-column>
        </el-table-column>
        <el-table-column align="center" label="高中组">
          <el-table-column align="center" label="俱乐部"
            ><template slot-scope="scope">
              <el-link
                v-if="scope.row.hclubName != '无资格'"
                type="primary"
                @click="goClubDetail(scope.row.hclubId)"
                >{{ scope.row.hclubName ? scope.row.hclubName : '-' }}</el-link
              >
              <span v-else>{{ scope.row.hclubName }}</span>
            </template></el-table-column
          >
          <el-table-column
            align="center"
            label="指导老师账号"
            prop="hteacherPhone"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="会员学校">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isvip"
              @change="vipChange(scope.row)"
              :active-value="true"
              :inactive-value="false"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              @click="showSetTeacherDialog(scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 设置指导教师的dialog -->
    <el-dialog
      title="设置指导老师"
      :visible.sync="setTeacherDialogVisible"
      width="30%"
    >
      <el-form
        :model="setTeacherForm"
        label-width="150px"
        ref="setTeacherFormRef"
        :rules="setTeacherFormRule"
      >
        <el-form-item
          label="小学组指导教师账号:"
          prop="pteacherPhone"
          v-if="setTeacherForm.haveP === true"
        >
          <el-input
            v-model="setTeacherForm.pteacherPhone"
            placeholder="请输入指导教师账号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="初中组指导教师账号:"
          prop="mteacherPhone"
          v-if="setTeacherForm.haveM === true"
        >
          <el-input
            v-model="setTeacherForm.mteacherPhone"
            placeholder="请输入指导教师账号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="高中组指导教师账号:"
          prop="hteacherPhone"
          v-if="setTeacherForm.haveH === true"
        >
          <el-input
            v-model="setTeacherForm.hteacherPhone"
            placeholder="请输入指导教师账号"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setClubTeacher()">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import areasData from '@/assets/area.json'
import { itsoClubInfoList, setSchoolMember, deleteSchoolMember, setClubTeacher } from '@/http/api'
export default {
  data () {
    return {
      dataList: [],
      areasData: areasData.areas,
      setTeacherDialogVisible: false,
      total: 0,
      dict_schoolGroup: this.$userInfo.dict_schoolGroup(),
      queryForm: {
        schoolName: '',
        pageNum: 1,
        pageSize: 10,
        level: ''
      },
      setTeacherForm: {},
      setTeacherFormRule: {
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getData()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getData()
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getData()
    },
    getData (type) {
      if (type === 'search') {
        this.queryForm.pageNum = 1
      }
      itsoClubInfoList(this.queryForm).then((res) => {
        if (res.code === 200) {
          this.dataList = res.data.list
          this.total = res.data.total
        }
      })
    },
    showSetTeacherDialog (data) {
      var haveP = true
      var haveM = true
      var haveH = true
      if (data.schoolLevel.indexOf('0') === -1 &&
        data.schoolLevel.indexOf('1') === -1 &&
        data.schoolLevel.indexOf('2') === -1) {
        haveP = false
      }
      if (data.schoolLevel.indexOf('3') === -1) {
        haveM = false
      }
      if (data.schoolLevel.indexOf('4') === -1) {
        haveH = false
      }
      this.setTeacherForm = {
        schoolId: data.id,
        pteacherPhone: data.pteacherPhone,
        mteacherPhone: data.mteacherPhone,
        hteacherPhone: data.hteacherPhone,
        haveP: haveP,
        haveM: haveM,
        haveH: haveH
      }
      this.setTeacherDialogVisible = true
    },
    setClubTeacher () {
      this.$refs.setTeacherFormRef.validate(async valid => {
        if (!valid) return
        if (this.setTeacherForm.pteacherPhone === undefined) {
          this.setTeacherForm.pteacherPhone = ''
        }
        if (this.setTeacherForm.mteacherPhone === undefined) {
          this.setTeacherForm.mteacherPhone = ''
        }
        if (this.setTeacherForm.hteacherPhone === undefined) {
          this.setTeacherForm.hteacherPhone = ''
        }
        if (this.setTeacherForm.pteacherPhone !== '' &&
         this.setTeacherForm.pteacherPhone !== '无资格' &&
          this.setTeacherForm.pteacherPhone === this.setTeacherForm.mteacherPhone) {
          return this.$message.warning('各组别指导教师账号不能重复')
        }
        if (this.setTeacherForm.pteacherPhone !== '' &&
         this.setTeacherForm.pteacherPhone !== '无资格' &&
          this.setTeacherForm.pteacherPhone === this.setTeacherForm.hteacherPhone) {
          return this.$message.warning('各组别指导教师账号不能重复')
        }
        if (this.setTeacherForm.mteacherPhone !== '' &&
         this.setTeacherForm.mteacherPhone !== '无资格' &&
          this.setTeacherForm.mteacherPhone === this.setTeacherForm.hteacherPhone) {
          return this.$message.warning('各组别指导教师账号不能重复')
        }
        setClubTeacher(this.setTeacherForm).then((res) => {
          this.setTeacherDialogVisible = false
          this.$message.success('保存成功')
          this.getClubList()
        })
      })
    },
    // 修改vip信息
    vipChange (data) {
      console.log('data_______', data)
      if (data.isvip === false) {
        deleteSchoolMember({
          id: data.id
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success('取消会员校成功')
            this.getClubList()
          }
        })
      } else {
        setSchoolMember({
          id: data.id
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success('开启会员校成功')
            this.getClubList()
          }
        })
      }
    },
    // 跳转俱乐部详情
    goClubDetail (clubId) {
      if (clubId) {
        this.$router.push(
          {
            path: '/team_management/team_manage_homeview/team_club_detailData',
            query: {
              clubId: clubId
            }
          }
        )
      } else {
        this.$message.warning('暂无该组别俱乐部信息！')
      }
    }
  }
}
</script>

<style>

</style>
