<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>字典管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card>
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="title_class">信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        label-width="80px"
        ref="queryFormRef"
      >
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="字典名称" prop="dictName">
              <el-input
                v-model="queryForm.dictName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="字典类型" prop="dictType">
              <el-input
                v-model="queryForm.dictType"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="searchFn"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="resetFn"
            >重置</el-button
          >
        </el-col>
      </el-row>
      </el-form>
    </el-card>
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="12">
          <span class="title_class">字典信息列表</span>
        </el-col>
        <el-col :span="12" style="text-align: right;">
          <el-button type="primary" @click="showDialog(null)"
            >添加字典类型</el-button
          >
        </el-col>
      </el-row>
      <!-- 列表表格区域 -->
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="accountList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="字典名称" prop="dictName"></el-table-column>
        <el-table-column label="字典类型" prop="dictType">
          <template slot-scope="scope">
            <el-button type="text" @click="handleToChild(scope.row)">{{ scope.row.dictType }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <!-- <el-table-column label="状态" prop="userStatus">
          <template slot-scope="scope">
            <el-tag :type="scope.row.userStatus === 1 ? 'success' : 'danger'" size="mini">
              {{ scope.row.userStatus === 1 ? '启用' : '禁用' }}
            </el-tag>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="showDialog(scope.row)"
            >修改</el-button>
            <!-- <el-button
              type="text"
              icon="el-icon-delete"
              size="mini"
              style="color: rgb(245, 108, 108);"
              @click="showDeleteAccountTip(scope.row.id)"
            >删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      :title="title + '字典类型'"
      :visible.sync="visible"
      width="500px"
      @close="dialogCloseFn"
    >
    <el-form
        :model="editForm"
        label-width="100px"
        ref="editFormRef"
        :rules="editFormRules"
        style="width: 100%"
      >
        <el-form-item label="字典名称" prop="dictName">
          <el-input
            v-model="editForm.dictName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="字典类型" prop="dictType">
          <el-input v-model="editForm.dictType" placeholder="请输入">
          </el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea"
            v-model="editForm.remark"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="状态" prop="userStatus">
          <el-switch v-model="editForm.userStatus"
          :active-value="1"
          :inactive-value="0"></el-switch>
          <span style="margin-left: 12px;">{{ editForm.userStatus === 1 ? '启用' : '禁用' }}</span>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="saveFn">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDictTypeList, addSysDictType } from '@/http/api'
export default {
  name: 'account',
  data () {
    return {
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        dictName: '',
        dictType: ''
      },
      total: 0,
      accountList: [],
      visible: false,
      title: '',
      editType: 0,
      editForm: {
        id: 0,
        dictName: '',
        dictType: '',
        remark: ''
      },
      editFormRules: {
        dictName: [{ required: true, message: '请输入', trigger: 'blur' }],
        dictType: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    getList () {
      getDictTypeList(this.queryForm).then(res => {
        this.accountList = res.data.list
        this.total = res.data.total
      })
    },
    searchFn () {
      this.queryForm.pageNum = 1
      this.getList()
    },
    resetFn () {
      this.$refs.queryFormRef.resetFields()
      this.getList()
    },
    handleCurrentChange (e) {
      this.queryForm.pageNum = e
      this.getList()
    },
    handleSizeChange (e) {
      this.queryForm.pageSize = e
      this.getList()
    },
    showDialog (item) {
      this.visible = true
      this.title = item ? '修改' : '添加'
      this.editType = item ? 1 : 0
      if (item) {
        this.editForm = {
          id: item.id,
          dictName: item.dictName,
          dictType: item.dictType,
          remark: item.remark
        }
      } else {
        this.editForm = {
          id: 0,
          dictName: '',
          dictType: '',
          remark: ''
        }
      }
    },
    showDeleteAccountTip (id) {
      this.$confirm('此操作将永久删除该字典, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(id)
        // delAccount({ id }).then(res => {
        //   this.searchFn()
        //   this.$message.success('已删除')
        // })
      })
    },
    dialogCloseFn () {
      this.visible = false
    },
    saveFn () {
      console.log(this.editForm)

      this.$refs.editFormRef.validate(valid => {
        if (!valid) return
        addSysDictType(this.editForm).then(res => {
          this.$message.success(`${this.title}成功`)
          if (this.editType) {
            this.getList()
          } else {
            this.searchFn()
          }
          this.visible = false
        })
      })
    },
    handleToChild (item) {
      const dictType = item.dictType
      const dictName = item.dictName
      sessionStorage.setItem('dictName', dictName)
      console.log(dictType)
      this.$router.push({
        path: '/system/dict/data',
        query: {
          t: dictType
        }
      })
    }
  }
}

</script>
