<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <el-card style="margin-top: 15px;">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">账号及岗位权限管理</span>
        </el-col>
      </el-row>
      <div class="row">
        <div class="col" v-for="(item,i) in menus" :key="item.id" >
          <system :item="item" :index="i"></system>
        </div>
      </div>
    </el-card> -->
    <el-card style="margin-top: 15px;">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">系统信息管理</span>
        </el-col>
      </el-row>
      <div class="row">
        <div class="col" v-for="(item, i) in menus2" :key="item.id" >
          <system :item="item" :index="i"></system>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      menus: [
        {
          id: 1,
          // path: '/system/account',
          path: '/system_Management/system_management_user',
          title: '账号管理',
          desc: '新增、修改、查看ERP账号信息',
          authorityFlag: this.$chnEngStatusCode.system_management_user,
          icon: 'icon-user'
        },
        {
          id: 2,
          path: '/system_Management/system_management_section',
          title: '部门管理',
          desc: '新增、修改、查看部门信息',
          authorityFlag: this.$chnEngStatusCode.system_management_section,
          icon: 'icon-ziyuan'
        },
        {
          id: 3,
          path: '/system_Management/system_management_position',
          title: '岗位管理',
          desc: '新增、修改、查看岗位信息',
          authorityFlag: this.$chnEngStatusCode.system_management_position,
          icon: 'icon-zhiwei'
        },
        {
          id: 4,
          path: '/system_Management/system_management_authority',
          title: '权限管理',
          desc: '新增、修改、查看权限信息',
          authorityFlag: this.$chnEngStatusCode.system_management_authority,
          icon: 'icon-quanxian'
        }
      ],
      menus2: [
        {
          id: 1,
          path: '/system/dict',
          title: '字典管理',
          desc: '新增、修改、查看系统字典信息',
          authorityFlag: this.$chnEngStatusCode.system_management_dictionary,
          icon: 'icon-zidianguanli'
        }
        // {
        //   id: 2,
        //   path: '/system/season',
        //   title: '赛季管理',
        //   desc: '个人赛/团体赛切换赛季',
        //   authorityFlag: this.$chnEngStatusCode.season,
        //   icon: 'icon-yunhangshi-qiehuanbanben'
        // },
        // {
        //   id: 3,
        //   path: '/system/race',
        //   title: '赛程管理',
        //   desc: '个人赛赛程查询',
        //   authorityFlag: this.$chnEngStatusCode.race,
        //   icon: 'icon-tisheng'
        // }
      ]
    }
  },
  created () {

  },
  methods: {
    handleTo (path) {
      if (path) {
        this.$router.push({ path })
      }
    }
  }
}
</script>
<style scoped lang="less">
.row{
  overflow: hidden;
}
.col{
  float: left;
}
</style>
