<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>产品码管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="100px"
        ref="queryFormRef"
      >
        <el-row :gutter="10">
          <!-- <el-col :span="6">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择赛季"
                style="width: 100%"
                v-model="queryForm.season"
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="选手姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item label="兑换码" prop="conversionCode">
              <el-input
                v-model="queryForm.conversionCode"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="学校名称" prop="schoolName">
              <el-input
                v-model="queryForm.schoolName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开通类型" prop="codeType">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.codeType"
                clearable
              >
                <el-option
                  v-for="item in codeTypeList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="使用方式" prop="isOne">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.isOne"
                clearable
              >
                <el-option
                  v-for="item in product_disposable"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="9">
            <el-form-item label="起止时间" prop="dateTimeRange">
              <el-date-picker
                v-model="queryForm.dateTimeRange"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="dateChangeClicked"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="开始时间" prop="startTime">
              <el-date-picker
                type="datetime"
                clearable
                placeholder="选择日期/时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
                v-model="queryForm.startTime"
                @change="addBeginTimeChangeStart"
                :picker-options="addTimepickerOptionsStart"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结束时间" prop="endTime">
              <el-date-picker
                type="datetime"
                clearable
                placeholder="选择日期/时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
                v-model="queryForm.endTime"
                @change="addEndTimeChangeEnd"
                :picker-options="addTimepickerOptionsEnd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getDataList('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 学校列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span
            style="
              display: -moz-inline-box;
              display: inline-block;
              width: 120px;
            "
            >兑换码信息</span
          >
        </el-col>
        <el-col :span="15">
          <div class="div_algin_right">
            <el-button type="primary" @click="editInfoDialogVisible = true"
              >新增兑换码</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column
          label="兑换码类型"
          prop="codeType"
          :formatter="codeTypeFormatter"
        ></el-table-column>
        <el-table-column label="兑换码" prop="conversionCode"></el-table-column>
        <el-table-column
          label="使用方式"
          prop="isOne"
          :formatter="isOneTypeFormatter"
        ></el-table-column>
        <el-table-column label="学校" prop="schoolName"></el-table-column>
        <el-table-column label="是否使用" prop="isUsed">
          <template slot-scope="scope">
            <el-tag
              type="warning"
              v-if="scope.row.isUsed + '' === '0'"
              size="mini"
              >未使用</el-tag
            >
            <el-tag type="success" v-else size="mini">已使用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="开始时间" prop="startTime"></el-table-column>
        <el-table-column label="结束时间" prop="endTime"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="创建人" prop="creator"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 编辑信息的dialog -->
    <el-dialog
      title="添加兑换码信息"
      :visible.sync="editInfoDialogVisible"
      width="60%"
      @close="editInfoDialogClose"
    >
      <el-form
        :model="editInfoForm"
        label-width="140px"
        ref="editInfoFormRef"
        :rules="editInfoRules"
        style="width: 100%"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="学校省市区" prop="schoolArea">
              <el-cascader
                placeholder="请选择"
                v-model="editInfoForm.schoolArea"
                :options="areasData"
                style="width: 100%"
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'id',
                  children: 'areas',
                }"
                @change="provincesHandleChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学校名称" prop="schoolId">
              <el-select
                ref="schoolSelectRef"
                placeholder="请选择"
                style="width: 100%"
                v-model="editInfoForm.schoolId"
                @change="selectSchoolChange"
              >
                <el-option
                  v-for="item in provincesSchoolList"
                  :key="item.id"
                  :label="item.schoolName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="学校名称" prop="schoolName">
              <el-input
                v-model="editInfoForm.name"
                placeholder="请输入"
                ref="schoolSelectRef"
                clearable
              ></el-input>
            </el-form-item> -->
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="解锁产品类型" prop="codeType">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="editInfoForm.codeType"
              >
                <el-option
                  v-for="item in codeTypeList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否一次性" prop="isOne">
              <el-select
                placeholder="请选择"
                v-model="editInfoForm.isOne"
                style="width: 100%"
              >
                <el-option
                  v-for="item in product_disposable"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开始时间" prop="startTime">
              <el-date-picker
                type="datetime"
                placeholder="选择日期/时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
                v-model="editInfoForm.startTime"
                @change="addBeginTimeChangeStart"
                :picker-options="addTimepickerOptionsStart"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间" prop="endTime">
              <el-date-picker
                type="datetime"
                placeholder="选择日期/时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 100%"
                v-model="editInfoForm.endTime"
                @change="addEndTimeChangeEnd"
                :picker-options="addTimepickerOptionsEnd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="描述信息" prop="codeDescription">
            <el-input
              maxlength="100"
              type="textarea"
              v-model="editInfoForm.codeDescription"
              placeholder="请输入描述信息(不超过100字)"
              clearable
            ></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editInfoDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editInfo">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import areasData from '@/assets/area.json'
import { queryConversionCodeList, getSysUserInfo, addConversionCode, getSchoolListWithCity } from '@/http/api'
export default {
  data () {
    return {
      provincesSchoolList: [],
      areasData: areasData.areas,
      editInfoDialogVisible: false,
      showEditInfoForm: false,
      dataList: [],
      total: 0,
      userInfoData: {},
      userInfoForm: {
        phone: ''
      },
      queryForm: {
        schoolName: '',
        isOne: '',
        conversionCode: '',
        pageNum: 1,
        pageSize: 10,
        codeType: '',
        startTime: '',
        endTime: ''
      },
      editInfoForm: {
        schoolArea: null,
        codeType: '',
        isOne: '',
        startTime: '',
        endTime: '',
        schoolName: null,
        schoolId: null,
        codeDescription: ''
      },
      userInfoRules: {
        phone: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      editInfoRules: {
        codeType: [{ required: true, message: '请选择', trigger: 'change' }],
        isOne: [{ required: true, message: '请选择', trigger: 'change' }],
        startTime: [{ required: true, message: '请选择', trigger: 'change' }],
        endTime: [{ required: true, message: '请选择', trigger: 'change' }],
        codeDescription: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      dict_group: this.$userInfo.dict_group(),
      product_disposable: this.$userInfo.product_disposable(),
      codeTypeList: this.$userInfo.getDataList(this, 'code_type').then(value => { this.codeTypeList = value }),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      addTimepickerOptionsStart: {},
      addTimepickerOptionsEnd: {}
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取列表信息
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      queryConversionCodeList(this.queryForm).then((res) => {
        console.log('queryConversionCodeList', res)
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    dateChangeClicked (val) {
      if (val.length === 2) {
        this.queryForm.startTime = val[0]
        this.queryForm.endTime = val[1]
        console.log('val', val)
      }
    },
    codeTypeFormatter (data) {
      var temp = '未知'
      for (const key in this.codeTypeList) {
        if (Object.hasOwnProperty.call(this.codeTypeList, key)) {
          const element = this.codeTypeList[key]
          if (element.dictValue === data.codeType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    isOneTypeFormatter (data) {
      var temp = '未知'
      for (const key in this.product_disposable) {
        if (Object.hasOwnProperty.call(this.product_disposable, key)) {
          const element = this.product_disposable[key]
          if (element.dictValue === data.isOne + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // 修改开始时间的判断
    addBeginTimeChangeStart () {
      if (this.editInfoForm.endTime) {
        if (this.editInfoForm.endTime <= this.editInfoForm.startTime) {
          this.$message.warning('开始时间必须小于结束时间！')
          this.editInfoForm.startTime = ''
        }
      }
      this.addTimepickerOptionsEnd = Object.assign({}, this.addTimepickerOptionsEnd, {
        disabledDate: (time) => {
          if (this.editInfoForm.startTime) {
            return new Date(time).getTime() < new Date(this.editInfoForm.startTime).getTime()
          }
        }
      })
    },
    // 修改结束时间的判断
    addEndTimeChangeEnd () {
      if (this.editInfoForm.startTime) {
        if (this.editInfoForm.endTime <= this.editInfoForm.startTime) {
          this.$message.warning('结束必须大于开始时间！')
          this.editInfoForm.endTime = ''
        }
      }
      this.addTimepickerOptionsStart = Object.assign({}, this.addTimepickerOptionsStart, {
        disabledDate: (time) => {
          if (this.editInfoForm.endTime) {
            return new Date(time).getTime() > new Date(this.editInfoForm.endTime).getTime()
          }
        }
      })
    },
    // 获取用户详情
    getUserInfoDetail () {
      console.log('this.editInfoForm', this.editInfoForm)
      getSysUserInfo({ phone: this.userInfoForm.phone }).then((res) => {
        console.log('getSysUserInfo', res)
        this.userInfoData = res.data
        this.editInfoForm.userId = res.data.id
        this.editInfoForm.phone = this.userInfoForm.phone
        this.showEditInfoForm = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 保存新增code点击事件
    editInfo () {
      this.$refs.editInfoFormRef.validate(async valid => {
        if (!valid) return
        this.editInfoForm.isUsed = 0
        this.editInfoForm.schoolName = this.$refs.schoolSelectRef.selectedLabel
        addConversionCode(this.editInfoForm).then((res) => {
          this.editInfoDialogVisible = false
          this.$message.success('新增成功！')
          this.$refs.editInfoFormRef.resetFields()
          this.reset()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      if (!value) return
      this.provincesSchoolList = []
      this.editInfoForm.schoolName = null
      this.editInfoForm.schoolId = null
      this.editInfoForm.provinceCode = value[0]
      this.editInfoForm.cityCode = value[1]
      this.editInfoForm.areaCode = value[2]
      console.log('editInfoForm', this.editInfoForm)
      this.getSchoolListData(value)
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      console.log('schoolArea', schoolArea)
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        console.log('getSchoolList', res)
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    selectSchoolChange (e) {
      console.log('e__', e)
      this.$nextTick(function () {
        this.editInfoForm.schoolName = this.$refs.schoolSelectRef.selectedLabel
      })
    },
    // 弹窗关闭
    editInfoDialogClose () {
      this.showEditInfoForm = false
      this.editInfoForm.schoolArea = null
      this.$refs.editInfoFormRef.resetFields()
    },
    // 重置搜索条件
    reset () {
      this.queryForm.startTime = ''
      this.queryForm.endTime = ''
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.div_algin_right {
  text-align: right;
}
</style>
