import { updateTbEnrollmentIsOnline } from '@/http/api'
export default {
  data () {
    return {
      racingToolLost: [
        {
          leftImg: require('@/assets/img/icon-shijuanguanli.png'),
          rightTitle: '比赛形式管理',
          rightDesc: '修改各赛段比赛形式'
        },
        {
          leftImg: require('@/assets/img/icon-school.png'),
          rightTitle: '线上考场管理',
          rightDesc: '新增/修改线上考场信息'
        },
        {
          leftImg: require('@/assets/img/icon-rili.png'),
          rightTitle: '重置选手成绩',
          rightDesc: '重置选手网络赛成绩'
        },
        {
          leftImg: require('@/assets/img/icon-school.png'),
          rightTitle: '定榜管理',
          rightDesc: '定榜后即公布成绩'
        },
        {
          leftImg: require('@/assets/img/icon-diannao.png'),
          rightTitle: '参赛方式管理',
          rightDesc: '可修改用户参赛方式',
          menuKey: 'participationWay'
        }
      ],
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      gameTypeChangePoster: false,
      editGameTypeForm: {
        phone: '',
        season: '',
        remark: '',
        isOnline: '',
        raceSchedule: ''
      },
      editGameTypeFormRules: {
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        season: [{ required: true, message: '请选择赛季信息', trigger: 'change' }],
        raceSchedule: [{ required: true, message: '请选择比赛类型', trigger: 'change' }],
        isOnline: [{ required: true, message: '请选择成绩类型', trigger: 'change' }],
        remark: [{ required: true, message: '请输入备注信息', trigger: 'blur' }]
      },
      scoreFlagList: [
        {
          dictCode: 2,
          dictLabel: '复赛',
          dictValue: 2
        }
      ],
      scoreTypeList: [
        {
          dictCode: 1,
          dictLabel: '线上',
          dictValue: 1
        },
        {
          dictCode: 0,
          dictLabel: '线下',
          dictValue: 0
        }
      ],
      scheduleCardList: [
        {
          scheduleName: '初选',
          scheduleId: '1',
          promotionNum: '1000',
          unPromotion: '200',
          backGround: '#57a3f7',
          flag: this.$chnEngStatusCode.INDIVIDUAL_PRELIMINARY
        }
        // {
        //   scheduleName: '复选',
        //   scheduleId: '2',
        //   promotionNum: '1000',
        //   unPromotion: '200',
        //   backGround: '#febf3d',
        //   flag: this.$chnEngStatusCode.INDIVIDUAL_FINAL
        // }, {
        //   scheduleName: '城市总决选',
        //   scheduleId: '3',
        //   promotionNum: '1000',
        //   unPromotion: '200',
        //   backGround: '#52c1f5',
        //   flag: this.$chnEngStatusCode.CITY_REGIONAL_COMPETITION
        // }, {
        //   scheduleName: '国赛有组别',
        //   scheduleId: '4',
        //   promotionNum: '1000',
        //   unPromotion: '200',
        //   backGround: '#fb6260',
        //   flag: this.$chnEngStatusCode.NATIONAL_FINALS
        // }, {
        //   scheduleName: '国赛无组别',
        //   scheduleId: '4',
        //   promotionNum: '1000',
        //   unPromotion: '200',
        //   backGround: '#8167f5',
        //   flag: this.$chnEngStatusCode.NATIONAL_FINALS
        // }
      ]
    }
  },
  methods: {
    // 考务工具点击
    racingToolClicked (item) {
      console.log('item', item)
      switch (item.menuKey) {
        case 'participationWay': // 参赛方式管理
          this.gameTypeChangePoster = true
          break
        default:
          break
      }
    },
    // 赛程成绩
    scheduleCardTap (scheduleData) {
      console.log('e', scheduleData)
      let turnUrl = ''
      switch (scheduleData.scheduleId) {
        case '1': // 初选
          turnUrl = '/examination_Management/individual_examination_management/schedule_competitions/schedule_individual_competition'
          break
        case '2': // 复选
          turnUrl = '/examination_Management/individual_examination_management/schedule_competitions/schedule_rematch_competition'
          break
        default:
          turnUrl = ''
          break
      }
      this.$router.push(
        {
          path: turnUrl,
          query: {
            scheduleFlag: scheduleData.flag
          }
        }
      )
    },
    // 修改参赛形式
    editGameTypeScore () {
      this.$refs.editGameTypeFormRef.validate(async valid => {
        if (!valid) return
        updateTbEnrollmentIsOnline(this.editGameTypeForm).then((res) => {
          this.gameTypeChangePoster = false
          return this.$message.success('修改成功')
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 修改参赛方式关闭
    editGameTypeDialogClose () {
      this.$refs.editGameTypeFormRef.resetFields()
    }
  }
}
