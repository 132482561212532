export default {
  /**
   * 活动类型
   */
  activityType: 10, // 代表环球活动
  activityCourseType: 3, // 代表环球课程
  /**
   * 登录code码
   */
  LOGINTYPE: '3', // 代表erp
  /**
  * 获取验证码状态
   */
  LOGINSMSTYPE: '7', // 登录
  /**
   * 跳转订单来源
   */
  registrantsOrder: '1', // 个人跳转
  registrantsSchoolOrder: '2', // 学校报名个人信息
  teamSchoolOrder: '3', // 学校报名团体信息
  /**
   * 默认赛季
   */
  defaultSeason: '2022~2023',
  /**
   * 判断学校整体参加个人考量是否上报
   */
  ENROLLMENT_NO: 3001, // 未提交
  ENROLLMENT_NO_PAY: 3002, // 已提交未支付
  ENROLLMENT_YES_PAY: 3003, // 已支付
  /**
   * 支付来源
   */
  PAY_ORDERSOURCE: 0, // 网站
  /**
   * 支付类型
   */
  PAY_ORDERTYPE: 0, // 网站
  /**
   * 商品ID
   */
  PRODUCT_SCHOOLPERSON: '1', // 学校报名个人赛
  /**
   * 产品规格类型Code码
   */
  PRODUCT_SPECSTYPE_CODE_SCHOOLPERSON: 1, // 学校报名个人赛
  /**
   * 产品规格类型内容Code码
   */
  PRODUCT_SPECSTYPE_VALUE_CODE_SCHOOLPERSON: 1, // 学校报名个人赛
  /**
   * 商品productFlag
   */
  APPLY_TEST_PAPER: 'APPLY_TEST_PAPER', // 个人赛申报考量
  /**
   * 开通方式
   */
  OPEN_TYPE_DATE: 1, // 开通日期
  OPEN_TYPE_DAYS: 2, // 开通天数
  OPEN_TYPE_DEFAULT: 3, // 产品默认方式
  /**
   * 赛程flag
   */
  TEAM_COMPETITION_REGISTRATION: 'TEAM_COMPETITION_REGISTRATION', // 团体赛报名
  INDIVIDUAL_PRELIMINARY: 'INDIVIDUAL_PRELIMINARY', // 初赛
  INDIVIDUAL_FINAL: 'INDIVIDUAL_FINAL', // 复赛
  CITY_REGIONAL_COMPETITION: 'CITY_REGIONAL_COMPETITION', // 城市赛
  NATIONAL_FINALS: 'NATIONAL_FINALS', // 全国总
  /**
   * 教师身份
   * 1管理员 2个人赛， 3团体赛 4社团
   */
  ADMINISTRATOR_ROLEID: '1',
  ADMINISTRATOR_INDIVIDUAL: '2',
  ADMINISTRATOR_TEAN: '3',
  ADMINISTRATOR_ASSOCIATION: '4'

}
