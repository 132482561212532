import axios from 'axios'
export default {
  // requestUrl, {
  //   Authorization: window.localStorage.getItem('Authorization'),
  //   responseType: 'arraybuffer'
  // }
  downLoadFileWithUrl: async function (that, requestUrl, formData) {
    console.log('formData', formData)
    axios({
      headers: {
        'Content-Type': 'application/json;charset=UTF-8;',
        Authorization: localStorage.getItem('Authorization')
      },
      responseType: 'arraybuffer',
      method: 'GET',
      url: requestUrl,
      params: formData
    }).then((res) => {
      console.log('res', res)
      const data = res.data
      const url = window.URL.createObjectURL(new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }))
      console.log('res.headers', res.headers)
      const fileNames = res.headers['content-disposition'] // 获取到Content-Disposition;filename
      const regFileNames = fileNames.match(/=(.*)$/)[1] // 文件名称
      // const regFileNames = '模板文件.xlsx' // 文件名称
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.setAttribute('download', decodeURI(regFileNames))
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      this.dialogInfo.dialogVisible = false
    }).catch((err) => {
      console.log(err)
    })
  }
}
